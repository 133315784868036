import React from "react";
import { Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import { alertService } from "../../../data/services/alertService";
import TeamsModal from "./TeamsModal";

class UserModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.state = {
      show: true,
      fields: {},
      errors: {},
      teamList: [],
      teamsIndex: [],
      tmpTeamsIdx: [],
      open: false,
      contextMenuOpen: false,
      filterMenuOpen: false,
      anchorEl: null,
      modTeamsList: [],
    };
  }
  componentWillMount() {
    this.props.getTeams();
    if (this.props.userInfo && this.props.teams) {
      let fields = this.state.fields;
      fields["id"] = this.props.userInfo.id;
      fields["systemEdxProviderId"] = this.props.userInfo.systemEdxProviderId;
      fields["firstName"] = this.props.userInfo.firstName;
      fields["lastName"] = this.props.userInfo.lastName;
      fields["prefix"] = this.props.userInfo.prefix;
      fields["suffix"] = this.props.userInfo.suffix;
      fields["email"] = this.props.userInfo.email;
      fields["role"] = this.props.userInfo.role;
      fields["teams"] = this.props.userInfo.teams;
      fields["isAdmin"] = this.props.userInfo.isAdmin;
      fields["practice"] = this.props.userPractice;
      let teamsIdx = [];
    
      this.props.teams.forEach((item, idx) => {
        if (this.props.userInfo.teams.includes(item.name)) {
          teamsIdx[idx] = item.name;
        } else {
          teamsIdx[idx] = null;
        }
      });
    
      this.setState({
        fields,
        teamsIndex: teamsIdx,
        tmpTeamsIdx: [...teamsIdx],
        modTeamsList: [...teamsIdx]
      });
    } else {
      this.setState({
        fields: {
          id: "",
          systemEdxProviderId: "",
          firstName: "",
          lastName: "",
          prefix: "",
          suffix: "",
          email: "",
          role: "",
          new_team: "",
          teams: [],
          teamsIndex: [],
          tmpTeamsIdx: [],
          practice: this.props.userPractice,
          removeSelfAsAdmin: false,
        },
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userAdd !== this.props.userAdd) {
      if (this.props.userAdd) {
        if (this.props.userAdd.status === "success") {
          this.props.closePopup();
        }
      }
    }
    if (prevProps.userUpdate !== this.props.userUpdate) {
      if (this.props.userUpdate) {
        if (this.props.userUpdate.status === "success") {
          this.props.closePopup();
        }
      }
    }
    if (prevProps.teams !== this.props.teams) {
      if (this.props.teams) {
        this.setState({ teamList: this.props.teams });
      }
    }
    if (prevProps.teamAdd !== this.props.teamAdd) {
      if (this.props.teamAdd) {
        this.props.getTeams();
        let fields = this.state.fields;
        fields.new_team = "";
        this.setState({
          fields,
        });
      }
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    let removeAsAdmin = false;
    if(e.target.value.length > 64) return;
    if(!e.target.value.startsWith(" ")){
      const val = e.target.value.replace(/  +/g, ' ');
      fields[e.target.name] = val;
      if (e.target.name === "isAdmin" && e.target.checked) {
        fields[e.target.name] = true;
      } else if (e.target.name === "isAdmin" && !e.target.checked) {
        removeAsAdmin = true
        fields[e.target.name] = false;
      }
      this.setState({
        fields,
        removeSelfAsAdmin: removeAsAdmin && this.props.loggedInUser.id === this.props.userInfo.id
      });
    } else {
      const val = e.target.value.replace(/ +/g, '');
      fields[e.target.name] = val;
      this.setState({
        fields,
      });
    }
    
  }

  handleChangeTeamlist(e, idx = 0) {
    let teams = this.state.fields.teams;
    let teamsIdx = this.state.teamsIndex;
    if(e.target.id === "existingTeam") {
      if (e.target.checked) {
        teams.push(e.target.name);
        teamsIdx[idx] = e.target.name;
      } else {
        let index = teams.indexOf(e.target.value);
        teams = teams.splice(index, 1);
        teamsIdx[idx] = null;
      }
    }

    if(e.target.id === "newTeam") {
      let tmpTeamsIndex = teamsIdx;
      tmpTeamsIndex[this.state.teamList.length] = e.target.name;
      teams.push(e.target.name);
      teamsIdx = tmpTeamsIndex;
    }

    this.setState({
      teams: teams,
      teamsIndex: teamsIdx,
    });

  }
  showMenu = (event) => {
    this.setAnchorEl(event.currentTarget);
    var userModal = document.querySelector(".add-user-modal");
    if (userModal.hasAttribute("tabindex")) {
      userModal.removeAttribute("tabindex");
    }
  };
  closeMenu = (modTeamsList) => {
    // if(info) {
    //   let teamsIdx = this.state.teamsIndex;
    //   if (this.state.fields.new_team && this.state.fields.new_team !== "" && this.state.fields.new_team.trim().length > 0) {
    //     let new_team = {};
    //     new_team["name"] = this.state.fields.new_team.trim();
    //     new_team["clinicId"] = this.state.fields.id;
  
    //     const isPresentTeam = this.state.teamList.filter(team => team.name ===  this.state.fields.new_team.trim())
    //     if(isPresentTeam.length){
    //       alertService.error('Team name already exits.',{autoClose: true});
    //       teamsIdx.pop();
    //       new_team["name"] = "";
    //     } else {
    //       this.props.postTeam(new_team);
    //     }
    //   }
    //   let tmpTeamsIdxArr = [];
    //   teamsIdx.forEach(item => {
    //     if(item) {
    //       tmpTeamsIdxArr.push(item);
    //     }
    //   })

    //   this.setState({
    //     filterMenuOpen: false,
    //     teamsIndex: teamsIdx,
    //     tmpTeamsIdx: [...tmpTeamsIdxArr],
    //     fields: {...this.state.fields, new_team: ""}
    //   });
    // } else {
      this.setState({
        filterMenuOpen: false,
        fields: {...this.state.fields, new_team: ""},
        modTeamsList: modTeamsList && modTeamsList.length ? modTeamsList : []
      });
    // }
  };
  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      filterMenuOpen: !this.state.filterMenuOpen,
    });
  }
  // handleTeamChange(e) {

  //     let fields = this.state.fields;
  //     fields[e.target.name] = e.target.value;
  //     this.setState({
  //         fields
  //     });
  // };

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      let removeSelfAsAdmin = this.state.removeSelfAsAdmin;
      if (fields.id !== "" && fields.id !== undefined) {
        let filteredTeams = this.state.modTeamsList.filter(item => item !== undefined);
        filteredTeams = this.state.modTeamsList.filter(item => item !== null );
        filteredTeams = filteredTeams.filter(item => item !== "" );
        fields.teams = filteredTeams;
        this.props.putUser(fields, removeSelfAsAdmin);
      } else {
        fields.id = "";
        let filteredTeams = this.state.modTeamsList.filter(item => item !== undefined);
        filteredTeams = this.state.modTeamsList.filter(item => item !== null );
        filteredTeams = filteredTeams.filter(item => item !== "" );
        fields.teams = filteredTeams;
        this.props.postUser(fields);
      }

      let prev_fields = this.state.fields;

      this.setState({ fields: prev_fields });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstName"].match(/^[a-z A-Z,.'-]+$/)) {
      formIsValid = false;
      errors["firstName"] = "Enter a valid name";
    }

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }

    if (!fields["lastName"].match(/^[a-z A-Z,.'-]+$/)) {
      formIsValid = false;
      errors["lastName"] = "Enter a valid name";
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "This field is required";
    } else {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "This field is invalid";
      }
    }

    // if (!fields["practice"]) {
    //     formIsValid = false;
    //     errors["practice"] = "This field is required";
    // }

    if (!fields["role"]) {
      formIsValid = false;
      errors["role"] = "This field is required";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  truncate(info) {
    let tmpInfo = info.filter(item => item !== undefined);
    tmpInfo = info.filter(item => item !== null);
    const str = tmpInfo.join();
    return str.length > 24 ? str.substring(0, 27) + "..." : str;
  }

  render() {
    // console.log("this.state.removeSelfAsAdmin ", this.state.removeSelfAsAdmin)
    
    return (
      <div>
        <Modal
          className="modal-656 add-user-modal"
          show={this.props.showPopup}
          onHide={this.props.closePopup}
          backdrop={"static"}
          keyboard={false}>
          <Modal.Header>
            <Modal.Title className="font-18 font-quicksand">Manage Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <!--New Patient Dialog Content--> */}
            <form
              method="post"
              name="userRegistrationForm"
              onSubmit={this.submituserRegistrationForm.bind(this)}>
              <div id="newUserContent" className="modal-content">
                <div className="">
                  <div className="row">
                    <div className="col-2">
                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Prefix"
                            id="prefix"
                            name="prefix"
                            value={this.state.fields.prefix}
                            onChange={this.handleChange.bind(this)}
                            className="w-100"
                            inputProps={{"data-lpignore":"true"}}
                          />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group modal-col">
                          <TextField
                              type="text"
                              label="First Name *"
                              id="firstName"
                              name="firstName"
                              value={this.state.fields.firstName}
                              onChange={this.handleChange.bind(this)}
                              error={this.state.errors.firstName ? true : false}
                              helperText={this.state.errors.firstName}
                              className="w-100"
                              inputProps={{"data-lpignore":"true"}}
                          />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Last Name *"
                            id="lastName"
                            name="lastName"
                            value={this.state.fields.lastName}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.errors.lastName ? true : false}
                            helperText={this.state.errors.lastName}
                            className="w-100"
                            inputProps={{"data-lpignore":"true"}}
                          />
                        </div>
                    </div>

                    <div className="col-2">
                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Suffix"
                            id="suffix"
                            name="suffix"
                            value={this.state.fields.suffix}
                            onChange={this.handleChange.bind(this)}
                            className="w-100"
                            inputProps={{"data-lpignore":"true"}}
                          />
                          <TextField
                              type="hidden"
                              id="practice"
                              name="practice"
                              value={this.state.fields.practice}
                              onChange={this.handleChange.bind(this)}
                              error={this.state.errors.practice ? true : false}
                              helperText={this.state.errors.practice}
                              className="d-none"
                          />
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">

                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Email *"
                            id="email"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.errors.email ? true : false}
                            helperText={this.state.errors.email}
                            className="w-100"
                          />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group modal-col">
                          <FormControl className="w-100">
                            <div className="form-group cursor-point" onClick={this.showMenu}>
                              <div className="form-input-dropdown">
                                <TextField
                                  type="text"
                                  label="Team"
                                  id="teamlist"
                                  name="team"
                                // value={this.truncate(this.state.fields.teams.join())}
                                value={this.truncate(this.state.modTeamsList)}
                                className="w-100"
                                />
                                <span className="material-icons form-input-dropdown-icon">
                                  {" "}
                                  arrow_drop_down{" "}
                                </span>
                              </div>
                            </div>

                            <Menu
                              id="filterMenu"
                              anchorEl={this.state.anchorEl}
                              open={this.state.filterMenuOpen}
                              onClose={() => this.closeMenu(this.state.modTeamsList)}
                              getContentAnchorEl={null}
                              anchorOrigin={{ vertical: "center", horizontal: "left" }}
                              transformOrigin={{ vertical: "center", horizontal: "left" }}>
                              <div className="filter-dropdown-menu" id="menuCheckbox"
                               onFocus={() => {
                                var userModal = document.querySelector("#menuCheckbox");
                                if (userModal.hasAttribute("tabindex")) {
                                  userModal.removeAttribute("tabindex");
                                }
                              }}
                              >
                                {/* <div className="d-flex align-content-start flex-wrap">
                                  <div className="filter-team-modal">
                                    <FormControl component="fieldset">
                                      <FormGroup>
                                        {this.state.teamList.map((team, index) => (
                                          <FormControlLabel
                                            className="form-checkbox"
                                            control={
                                              <Checkbox
                                                id="existingTeam"
                                                onChange={(e) =>
                                                  this.handleChangeTeamlist(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name={team.name}
                                                checked={
                                                  this.state.teamsIndex[index]
                                                }
                                                color="primary"
                                              />
                                            }
                                            label={team.name}
                                          />
                                        ))}

                                        <div className="d-flex align-items-center">
                                          <FormControlLabel
                                            className="form-checkbox mr-0 mb-0"
                                            control={
                                              <Checkbox
                                              id="newTeam"
                                                name={this.state.fields.new_team}
                                                onChange={this.handleChangeTeamlist.bind(this)}
                                                color="primary"
                                              />
                                            }
                                          />
                                          <TextField
                                            type="text"
                                            id="standard-name"
                                            margin="normal"
                                            className="my-0 pl-1"
                                            value={this.state.fields.new_team}
                                            name="new_team"
                                            onChange={this.handleChange.bind(this)}
                                          />
                                        </div>
                                        <div className="mt-4">
                                          <Button
                                            type="button"
                                            className="btn btn-primary text-uppercase px-4 ml-0"
                                            onClick={() => this.closeMenu(true)}>
                                            SAVE
                                          </Button>
                                        </div>
                                      </FormGroup>
                                    </FormControl>
                                  </div>
                                </div> */}
                                <TeamsModal teams={this.props.teams} userInfo={this.props.userInfo}  open={this.state.filterMenuOpen} postTeam={this.props.postTeam} onClose={
                                  (info) => {this.closeMenu(info)}
                                } 
                                  modTeamsList={this.state.modTeamsList}/>
                              </div>
                            </Menu>
                          </FormControl>
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">

                        <div className="form-group modal-col">
                          <FormControl
                            className="w-100"
                            error={this.state.errors.role ? true : false}>
                            <InputLabel id="role">Role *</InputLabel>
                            <Select
                              className="w-100"
                              id="role"
                              name="role"
                              value={this.state.fields.role}
                              onChange={this.handleChange.bind(this)}
                              disabled={this.state.fields.id !== ""}>
                              <MenuItem value="Provider">Provider</MenuItem>
                              <MenuItem value="Staff">Staff</MenuItem>
                            </Select>
                            {this.state.errors.role && (
                              <FormHelperText>{this.state.errors.role}</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group modal-col">
                          <Checkbox
                            color="primary"
                            value=""
                            name="isAdmin"
                            checked={this.state.fields.isAdmin}
                            onChange={this.handleChange.bind(this)}
                          />{" "}
                          Admin
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                        <div className="form-group modal-col">
                          <TextField
                              type="text"
                              label="External Id"
                              id="systemEdxProviderId"
                              name="systemEdxProviderId"
                              value={this.state.fields.systemEdxProviderId}
                              onChange={this.handleChange.bind(this)}
                              className="w-100"
                              inputProps={{"data-lpignore":"true"}}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer px-0">
                <Button
                  variant="outlined"
                  onClick={this.props.closePopup}
                  className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                  data-dismiss="modal">
                  Cancel
                </Button>
                <Button type="submit" className="btn btn-primary text-uppercase px-4 ml-0">
                  SAVE
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default UserModal;
