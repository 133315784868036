/* eslint-disable no-script-url */
import React, { Component } from "react";
import { isMobile } from "mobile-device-detect";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Moment from "react-moment";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Tabs, Tab } from "react-bootstrap";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

class Reportlists extends Component {
  state = {
    searchFilter: {
      providerId: this.props.location.state.providerId || "",
      lastDays: this.props.location.state.lastDays || "",
      status: this.props.location.state.status || "",
    },
    reportsRows: [],
    pageInfo: {
      id: "",
      start: "",
      count: "10",
    },
    items: Array.from({ length: 20 }),
    hasMore: true,
    startDPOpen: false,
    startDate: this.props.location.state.startDate || null,
    endDPOpen: false,
    endDate: this.props.location.state.endDate || null,
  };

  componentDidMount() {
    this.props.getPhysicianList();
    this._fetchRecords();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reportList &&
      prevProps.reportList !== this.props.reportList
    ) {
      this.setState({
        reportsRows: this.props.reportList.data,
        pageInfo: this.props.reportList.page,
      });
    }
  }

  //return to dashboard
  _backToDashboard() {
    this.props.history.push({ pathname: "/" });
  }

  _fetchRecords() {
    const { pageInfo } = this.state;
    const searchFilter = {
      ...this.state.searchFilter,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    this.props.getReportList(searchFilter, pageInfo);
  }

  _onTabSelect = (key) => {
    let searchFilter = this.state.searchFilter;
    searchFilter["lastDays"] = key;
    this.setState({ searchFilter: searchFilter }, this._fetchRecords);
  };

  _onProviderChange = (e) => {
    let searchFilter = this.state.searchFilter;
    searchFilter["providerId"] = e.target.value;
    this.setState({ searchFilter: searchFilter }, this._fetchRecords);
  };

  _onStatusChange = (e) => {
    let searchFilter = this.state.searchFilter;
    searchFilter["status"] = e.target.value;
    this.setState({ searchFilter: searchFilter }, this._fetchRecords);
  };

  _onStartDateChange = (d) => {
    this.setState({ startDate: moment(d).format() });
  };

  _onEndDateChange = (d) => {
    this.setState({ endDate: moment(d).format() });
  };

  _onEndDateAccept = () => {
    let searchFilter = this.state.searchFilter;
    searchFilter["lastDays"] = "custom";
    this.setState({ searchFilter }, this._fetchRecords);
  };

  _formateRangeDate = () => {
    const { startDate, endDate } = this.state;
    return (
      moment(startDate).format("MM/DD/YYYY") +
      " - " +
      moment(endDate).format("MM/DD/YYYY")
    );
  };

  _fetchMoreData = () => {
    if (this.state.reportsRows.length >= this.state.pageInfo.count) {
      this.setState({ hasMore: false });
      return;
    }
    this._fetchRecords();
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    // setTimeout(() => {
    //     this.setState({
    //         items: this.state.items.concat(Array.from({ length: 20 }))
    //     });
    // }, 1500);
  };

  renderProviders = () => {
    const { physicians } = this.props;
    return (
      <div className="form-group report-provider-select mr-4">
        <FormControl className="w-100">
          <InputLabel id="demo-controlled-open-select-label">
            Select Provider
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="providerId"
            name="providerId"
            value={this.state.searchFilter.providerId}
            onChange={this._onProviderChange}
          >
            <MenuItem value="-1">All Providers </MenuItem>
            {physicians &&
              physicians.length > 0 &&
              physicians.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.id}>
                    {val.lastName}, {val.firstName}{" "}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  };

  render() {
    const { searchFilter, reportsRows, startDate, endDate } = this.state;
    const { isAdmin } = this.props.location.state;
    return (
      <main className="main-container user-container bg-white">
        <div className="content-area scrollbar h-100">
          <div className="container-fluid container-padding">
            <div className="mb-4">
              {!isMobile && (
                <div className="clearfix mb-2">
                  <Typography>
                    <Link
                      className="back-dashboard-btn d-inline-flex align-items-center text-decoration-none"
                      href="javascript:void(0)"
                      onClick={this._backToDashboard.bind(this)}
                    >
                      <span className="material-icons mr-2">
                        keyboard_backspace
                      </span>
                      Back to Dashboard
                    </Link>
                  </Typography>
                </div>
              )}
              {!isMobile && (
                <div class="d-flex align-items-center justify-content-between flex-wrap mb-n2">
                  <h1 class="font-18 quicksand-regular text-charcoal mb-2 text-left">
                    Reports
                  </h1>
                </div>
              )}
            </div>
            <div className="row report-dashboard-filter">
              <div className="col-12 mb-4 pb-2">
                <div className="d-flex align-items-center flex-wrap mb-n3 justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    {isAdmin && this.renderProviders()}

                    <div className="form-group report-provider-select">
                      <FormControl className="w-100">
                        <InputLabel id="demo-controlled-open-select-label">
                          Select Status
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          value={searchFilter.status}
                          onChange={this._onStatusChange}
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="completed">Successful</MenuItem>
                          <MenuItem value="missed">Missed</MenuItem>
                          <MenuItem value="failed">Failed</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end flex-grow-1">
                    {!isMobile && (
                      <Link
                        onClick={() => this.setState({ startDPOpen: true })}
                      >
                        <div className="mr-3 dateRange">Date Range</div>
                      </Link>
                    )}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        views={["year", "month", "date"]}
                        open={this.state.startDPOpen}
                        onOpen={() => this.setState({ startDPOpen: true })}
                        onClose={() => this.setState({ startDPOpen: false })}
                        value={this.state.startDate}
                        title="Start Date"
                        onChange={this._onStartDateChange}
                        okLabel={"Select End Date"}
                        onAccept={() => this.setState({ endDPOpen: true })}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        TextFieldComponent={() => null}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        views={["year", "month", "date"]}
                        open={this.state.endDPOpen}
                        onOpen={() => this.setState({ endDPOpen: true })}
                        onClose={() => this.setState({ endDPOpen: false })}
                        value={this.state.endDate}
                        title="End Date"
                        onChange={this._onEndDateChange}
                        dateFormat="MM/dd/yyyy"
                        onAccept={this._onEndDateAccept}
                        minDate={startDate}
                        maxDate={new Date()}
                        TextFieldComponent={() => null}
                      />
                    </MuiPickersUtilsProvider>
                    <div className="inner-tab-wrapper">
                      <Tabs
                        id="controlled-tab-example"
                        onSelect={this._onTabSelect}
                        activeKey={searchFilter.lastDays}
                        className="mb-0"
                      >
                        {startDate && endDate && (
                          <Tab
                            className={""}
                            eventKey={"custom"}
                            title={this._formateRangeDate()}
                          />
                        )}
                        <Tab className={""} eventKey={"0"} title="Today"></Tab>
                        <Tab
                          className={""}
                          eventKey={"7"}
                          title="Last 7 Days"
                        ></Tab>
                        <Tab
                          className={""}
                          eventKey={"30"}
                          title="Last 30 Days"
                        ></Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h2 class="title-count">Count: {reportsRows?.length || 0}</h2>
            </div>
            <div className="content-tab-panel user-content-tab-panel">
              <div className="table-responsive div-table">
                <div
                  id="scrollableDiv"
                  style={{ height: 600, overflow: "auto" }}
                >
                  <InfiniteScroll
                    dataLength={reportsRows?.length}
                    next={this._fetchMoreData}
                    hasMore={this.state.hasMore}
                    // loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <table className="d-table w-100">
                      <thead className="table-head text-dusty-gray">
                        <tr className="position-relative">
                          <td className="td-title-2 position-relative">
                            <span className="d-inline-block">Appt Date</span>
                          </td>
                          <td className="td-title-2">Provider</td>
                          <td className="td-title-3">Appt Type</td>
                          <td className="td-title-8"></td>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {reportsRows && reportsRows.length > 0 ? (
                          reportsRows.map((val, index) => {
                            let apptStatusClass = classNames({
                              "icon-sucess":
                                val.status === "COMPLETED" ||
                                val.status === "COMPLETE",
                              "icon-error":
                                val.status === "MISSED" ||
                                val.status === "cancelled",
                              "icon-user-times":
                                val.status === "MISSED_CLINICIAN",
                              "icon-exclamation": val.status === "FAILED",
                            });
                            let attendee_name = [];
                            if (val.attendee && val.attendee.length > 0) {
                              val.attendee.forEach((val, index) => {
                                attendee_name.push(val.attendee_name);
                              });
                            }
                            return (
                              <tr>
                                <td>
                                  <span className="d-inline-block text-center">
                                    <Moment format="MM/DD/YY">
                                      {val.scheduleStart}
                                    </Moment>
                                    <br />
                                    <Moment format="h:mm A">
                                      {val.scheduleStart}
                                    </Moment>
                                  </span>
                                </td>
                                <td>{val.providerLastName + ', ' + val.providerFirstName}</td>
                                <td>{val.appointmentType}</td>
                                <td className="text-center">
                                  <span
                                    className={"font-21 " + apptStatusClass}
                                  ></span>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Reportlists;
