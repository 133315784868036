import {
  PHYSICIAN_LIST_SUCCESS, PHYSICIAN_LIST_ERROR, PHYSICIANSAVAILABILITY_LIST_SUCCESS, PHYSICIANSAVAILABILITY_LIST_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
  physicians: {}
};

export const physicianmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PHYSICIAN_LIST_SUCCESS:
    case PHYSICIAN_LIST_ERROR:
      return {
        ...state,
        physicians: action.payload
      };
    case PHYSICIANSAVAILABILITY_LIST_SUCCESS:
    case PHYSICIANSAVAILABILITY_LIST_ERROR:
      return {
        ...state,
        physician_availabilities: action.payload
      }
    default:
      return state;
  }
};
