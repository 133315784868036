import React, {Component} from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {Authenticator} from "aws-amplify-react";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {withRouter} from 'react-router-dom';
import AuthWrapper from "./Components/Auth/AuthWrapper";
import Header from "./Containers/PageContainer/Layout/Header";
import Footer from "./Components/Layout/Footer";
import {Alert} from './Components/Global/Alert/Alert';
import {Auth, Hub} from 'aws-amplify';

//import CircularProgress from '@material-ui/core/CircularProgress';
//import Backdrop from '@material-ui/core/Backdrop';

Amplify.configure(awsconfig);

class App extends Component {
    // async function getUserInfo() {
    //   const userInfo = await Auth.currentUserInfo();
    //   console.log('userInfo', userInfo);
    // }
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false
        };
        this.checkAuth = this.checkAuth.bind(this);
    }

    componentDidMount() {
        this.checkAuth();
        Hub.listen("auth", this.checkAuth);
    }

    async checkAuth() {
        try {
            await Auth.currentAuthenticatedUser();
            this.setState({isAuthenticated: true});
        } catch (e) {
            if (e === "not authenticated") {
                this.setState({isAuthenticated: false});
            }
        }
    }

    render() {
        return (
            <div className="App app-wrapper h-100">

                <React.Fragment>
                    {/* <Backdrop open><CircularProgress  /></Backdrop> */}
                    {/* this.state.isAuthenticated ? <Alert></Alert> : null */}
                    <Alert></Alert>
                    {this.state.isAuthenticated ? <Header/> : null}

                    <Authenticator hideDefault={true} amplifyConfig={awsconfig}>
                        <AuthWrapper/>
                    </Authenticator>

                    <Footer/>
                </React.Fragment>

            </div>
        );
    }
}

//export default withAuthenticator(withRouter(App), { includeGreetings: false });
export default withRouter(App);
