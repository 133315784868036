
import Http from '../../Services/Http';
import {
  URL_PHYSICIANS, APINAME_PHYSICIANS, URL_PHYSICIANS_AVAILABILITY
} from '../../Containers/Common/APIUtils';
const api = new Http({});
var moment = require('moment');

const getPhysicianList = async () => {
  let apiName = APINAME_PHYSICIANS;
  let path = URL_PHYSICIANS;
  let queryParams = {
  };

  // fetch physician from api
  let response = await api.get(apiName, path, queryParams);

  // fetch physician from static json file
  //let response = data.physicians[0];
  return response;
};

const getphysicianAvailability = async (appointmentDate, physicianId) => {
  let apiName = APINAME_PHYSICIANS;
  let path = URL_PHYSICIANS_AVAILABILITY(physicianId);
  let begin = moment(appointmentDate).startOf('day').format();
  if (moment(appointmentDate).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')) {
    let interval = 15 * 60 * 1000
    let dt = new Date(Math.ceil(new Date().getTime()/interval)*interval);
    begin = moment(dt).format();
  }
  let end = moment(appointmentDate).add(1,'days').startOf('day').format();
  let queryParams = {
    "begin": begin,
    "end": end
  };

  // fetch physician availability from api
  let response = await api.get(apiName, path, queryParams);

  // fetch physician availability from static json file
  //let response = data.physician_availability[0];
  return response;
};

export const PhysicianService = {
  getPhysicianList, getphysicianAvailability
};
