import * as actionTypes from './actionTypes';
import { alertService } from '../../data/services/alertService';
import { Physician } from '../../Models/index';
import { PhysicianService } from '../../data/services/index';
//import {AlertHelper} from '../../common/AlertHelper';
//import {logger} from '../../common/logger';


export const physicianList = () => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_ALL_PHYSICIANS });
  getPhysicianList(dispatchEvent);
};

function getPhysicianList(dispatchEvent) {
  PhysicianService.getPhysicianList()
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });

      let modifiedRes = [];
      if(response?.status === "error"){
        throw response;
      }
      response.data.forEach((val, index) => {
        let obj = {};
        obj[Physician.id] = val.id;
        obj[Physician.firstName] = val.firstName;
        obj[Physician.lastName] = val.lastName;
        obj[Physician.name] = `${val.lastName}, ${val.firstName}`;
        obj[Physician.phone] = val.phone;
        obj[Physician.email] = val.email;
        obj[Physician.role] = val.role;
        modifiedRes.push(obj);
      });

      dispatchEvent({
        type: actionTypes.PHYSICIAN_LIST_SUCCESS,
        payload: modifiedRes,
      });
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // 
      dispatchEvent({
        type: actionTypes.PHYSICIAN_LIST_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

export const physicianAvailability = (appointmentDate, physicianId) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_PHYSICIANS_AVAILABILITY });
  getphysicianAvailability(dispatchEvent, appointmentDate, physicianId);
};

function getphysicianAvailability(dispatchEvent, appointmentDate, physicianId) {
  PhysicianService.getphysicianAvailability(appointmentDate, physicianId)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });


      let modifiedRes = [];
      if(response?.status === "error"){
        throw response
      }
      response.data.forEach((val, index) => {
        let obj = {};
        obj['start'] = val.start;
        modifiedRes.push(obj);
      });


      dispatchEvent({
        type: actionTypes.PHYSICIANSAVAILABILITY_LIST_SUCCESS,
        payload: modifiedRes,
      });
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // 
      dispatchEvent({
        type: actionTypes.PHYSICIANSAVAILABILITY_LIST_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

