import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "mobile-device-detect";
import { Auth } from "aws-amplify";
import IdleTimer from "react-idle-timer";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Branding from "../../Services/Branding";
import Storage from "../../Providers/Storage";
import CustomImg from "../Global/CustomImg/CustomImg";
//import PatientModal from '../PageContainer/Patient/PatientModal';
import PatientModal from "../../Containers/PageContainer/Patient/PatientModel";
import ScheduleApptModal from "../../Containers/PageContainer/Patient/ScheduleAppointment";
import PatientSearchbox from "../../Containers/PageContainer/Layout/PatientSearchbox";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPatient: "",
      userName: "",
      displayName: "",
      showPatientPopup: false,
      showApptPopup: true,
      patientInfo: "",
      anchorEl: null,
      open: false,
      currentPage: "",
      patientAdd: {},
      logoImageUrl: null,
      admin: false,
      isProvider: false,
      developer: false,
    };
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  async setBranding() {
    const branding = await Branding.getBranding();
    if (this.state.logoImageUrl !== branding.logoImageUrl) {
      this.setState({ logoImageUrl: branding.logoImageUrl });
    }
  }

  async componentDidMount() {
    await this.setBranding();

    const userInfo = await this.props.getCurrentUserInfo();

    try {
      const st = {
        userName: userInfo.email,
        displayName:
          userInfo.firstName && userInfo.lastName
            ? `${userInfo.firstName} ${userInfo.lastName}`
            : userInfo.email,
        admin: userInfo.isAdmin,
        isProvider: userInfo.timekitResourceId ? true : false,
      };
      this.setState(st);
    } catch (err) {
      this.setState({ userName: "", displayName: "" });
    }

    let currentpage = this.props.location.pathname;
    this.setState({ currentPage: currentpage.replace("/", "") });
  }

  async componentDidUpdate(prevProps) {
    await this.setBranding();

    if (prevProps.location.pathname !== this.props.location.pathname) {
      let currentpage = this.props.location.pathname;
      this.setState({ currentPage: currentpage.replace("/", "") });
    }
    if (prevProps.patientAdd !== this.props.patientAdd) {
      this.setState({ patientAdd: this.props.patientAdd });
      this.setState({ showApptPopup: true });
    }
  }

  patientModalRef = ({ handleShow }) => {
    //this.showModel = handleShow;
  };
  newPatientClick = () => {
    //this.showModel();
    this.setState({
      showPatientPopup: true,
      patientAdd: {},
    });
  };

  togglePopup() {
    this.setState({
      showPatientPopup: !this.state.showPatientPopup,
    });
  }

  closeApptPopup = (patientInfo) => {
    this.setState({
      showApptPopup: false,
      patientInfo: patientInfo,
    });
  };

  async authToken() {
    this.closeMenu();
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

    const el = document.createElement("textarea");
    el.value = jwt;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    console.log("Auth token copied to clipboard.");
  }

  async admin() {
    this.closeMenu();
    this.props.history.push({
      pathname: "/users",
    });
  }
  async report() {
    this.closeMenu();
    this.props.history.push({
      pathname: "/reportdashboard",
    });
  }
  // sigout from app
  async signOut() {
    //await Auth.signOut();
    //await this.props.getCurrentUserInfo();
    //Storage.remove("username");
    this.props.currentUserSignout();
    // this.props.history.push({
    //     pathname: "/"
    // });
    //this.closeMenu();
  }

  showMenu = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: !this.state.open,
    });
  }

  closeMenu = () => {
    this.setAnchorEl(null);
  };

  _onIdle(e) {
    let linkPathParams = this.props.location.pathname.split('/');
    if (!(linkPathParams && linkPathParams.length > 0 && (linkPathParams[1] && linkPathParams[1] === 'v'))) {
      this.props.currentUserSignout();
    }
    //console.log('last active', this.idleTimer.getLastActiveTime());
  }

  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={3600000}
        />
        <header className="bg-white sticky-top py-2 py-lg-3">
          <div className="container-fluid container-padding">
            <div className="d-flex align-items-center flex-wrap position-relative">
              <div className="header-left flex-grow-1">
                <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                  <div className="header-logo">
                    <Link to={"/"}>
                      <CustomImg
                        src={this.state.logoImageUrl}
                        className="img-fluid"
                        alt="SportsMed Connect"
                        longdesc="SportsMed Connect"
                        title="SportsMed Connect"
                      />
                    </Link>
                  </div>
                  <div className="d-none d-lg-block">
                    {!isMobile &&
                    this.props.userInfo &&
                    this.props.userInfo.username &&
                    this.state.currentPage === "" ? (
                      <PatientSearchbox />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {Storage.get("username") ? (
                <div className="header-right pl-2">
                  <div className="d-flex align-items-center">
                    {!isMobile && this.state.currentPage === "" ? (
                      <div className="new-patient-area">
                        {/* <button type="button" className="btn btn-primary btn-small btn-shadow text-uppercase text-white" data-toggle="modal" onClick={this.newPatientClick}>New Patient</button> */}
                        <Button
                          data-toggle="modal"
                          onClick={this.newPatientClick}
                          className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
                        >
                          New Patient
                        </Button>
                      </div>
                    ) : null}
                    <div className="profile-area">
                      <div className="dropdown">
                        <Button
                          className="profile-btn font-16 text-initial"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={this.showMenu}
                        >
                          <span className="d-flex-">
                            <span className="d-none d-lg-inline-block font-16">
                              {this.state.displayName}
                            </span>
                            <span className="user-profile-icon d-none d-lg-inline-block">
                              {/* <CustomImg
                                src="icon_header_user.svg"
                                alt="User Profile"
                                className="d-lg-none"
                              /> */}
                            </span>
                            <span
                              className={`navbar-hamburger d-lg-none ${
                                this.state.open ? "" : "collapsed"
                              }`}
                            >
                              <span></span>
                            </span>
                          </span>
                        </Button>
                        <Menu
                          className="profile-menu"
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          open={this.state.open}
                          onClose={this.closeMenu}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {!isMobile && (
                            <div className="px-3 py-2 d-lg-none">
                              <div className="font-16">
                                {this.state.displayName}
                              </div>
                            </div>
                          )}
                          {!isMobile && this.state.admin ? (
                            <MenuItem
                              className="w-100 px-3 py-2 justify-content-start px-3"
                              onClick={() => this.admin()}
                            >
                              Admin
                            </MenuItem>
                          ) : null}
                          {this.state.admin || this.state.isProvider ? (
                            <MenuItem
                              className="w-100 px-3 py-2 justify-content-start px-3"
                              onClick={() => this.report()}
                            >
                              Reports
                            </MenuItem>
                          ) : null}
                          {this.state.developer ? (
                            <MenuItem
                              className="w-100 px-3 py-2 justify-content-start px-3"
                              onClick={() => this.authToken()}
                            >
                              Auth Token
                            </MenuItem>
                          ) : null}
                          <MenuItem
                            className="w-100 px-3 py-2 justify-content-start px-3"
                            onClick={() => this.signOut()}
                          >
                            Logout
                          </MenuItem>
                        </Menu>

                        {/* <Button className="profile-btn font-16 text-initial" type="button" id="profileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span className="d-flex-">
                                                    <span className="d-none d-md-inline-block font-16">{this.props.userInfo.attributes.email}</span>
                                                    <span className="user-profile-icon">
                                                        <CustomImg src="icon_header_user.svg" alt="User Profile" className="d-md-none" />
                                                    </span>
                                                </span>
                                            </Button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="profileDropdown">
                                                <div className="px-3 py-2 d-lg-none">
                                                    <div className="mb-2"> {this.props.userInfo.attributes.email} </div>
                                                </div>
                                                <Button className={"dropdown-item py-2 justify-content-start px-3 text-secondary"} onClick={() => this.signOut()}>Logout</Button>
                                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </header>
        {/* <PatientModal ref={this.patientModalRef} physicians={this.props.physicians}></PatientModal> */}
        {this.state.showPatientPopup ? (
          <PatientModal
            closePopup={this.togglePopup.bind(this)}
            showPopup={this.state.showPatientPopup}
            physicians={this.props.physicians}
          ></PatientModal>
        ) : (
          ""
        )}
        {this.state.patientAdd && this.state.patientAdd.status === "success" ? (
          <ScheduleApptModal
            patientInfo={this.state.patientAdd.data}
            closePopup={this.closeApptPopup.bind(this)}
            showPopup={this.state.showApptPopup}
          ></ScheduleApptModal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Header;
