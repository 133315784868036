import { REPORTSUMMARY_GET_SUCCESS, REPORTSUMMARY_GET_ERROR, REPORTLIST_GET_SUCCESS, REPORTLIST_GET_ERROR } from '../actions/actionTypes';

const INITIAL_STATE = {
  reportSummary: [],
  reportList: []
};

export const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case REPORTSUMMARY_GET_SUCCESS:
    case REPORTSUMMARY_GET_ERROR:
      return {
        ...state,
        reportSummary: action.payload
      };
    case REPORTLIST_GET_SUCCESS:
    case REPORTLIST_GET_ERROR:
      return {
        ...state,
        reportList: action.payload
      };

    default:
      return state;
  }
};
