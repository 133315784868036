import { connect } from 'react-redux';
import Pastappointment from '../../../Components/PageContainer/Dashboard/Pastappointment';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {    
    return {
        pastappointments: state.appointment.pastappointments,
        physicians: state.physician.physicians,
    };
};

const mapDispatchToProps = dispatch => ({
    getPastAppointments: (filter,pageInfo) => dispatch(actionCreators.pastAppointmentList(filter,pageInfo)),
    getPhysicianList: () => dispatch(actionCreators.physicianList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pastappointment);