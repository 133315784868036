import React from "react";
import CustomImg from "../../Global/CustomImg/CustomImg";

const CameraBlockMsg = ({ logoImageUrl }) => {
  return (
    <div>
      <div className="thankyou-page-wrapper waiting-session scrollbar">
        <div className="scroll-min-height position-relative d-flex flex-column align-items-center justify-content-center">
          <div className="thankyou-page-body text-center">
            <div className="mb-4">
              {logoImageUrl && (
                <CustomImg
                  src={logoImageUrl}
                  className="img-fluid"
                  alt="Logo"
                />
              )}
            </div>

            <div className="text-center thank-you-text">
              <p className="mb-0">
                Your telehealth session requires access to both your microphone
                and your camera. Please go to your browser settings and allow
                mic and camera access, then click on the link again. Otherwise,
                your appointment cannot resume.
              </p>
            </div>
          </div>
        </div>

        <div className="thankyou-page-footer text-center">
          <p className="mb-0 p-3">A Theos Health Partner</p>
        </div>
      </div>
    </div>
  );
};

export default CameraBlockMsg;
