import React from "react";
import CustomImg from "../../Global/CustomImg/CustomImg";

const Waitingmsg = ({isPhysician, isPhysianJoined, logoImageUrl}) => {
    if (isPhysician) {
        return (
            <div className="thankyou-page-wrapper waiting-session scrollbar">
                <div className="scroll-min-height position-relative d-flex flex-column align-items-center justify-content-center">
                    <div className="thankyou-page-body text-center">
                        <div className="mb-4">
                            <CustomImg src={logoImageUrl} className="img-fluid" alt="Logo"/>
                        </div>

                        <div className="text-center thank-you-text">
                            <p className="mb-0">
                                Initializing room.  Please wait.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="thankyou-page-footer text-center">
                    <p className="mb-0 p-3">A Theos Health Partner</p>
                </div>
            </div>
        )
    }

    if (isPhysianJoined) {
        return null;
    }

    return (
        <div className="thankyou-page-wrapper waiting-session scrollbar">
            <div className="scroll-min-height position-relative d-flex flex-column align-items-center justify-content-center">
                <div className="thankyou-page-body text-center">
                    <div className="mb-4">
                        <CustomImg src={logoImageUrl} className="img-fluid" alt="Logo"/>
                    </div>

                    <div className="text-center thank-you-text">
                        <p className="mb-0">
                            Your online appointment will begin shortly. As soon as your provider joins, the
                            video session will begin.
                        </p>
                    </div>
                </div>
            </div>

            <div className="thankyou-page-footer text-center">
                <p className="mb-0 p-3">A Theos Health Partner</p>
            </div>
        </div>
    );
};

export default Waitingmsg;
