import { connect } from "react-redux";
import Reportdashboards from "../../../Components/PageContainer/Reports/Reportdashboards";
import * as actionCreators from "../../../store/actions/index";

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
    reportSummary: state.report.reportSummary,
    physicians: state.physician.physicians,
  };
};
const mapDispatchToProps = (dispatch) => ({
  //getUsers: (filterBy, sortOn, sortBy, practice) => dispatch(actionCreators.userList(filterBy, sortOn, sortBy, practice)),
  getPhysicianList: () => dispatch(actionCreators.physicianList()),
  getDashboardCount: (searchFilter) =>
    dispatch(actionCreators.reportDashboardCount(searchFilter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reportdashboards);
