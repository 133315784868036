import "date-fns";
import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { isMobile } from "mobile-device-detect";
import NumberFormat from "react-number-format";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ScheduleApptModal from "../../../Containers/PageContainer/Patient/ScheduleAppointment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DateFnsUtils from "@date-io/date-fns";
import classNames from "classnames";
import { alertService } from "../../../data/services/alertService";
import { Tooltip } from "@material-ui/core";
import momentTZ from "moment-timezone";
import _ from "lodash";

class Appointmentlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      currentDatetime: moment().format("MM/DD/YYYY hh:mm").valueOf(),
      showApptPopup: false,
      scheduleInfo: "",
      active_appointment: "",
      future_appointments: [],
      apptStatus: {},
      appointment_shortcode: {},
      appointment_participants: {},
      copied: false,
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    //await this.props.getTodayAppointments(this.state.startDate);
    this.getAppointments(this.state.startDate);
    //this.getPastAppointments();

    // call function when component load
    this.checkAppointmentStatus();

    this.interval = setInterval(() => {
      this.checkAppointmentStatus();
    }, 30000); // call function every 30sec

    this.intervalAppointment = setInterval(() => {
      this.getAppointments(this.state.startDate);
    }, 60000); // call function every 60sec
    await this.props.getPhysicianList();
  }

  checkAppointmentStatus() {
    if (this.props.appointments && this.props.appointments.length > 0) {
      let future_appointments = [];
      this.setState({
        active_appointment: "",
      });
      this.props.appointments.forEach((val, index) => {
        let adjust_start_minutes = "15";
        let adjust_end_minutes = "5";
        let currentDatetime = moment();
        let start_datetime = moment(val.startDate).subtract(
          adjust_start_minutes,
          "minutes"
        );
        let end_datetime = moment(val.endDate).add(
          adjust_end_minutes,
          "minutes"
        );

        if (
          (start_datetime.isSameOrBefore(currentDatetime) &&
          end_datetime.isSameOrAfter(currentDatetime)) || _.includes(['IN_PROGRESS'], val.roomStatus)
        ) {
          this.props.getAppointmentStatus(val.id);
        }

        // make the appointment active
        if (
          (moment(val.startDate).isSameOrBefore(currentDatetime) &&
          moment(val.endDate).isSameOrAfter(currentDatetime)
        ) || _.includes(['IN_PROGRESS'], val.roomStatus)) {
          this.setState({
            active_appointment: val.id,
          });
        }

        if (moment(val.startDate).isAfter(currentDatetime)) {
          future_appointments.push(val.id);
        }
      });
      this.setState({ future_appointments: future_appointments });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval); // clear apponitment status interval
    clearInterval(this.intervalAppointment); //clear appointment interval
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointmentStatus !== this.props.appointmentStatus) {
      let appt_status = this.state.apptStatus;
      let appt_shortcode = this.state.appointment_shortcode;
      let appt_participants = this.state.appointment_participants;
      appt_status[
        this.props.appointmentStatus.id
      ] = this.props.appointmentStatus.appStatus;
      appt_shortcode[
        this.props.appointmentStatus.id
      ] = this.props.appointmentStatus.shortcode;
      appt_participants[
        this.props.appointmentStatus.id
      ] = this.props.appointmentStatus.participants;
      this.setState({
        apptStatus: appt_status,
        appointment_shortcode: appt_shortcode,
        appointment_participants: appt_participants,
      });
    }
    if (prevProps.appointments !== this.props.appointments) {
      this.checkAppointmentStatus();
    }
    if (prevProps.appointmentAdd !== this.props.appointmentAdd) {
      if (this.props.appointmentAdd) {
        if (this.props.appointmentAdd.status === "success") {
          this.handleChange(this.state.startDate);
          this.getAppointments(this.state.startDate);
        }
      }
    }
    if (prevProps.appointmentCancel !== this.props.appointmentCancel) {
      if (this.props.appointmentCancel) {
        if (this.props.appointmentCancel.status === "success") {
          this.handleChange(this.state.startDate);
          this.getAppointments(this.state.startDate);
        }
      }
    }
  }

  handleChange = (date) => {
    if(moment(this.state.startDate).format("MM/DD/YYYY") !== moment(date).format("MM/DD/YYYY")) {
      this.setState({
        startDate: date,
      });
      // this.getAppointments(date);
      this.props.handleDateChange(date);
    }
  };


  getAppointments = async (appointment_date) => {
    const isTodaySelected = momentTZ(appointment_date).tz('America/Chicago').format("MM/DD/YYYY") === momentTZ().tz('America/Chicago').format("MM/DD/YYYY"); 

    const pageInfo = {
      // page: page || this.state.pageInfo.current_page,
      page: this.props.pageNumber,
      limit: isTodaySelected ? 1000 : 10
  };
    await this.props.getAppointmentList(
      appointment_date,
      this.props.physicians,
      pageInfo
    );
  };

  // async getPastAppointments() {
  //     await this.props.getPastAppointments();
  // }
  toggleApptPopup = (scheduleInfo) => {
    this.setState({
      showApptPopup: !this.state.showApptPopup,
      scheduleInfo: scheduleInfo,
    });
  };

  renderPatientName(val, attendee_name) {
    if (val.patientName === ", ") {
      return (
          <span className="font-italic">PHI Removed</span>
      );
    }

    return (
        <span>
          <span>{val.patientName}</span>
          {attendee_name.length > 0 ? (
              isMobile ? (
                  <span className="ml-2">
                    +{attendee_name.length}
                  </span>
              ) : (
                <Tooltip
                    title={attendee_name.map((val, index) => {
                      return (
                          <span className="d-block">{val}</span>
                      );
                    })}
                    arrow
                    placement="bottom-start"
                >
                  <span className="attendee-popover-icon ml-3">
                    <span className="material-icons">people</span>
                  </span>
                </Tooltip>
              )
          ) : (
              ""
          )}
    </span>
    );
  }

  render() {
    const { apptStatus, appointment_participants } = this.state;
    return (
      <div>
        <div
          className="tab-pane fade show active bg-white"
          id="schedule"
          role="tabpanel"
          aria-labelledby="schedule-appointments"
        >
          <div className="title-today-date font-21 text-primary">
            {/*<div className="title-today-date-text"> Today <span className="text-dusty-gray position-relative">Mar 22</span> </div>*/}
            <div className="position-relative d-inline-block">
              {/* <DatePicker
                     selected={this.state.startDate}
                     name="startDate"
                     onChange={this.handleChange}
                     dateFormat="MMMM d, yyyy"
                 /> */}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  // disableToolbar
                  variant="inline"
                  views={["year", "month", "date"]}
                  format="MMMM d, yyyy"
                  name="startDate"
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  InputAdornmentProps={{ position: "end" }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div className="table-responsive div-table">
            {/* <!--Table--> */}
            {/* <table className="d-table w-100"> */}
            <table className={this.props.appointments?.length > 0 ? "d-table w-100" :"noRecordsHideOverflow"}>

              {/* <!--Table Head--> */}
              <thead className="table-head text-dusty-gray">
                <tr className="position-relative">
                  <td className="td-title-1 position-relative">
                    <span className="d-inline-block">Appointment</span>
                  </td>
                  <td className="td-title-2">Patient</td>
                  {!isMobile && (
                    <>
                      <td className="td-title-3">Procedure</td>
                      <td className="td-title-4">Procedure Date</td>
                      <td className="td-title-5">
                        {this.props.isClinicanUser === true
                          ? "Physician"
                          : "Provider"}
                      </td>
                      <td className="td-title-6">Phone</td>
                      <td className="td-title-7"></td>
                      <td className="td-title-8"></td>
                    </>
                  )}
                </tr>
              </thead>
              {/* <!--Table Body--> */}
              <tbody className="table-body">
                {this.props.appointments &&
                  this.props.appointments.length > 0 ? (
                    this.props.appointments.map((val, index) => {
                      let apptStatusClass = classNames({
                        "icon-sucess": val.status === "COMPLETED" || val.roomStatus === "COMPLETE",
                        "icon-error":
                          val.roomStatus === "MISSED" ||
                          val.roomStatus === "cancelled",
                        "icon-user-times": val.roomStatus === "MISSED_CLINICIAN",
                        "icon-exclamation": val.roomStatus === "FAILED",
                      });
                      let attendee_name = [];
                      if (val.attendee && val.attendee.length > 0) {
                        val.attendee.forEach((val, index) => {
                          if(val.attendee_name!=''){
                            attendee_name.push(val.attendee_name);
                          }
                        });
                      }
                      return (
                        <tr
                          key={index}
                          className={
                            "lh-16 " +
                            (this.state.active_appointment === val.id &&
                              apptStatus &&
                              apptStatus[val.id] !== "MISSED" &&
                              apptStatus[val.id] !== "MISSED_CLINICIAN" &&
                              apptStatus[val.id] !== "FAILED"
                              ? "current "
                              : "") +
                            (this.state.future_appointments.indexOf(val.id) > -1
                              ? "upcoming "
                              : "")
                          }
                        >
                          <td>
                            <div className="time-date-box text-center text-dusty-gray py-2">
                              <span className="d-block font-21">
                                <Moment format="hh:mm A">{val.startDate}</Moment>
                              </span>
                              {val.appointmentType}
                            </div>
                          </td>
                          <td>
                            <span className="roboto-bold d-flex">
                              {this.renderPatientName(val, attendee_name)}
                            </span>
                            <span className="d-block">
                              <span className="d-inline-block text-dusty-gray">
                                DOB&nbsp;
                            </span>
                              {val.patientDOB ? (
                                <Moment className="" format="MM/DD/YYYY">
                                  {val.patientDOB}
                                </Moment>
                              ) : null}
                            </span>
                            {/*<span>{JSON.stringify(val, null, 4)}</span>*/}
                          </td>
                          {!isMobile && (
                            <>
                              <td>
                                {val.procedureDesc && val.procedureDesc !== "NA"
                                  ? val.procedureDesc
                                  : "n/a"}
                              </td>
                              <td>
                                {val.procedureDate &&
                                  val.procedureDate !== "NA" &&
                                  moment(val.procedureDate).isValid() ? (
                                    <Moment className="" format="MM/DD/YYYY">
                                      {val.procedureDate}
                                    </Moment>
                                  ) : (
                                    "n/a"
                                  )}
                              </td>
                              <td>
                                {this.props.isClinicanUser === true
                                  ? val.physician
                                  : val.resource}
                              </td>
                              <td>
                                <a href={'tel:'+val.patientPhone}>
                                <NumberFormat
                                  format="(###) ###-####"
                                  displayType={"text"}
                                  mask="_"
                                  value={val.patientPhone}
                                />
                                </a>
                              </td>
                              <td className=" text-center">
                                {/* <a href={void (0)} onClick={() => this.toggleApptPopup(val)} className={"edit-icon-btn p-3 text-decoration-none d-flex align-items-center justify-content-center"}>
                                     <span className="icon-edit"></span>
                                 </a> */}
                                { val.roomStatus !== "COMPLETED" && val.roomStatus !== "COMPLETE" && val.roomStatus !== "FAILED" && this.state.future_appointments.indexOf(val.id) > -1 && (appointment_participants[val.id] || []).length ==
                                  0  ?
                                  <IconButton
                                    href={void 0}
                                    onClick={() => this.toggleApptPopup(val)}
                                    aria-label="edit"
                                    className={
                                      "edit-icon-btn text-primary p-3 text-decoration-none d-flex align-items-center justify-content-center"
                                    }
                                  >
                                    <span className="icon-edit"></span>
                                  </IconButton>
                                  : ''}
                              </td>

                              <td className="text-center">
                                { !_.includes(["COMPLETED", "COMPLETE", "FAILED", "MISSED", "MISSED_CLINICIAN"], val.roomStatus) && 
                                  appointment_participants &&
                                  (appointment_participants[val.id] || []).length >
                                  0 &&
                                  val.physicianId === this.props.userInfo.id ? (
                                    //   <Button onClick={() => this.startSession(val)} className="btn btn-primary session-start-btn text-white btn-small text-uppercase" disabled={(moment(val.startDate).format('MM/DD/YYYY hh:mm').valueOf() <= this.state.currentDatetime && moment(val.endDate).format('MM/DD/YYYY hh:mm').valueOf() >= this.s tate.currentDatetime ? false : false)}>Start Session</Button>
                                    <Button
                                      onClick={() =>
                                        this.props.startSession(
                                          val,
                                          this.state.appointment_shortcode[val.id]
                                        )
                                      }
                                      className="btn btn-primary session-start-btn text-white btn-small text-uppercase"
                                    >
                                      Start Session
                                </Button>
                                  ) : (
                                    <span
                                      className={"font-21 " + apptStatusClass}
                                      title={val.roomStatus}
                                    ></span>
                                  )}
                              </td>
                            </>
                          )}

                          {isMobile && (
                            <td className="text-center btn-td">
                              { !_.includes(["COMPLETED", "COMPLETE", "FAILED", "MISSED", "MISSED_CLINICIAN"], val.roomStatus) &&
                                appointment_participants &&
                                (appointment_participants[val.id] || []).length >
                                0 &&
                                val.physicianId === this.props.userInfo.id ? (
                                  <Button
                                    onClick={() =>
                                      this.props.startSession(
                                        val,
                                        this.state.appointment_shortcode[val.id]
                                      )
                                    }
                                    className="btn btn-primary session-start-btn text-white btn-small text-uppercase"
                                  >
                                    Start Session
                              </Button>
                                ) : (
                                  ""
                                )}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="d-flex w-100">
                      {/*<tr>
                      <td colSpan="8" className="text-center no-records-td">
                        No Record Found
                    </td>
                    </tr> */}
                      <td colSpan="8" className="no-records-td d-flex w-100 justify-content-center ">
                        No Record Found
                    </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {this.props.loadMoreSection(this.state.startDate)}
          </div>
        </div>
        {this.state.showApptPopup ? (
          <ScheduleApptModal
            scheduleInfo={this.state.scheduleInfo}
            closePopup={this.toggleApptPopup.bind(this)}
            showPopup={this.state.showApptPopup}
          ></ScheduleApptModal>
        ) : null}
      </div>
    );
  }
}

export default Appointmentlist;
