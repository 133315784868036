import React, { useState, useEffect, useRef } from "react";

import Videocontrols from "./Videocontrols";

const Participant = ({
  participant,
  handleAudioToggle,
  handleVideoToggle,
  toggleAudio,
  toggleVideo,
  isLocal,
  showVideoControl,
  isMainPhysician,
  screenShare,
  handleScreenShareToggle,
  handleCameraFlip,
  remoteUserVideotrack,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [remoteVideotrack, setRemoteVideotrack] = useState([]);
  const [currentVideotrack, setCurrentVideotrack] = useState([]);


  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    //console.log(remoteUserVideotrack);
    if (!participant) {
      return;
    }
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.videoTracks.forEach((track) => {
      if (track.kind == 'video') {
        setCurrentVideotrack(track.trackSid);
      }
    });

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);


  useEffect(() => {
    const remoteVideotrack = remoteUserVideotrack;
    setRemoteVideotrack(remoteUserVideotrack);
    //console.log(remoteVideotrack);
  }, [remoteUserVideotrack]);
  // Show the image
  //const img = document.getElementById("my-screenshot");
  //img.setAttribute("src", frame.dataUri);

  return (
    <div className="position-relative">
      {/* <h3>{participant.identity}</h3> */}
      
      {/* {currentVideotrack+'::::::'+JSON.stringify(remoteVideotrack)}
      {remoteVideotrack && currentVideotrack && remoteVideotrack[currentVideotrack] && remoteVideotrack[currentVideotrack].isTrackEnabled ?JSON.stringify(remoteVideotrack[currentVideotrack].isTrackEnabled)+':::'+currentVideotrack:''} */}
      {!isLocal ? (


        <div className={remoteVideotrack && currentVideotrack && remoteVideotrack[currentVideotrack] && remoteVideotrack[currentVideotrack].isTrackEnabled ? "visible" : "invisible"}><video ref={videoRef} autoPlay={true} id="my-video" type='video/webm' /></div>
      ) : (
          <video ref={videoRef} autoPlay={true} type='video/webm'/>
        )}
      <audio ref={audioRef} autoPlay={true} />
      {/* {isLocal && (
        <Videocontrols
          handleAudioToggle={handleAudioToggle}
          handleVideoToggle={handleVideoToggle}
          audio={toggleAudio}
          video={toggleVideo}
        />
      )} */}
      {showVideoControl && (
        <Videocontrols
          handleAudioToggle={handleAudioToggle}
          handleVideoToggle={handleVideoToggle}
          audio={toggleAudio}
          video={toggleVideo}
          isMainPhysician={isMainPhysician}
          screenShare={screenShare}
          handleScreenShareToggle={handleScreenShareToggle}
          handleCameraFlip={handleCameraFlip}
        />
      )}
    </div>
  );
};

export default Participant;
