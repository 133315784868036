import React, { Component } from "react";
// import { isMobile } from "mobile-device-detect";
// import Moment from "react-moment";
// import moment from "moment";
import Room from "../../../Containers/PageContainer/Conference/Room";
import _ from "lodash";
import momentTZ from "moment-timezone";

class Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleInfo: {},
      showEndSessionConfirm: false,
      showSessionQuality: false,
      fields: {},
      isClinican: false,
      twillioInfo: {},
      shortcode: "",
      showLoadImage: false,
      userId: "",
      isPhysianJoined: false,
      scheduleStart: ''
    };
  }

  /*calculateScrollbarHeight(){
    let winH = document.body.clientHeight;
    if(document.querySelector(".participant-auto-height")) { 
      let videoBoxElem = document.querySelector(".participant-auto-height");
      let videoBoxHeight = document.querySelector(".participant-auto-height").clientHeight;
      if(document.querySelector("header")){
        let headerHeight = document.querySelector("header").clientHeight;
        if(videoBoxHeight > (winH - (144 + headerHeight))) {
          videoBoxElem.style.height = ((winH - (144 + headerHeight)) - 15) +'px';
        }
      }
      else {
        if(videoBoxHeight > (winH - 70)) {
          videoBoxElem.style.height = ((winH - 70) - 15) +'px';
        }
      }
    }
  }*/

  async componentWillMount() {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.detail !== ""
    ) {
      this.setState({
        scheduleInfo: this.props.location.state.detail,
        isClinican: true,
      });
    }
    await this.props.getCurrentUserInfo();
  }

  async componentDidMount() {
    // get shortode from url
    let params = this.props.match.params;
    if (params && params.shortcode) {
      this.setState({ shortcode: params.shortcode });
      let shortcode = params.shortcode;
      shortcode = shortcode.replace("-p", "");
      shortcode = shortcode.replace("-a1", "");
      shortcode = shortcode.replace("-a2", "");
      await this.props.getApplicationInfo(shortcode);
      // await this.props.getAppointmentParticipants(shortcode);
      //await this.props.generateTwillioToken(params.shortcode);
    } else {
      // when clinican start session
      if (this.state.scheduleInfo && this.state.scheduleInfo.id) {
        //await this.props.getCurrentUserInfo();
        await this.props.joinAppointmentSession(this.state.scheduleInfo.id);
      } else {
        this.props.history.push({
          pathname: "/",
        });
      }
    }


    /*this.calculateScrollbarHeight();
    window.addEventListener("resize", this.calculateScrollbarHeight.bind(this));*/
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //     if (nextProps.userInfo !== this.props.userInfo) {
  //         console.log('true');
  //         return true;
  //     } else {
  //         console.log('false');
  //         return false;
  //     }
  // }
  async componentDidUpdate(prevProps) {
    if (prevProps.appliationInfo !== this.props.appliationInfo) {
      if(this.props.appliationInfo?.statusCode === 404) {
        this.props.history.push({
          pathname: "/i",
        });
      } else {
        this.props.getAppointmentStatus(this.props.appliationInfo.id)

        this.intervalAppointment = setInterval(() => {
          //console.log(start_datetime);
          //console.log(currentDatetime);
          //alert(start_datetime.isSameOrBefore(currentDatetime));

          this.props.getAppointmentParticipants(this.props.appliationInfo.shortCode);

        }, 30000); // call function every 30sec
      }
    }
    if (prevProps.appointmentStatus !== this.props.appointmentStatus) {
      let participants = this.props.appliationInfo.participants;



      let present_participant = [];
      let link_params = this.props.location.pathname.split('/');
      let joinedAttendee = this.state.shortcode.split('-');

      if (link_params[1] && link_params[1] === 'a') {
        if (participants && ((participants.includes('attendee1') && participants.includes('attendee2')) ||
          (joinedAttendee[1] === 'a1' && participants.includes('attendee1')) ||
          (joinedAttendee[1] === 'a2' && participants.includes('attendee2')))
        ) {
          this.props.history.push({
            pathname: "/c",
          });
          return true;
        }
      } else if (link_params[1] && link_params[1] === 'v') {
        if ((participants && participants.includes('patient')) ||
            ((joinedAttendee['1'] === 'p') && participants && participants.includes('patient'))) {
          this.props.history.push({
            pathname: "/c",
          });
          return true;
        }
      }

      if(this.state.shortcode) {
        if(_.includes(['COMPLETE', 'COMPLETED', 'FAILED'], this.props.appointmentStatus.appStatus)) {
          this.props.history.push({
            pathname: "/e",
          });
        } else if(_.includes(['MISSED', 'MISSED_CLINICIAN'], this.props.appointmentStatus.appStatus)) {
          this.props.history.push({
            pathname: "/i",
          });
        } else {
          await this.props.generateTwillioToken(this.state.shortcode);
        }
      }
    }

    if (
      prevProps.conference.twillioInfo !== this.props.conference.twillioInfo
    ) {
      if (this.props.conference.twillioInfo.statusCode === 404) {
        this.props.history.push({
          pathname: "/i",
        });
      }
      if (this.props.conference.twillioInfo.statusCode === 400 && this.state.isClinican === true) {
        setTimeout(function () {
          this.props.history.push({
            pathname: "/",
          });
        }.bind(this), 3000);
      }

      this.setState({ twillioInfo: this.props.conference.twillioInfo });
    }

    if (prevProps.userInfo !== this.props.userInfo) {
      if (this.props.userInfo && this.props.userInfo.id) {
        this.setState({ userId: this.props.userInfo.id });
      } else {
        this.setState({ userId: "" });
      }
      // if (this.props.userInfo && this.props.userInfo.attributes['custom:ResourceId']) {
      //     this.setState({ userId: this.props.userInfo.attributes['custom:ResourceId'] });
      // } else {
      //     this.setState({ userId: '' });
      // }      

    }

    if (prevProps.appointmentParticipants !== this.props.appointmentParticipants) {
      //console.log(this.props.appointmentParticipants);
      let participants = this.props.appointmentParticipants.participants;

      let appStatus = this.props.appointmentParticipants.roomStatus;
      if (_.includes(['COMPLETE', 'FAILED', 'MISSED_CLINICIAN'], appStatus)){
        this.endSession();
      }
      let scheduleStart = this.props.appliationInfo.scheduleStart;
      let start_datetime = momentTZ.utc(scheduleStart).tz("America/Chicago");
      let end_datetime = momentTZ.utc(this.props.appliationInfo.scheduleEnd).tz("America/Chicago");
      let currentDatetime = momentTZ().tz("America/Chicago");
      
      if (!participants.includes('physician')) {
        this.setState({ isPhysianJoined: false });
        if (start_datetime.isSameOrBefore(currentDatetime) && currentDatetime.isSameOrAfter(end_datetime) && appStatus !== 'IN_PROGRESS') {
          setTimeout(function () {
            if (!this.state.isPhysianJoined) {
              this.endSession();
            }
          }.bind(this), 300000);
        }
      }
      else {
        this.setState({ isPhysianJoined: true });
      }

    }

    //console.log(this.props.conforence.twillioInfo.token);
  }
  /*componentWillUnmount() {
    window.removeEventListener("resize", this.calculateScrollbarHeight.bind(this));
  }*/
  endSession = () => {
    if (this.state.isClinican === true) {
      this.props.history.push({
        pathname: "/",
      });
    } else if(!this.state.isPhysianJoined && (_.includes(['MISSED', 'MISSED_CLINICIAN'], this.props.appointmentParticipants.roomStatus)) ){
      console.log("endSession")
      this.props.history.push({
        pathname: "/i",
      });
    } else {
      this.props.history.push({
        pathname: "/e",
      });
    }
    // Need to refresh window to remove microphone access permission
    setTimeout(() => window.location.reload(false), 1000);
  };
  loadImage() {
    //let showLoadImage =  this.state.showLoadImage;
    this.setState({ showLoadImage: !this.state.showLoadImage });
  }
  render() {
    return (
      <main className="main-container">
        <div className="content-area h-100">
          <div className="container-fluid container-padding">
            <h2 className="font-18 quicksand-regular text-charcoal pb-2 text-left">
              Live Session
            </h2>
            {/* {!isMobile ? <div>
                            {!this.state.showLoadImage ? <div onClick={this.loadImage.bind(this)}>Load Image</div> : <div onClick={this.loadImage.bind(this)}>Hide Image</div>}
                        </div> : ''} */}
            <div className="content-tab-panel">
              <div className="tab-content bg-white py-0 px-0">
                <div className="participant">
                  {this.state.twillioInfo && this.state.twillioInfo.token ? (
                    <Room
                      userId={this.state.userId}
                      shortcode={this.state.shortcode}
                      token={this.state.twillioInfo.token}
                      roomName={this.state.twillioInfo.room}
                      isClinican={this.state.isClinican}
                      endSessionRed={this.endSession}
                      appointmentId={this.state.scheduleInfo.id}
                      isClinician={this.state.isClinican}
                      conference={this.props.conference.twillioInfo}
                      showLoadImage={this.state.showLoadImage}
                      scheduleInfo={this.state.scheduleInfo}
                      applicationInfo={this.props.appliationInfo}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Conference;
