import Http from '../../Services/Http';
import {
  URL_PATIENT_TWILIOTOKEN, APINAME_VISITS, URL_APPOINTMENT_JOIN, APINAME_APPOINTMENT, URL_APPOINTMENT_FEEDBACK,URL_APPOINTMENT_INFO
} from '../../Containers/Common/APIUtils';
import moment from 'moment-timezone';
const api = new Http({});

// update patient API call
const postTwillioToken = async (shortcode) => {

  let apiName = APINAME_VISITS;
  let path = URL_PATIENT_TWILIOTOKEN(shortcode);
  //let path = 'https://10q9u84x82.execute-api.us-east-1.amazonaws.com/devnew/visits/aaaa/start';

  let response = await api.post(apiName, path, {}, false, false);
  // let response = await fetch('https://theos-demo-api.devdigdev.com/video/token', {
  //           method: 'POST',
  //           body: JSON.stringify({
  //               identity:  moment().format('hh:mm:s'),
  //               room: 'dd'
  //           }),
  //           headers: {
  //               'Content-Type': 'application/json'
  //           }
  //       }).then(res => res.json());


  //return {'data':response,'status':'success'};
  return response;
}

const joinAppointmentSession = async (appointmentId) => {

  let apiName = APINAME_APPOINTMENT;
  let path = URL_APPOINTMENT_JOIN(appointmentId);

  let response = await api.get(apiName, path);
  //let response = data.twilioToken[0];

  return response;
}

const postSubmitSessionQuality = async (appointmentId, sessionInfo) => {
  let apiName = APINAME_APPOINTMENT;
  let path = URL_APPOINTMENT_FEEDBACK(appointmentId);
  let data = sessionInfo;
  let response = await api.put(apiName, path, data);
  //let response = data.twilioToken[0];

  return response;
}


const getAppointmentInfo = async (shortCode) => {
  let apiName = APINAME_APPOINTMENT;
  let path = URL_APPOINTMENT_INFO(shortCode);
  let response = await api.get(apiName, path,false,true,false);
  //let response = data.twilioToken[0];

  return response;
}

export const ConferenceService = {
  postTwillioToken, joinAppointmentSession, postSubmitSessionQuality, getAppointmentInfo
};
