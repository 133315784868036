export const User = {
    id: "id",
    systemEdxProviderId: "systemEdxProviderId",
    name: "name",
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    prefix: "prefix",
    suffix: "suffix",
    role: "role",
    teams: "teams",
    isAdmin: "isAdmin",
    isDeveloper: "isDeveloper",
    resourceId: "resourceId",
    practice: "practice",
    enabled: "enabled",
    patientCount: "patientCount",
}


export const Team = {
    id: "id",
    name: "name",
    clinicId: "clinicId"
}
