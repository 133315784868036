import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import PatientModal from '../../../Components/PageContainer/Patient/PatientModal';

const mapStateToProps = state => {
    return {
        physicians: state.physician.physicians,
        patientAdd: state.patient.patientAdd,
        patientUpdate:state.patient.patientUpdate,
    };
};

const mapDispatchToProps = dispatch => ({
    getPhysicianList: () => dispatch(actionCreators.physicianList()),
    //postPatient: (patient) => dispatch(postPatient(patient)),
    postPatient: (patient, physicians) => dispatch(actionCreators.addPatient(patient, physicians)),
    putPatient: (patient, physicians) => dispatch(actionCreators.updatePatient(patient, physicians)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientModal);