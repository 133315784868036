import React, { Component } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment-timezone';

import PastAppointmentList from './PastAppointmentList';
class Pastappointment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentDatetime: moment().format('h:mm').valueOf(),
            filter: '',
            pastappointments: [],
            pageInfo: []
        };
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pastappointments !== this.props.pastappointments) {

            this.setState({ pageInfo: this.props.pastappointments.page });
        }
    }
    async componentDidMount() {
        //await this.props.getTodayAppointments(this.state.startDate);
        this.getPastAppointments();
    }

    loadMorePastApp = (page) => {
        const pageInfo = {
            page: page
        };
        this.getPastAppointments(this.state.filter, pageInfo);
    }

    getPastAppointments = async (filter = '', pageInfo = {}) => {
        await this.props.getPastAppointments(filter, pageInfo);
    }

    handleTabSelect = (key) => {
        this.setState({ filter: key });
        this.getPastAppointments(key);
    }
    render() {

        return (
            <div className="tab-pane fade show active bg-white" id="past" role="tabpanel" aria-labelledby="past-appointments" >
                <div className="d-flex flex-wrap mb-3">

                    <div className="w-100 inner-tab-wrapper">
                        <Tabs 
                        id="controlled-tab-example" onSelect={this.handleTabSelect}>
                            {this.state.filter === "" ?     
                            <Tab className={""} eventKey={''} title="All" 
                            tabClassName={this.state.filter === "" ? 'active bg-white' : ''} disabled={this.state.filter === ""}>
                                <PastAppointmentList filtekey={this.state.filter} isClinicanUser={this.props.isClinicanUser} pastappointments={this.props.pastappointments} loadMorePastApp={this.loadMorePastApp}></PastAppointmentList>
                            </Tab>
                            : 
                            <Tab className={""} eventKey={'all'} title="All" disabled={this.state.filter === "all"}>
                                <PastAppointmentList filtekey={this.state.filter} isClinicanUser={this.props.isClinicanUser} pastappointments={this.props.pastappointments} loadMorePastApp={this.loadMorePastApp}></PastAppointmentList>
                            </Tab>
                            }
                            <Tab className={""} eventKey={'missed'} title="Missed" disabled={this.state.filter === "missed"}>
                                <PastAppointmentList filtekey={this.state.filter} isClinicanUser={this.props.isClinicanUser} pastappointments={this.props.pastappointments} loadMorePastApp={this.loadMorePastApp}></PastAppointmentList>
                            </Tab>
                            <Tab className={""} eventKey={'completed'} title="Completed" disabled={this.state.filter === "completed"}>
                                <PastAppointmentList filtekey={this.state.filter} isClinicanUser={this.props.isClinicanUser} pastappointments={this.props.pastappointments} loadMorePastApp={this.loadMorePastApp}></PastAppointmentList>
                            </Tab>
                            <Tab className={""} eventKey={'failed'} title="Failed" disabled={this.state.filter === "failed"}>
                                <PastAppointmentList filtekey={this.state.filter} isClinicanUser={this.props.isClinicanUser} pastappointments={this.props.pastappointments} loadMorePastApp={this.loadMorePastApp}></PastAppointmentList>
                            </Tab>
                        </Tabs>
                    </div>

                </div>
            </div >
        );
    }
}

export default Pastappointment;
