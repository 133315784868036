export const URL_APPOINTMENT = "/appointments";
export const APINAME_APPOINTMENT = "api";
export const URL_APPOINTMENT_CANCEL = (id) => {
  return "/appointments/" + id + "/cancel";
};
export const URL_APPOINTMENT_STATUS = (id) => {
  return "/appointments/" + id + "/status";
};
export const URL_APPOINTMENT_JOIN = (id) => {
  return "/appointments/" + id + "/join";
};

export const URL_APPOINTMENT_FEEDBACK = (id) => {
  return "/appointments/" + id + "/feedback ";
};
export const URL_APPOINTMENT_INFO = (shortcode) => {
  return "/appointments/" + shortcode;
};

export const URL_PATIENTS = "/patients";
export const APINAME_PATIENTS = "api";

export const URL_PHYSICIANS = "/clinicians";
export const APINAME_PHYSICIANS = "api";

export const URL_PHYSICIANS_AVAILABILITY = (id) => {
  return "/clinicians/" + id + "/availability";
};

export const APINAME_VISITS = "api";
export const URL_PATIENT_TWILIOTOKEN = (short_code) => {
  return "/visits/" + short_code + "/start";
};

export const URL_PAST_APPOINTMENT = (type) => {
  if (type === "all" || type === "") {
    return "/visits";
  } else {
    return "/visits/" + type;
  }
};

export const URL_REPORTS = "/visits/reportSummary";
export const URL_REPORTDETAILS = "/visits/reportDetail";
export const APINAME_REPORTS = "api";

export const URL_USERS = "/users";
export const APINAME_USERS = "api";

export const URL_WHOAMI = "/whoami";

export const APINAME_TEAMS = "api";
export const URL_TEAMS = "/careTeams";

// export const URL_PROFILE = '/patient_portal/mobile/users/profile'; // GET service
// export const URL_ORDERS = '/patient_portal/mobile/orders'; // GET & POST service

// export const URL_PRODUCTS_FAQS = id => {
//   return '/patient_portal/mobile/products/' + id + '/faqs'; // GET service
// };
