export const Patient = {
    id: "id",
    birthDate: "birthDate",
    firstName: "firstName",
    lastName: "lastName",
    phone: "phone",
    email: "email",
    physicianId: "physicianId",
    physician: "physician",
    procedureDesc: "procedureDesc",
    procedureDate: "procedureDate"
}
