export const Physician = {
    id: "id",
    systemEdxProviderId: "systemEdxProviderId",
    firstName: "firstName",
    lastName: "lastName",
    name: "name",
    phone: "phone",
    email: "email",
    role: "role"
}
