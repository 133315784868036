/* eslint-disable no-script-url */
import React, { Component } from "react";
import { isMobile } from "mobile-device-detect";
import "./Reports.css";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Tabs, Tab } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import * as _ from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

class Reportdashboards extends Component {
  state = {
    isAdmin: this.props.userInfo.isAdmin || false,
    searchFilter: {
      providerId: "-1",
      lastDays: "0",
      startDate: null,
      endDate: null,
    },
    startDPOpen: false,
    startDate: null,
    endDPOpen: false,
    endDate: null,
  };

  componentDidMount() {
    this.props.getPhysicianList();
    this._fetchRecords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo && this.props.userInfo !== prevProps.userInfo) {
      this.setState({ isAdmin: this.props.userInfo.isAdmin });
    }
  }

  //return to dashboard
  _backToDashboard = () => {
    this.props.history.push({ pathname: "/" });
  };

  _goToReportDetails = (type = "") => {
    var searchFilter = this.state.searchFilter;
    searchFilter.isAdmin = this.state.isAdmin;

    if (!this.state.isAdmin) searchFilter.providerId = this.props.userInfo.id;

    if (this.state.searchFilter.lastDays === "custom") {
      searchFilter.startDate = this.state.startDate;
      searchFilter.endDate = this.state.endDate;
    }

    this.props.history.push({
      pathname: "/reportdetail",
      state: { ...searchFilter, status: type },
    });
  };

  _fetchRecords = () => {
    const filter = _.clone(this.state.searchFilter);
    if (filter.lastDays === "custom") {
      filter.startDate = this.state.startDate;
      filter.endDate = this.state.endDate;
    } else {
      filter.startDate = null;
      filter.endDate = null;
    }
    this.props.getDashboardCount(filter);
  };

  _onProviderChange = (e) => {
    let searchFilter = this.state.searchFilter;
    searchFilter["providerId"] = e.target.value;
    this.setState({ searchFilter }, this._fetchRecords);
  };

  _onTabSelect = (key) => {
    let searchFilter = this.state.searchFilter;
    searchFilter["lastDays"] = key;
    this.setState({ searchFilter }, this._fetchRecords);
  };

  _onStartDateChange = (d) => {
    this.setState({ startDate: moment(d).format() });
  };

  _onEndDateChange = (d) => {
    this.setState({ endDate: moment(d).format() });
  };

  _onEndDateAccept = () => {
    let searchFilter = this.state.searchFilter;
    searchFilter["lastDays"] = "custom";
    this.setState({ searchFilter }, this._fetchRecords);
  };

  _formateRangeDate = () => {
    const { startDate, endDate } = this.state;
    return (
      moment(startDate).format("MM/DD/YYYY") +
      " - " +
      moment(endDate).format("MM/DD/YYYY")
    );
  };

  renderProviders = () => {
    const allPhysicians = this.props.physicians;
    const physicians =
      allPhysicians && allPhysicians.length
        ? allPhysicians.filter((x) => x.role === "Provider")
        : [];

    return (
      <div className="form-group report-provider-select">
        <FormControl className="w-100">
          <InputLabel id="demo-controlled-open-select-label">
            Select Provider
          </InputLabel>
          <Select
            className="w-100"
            labelId="providerLabel"
            id="providerId"
            name="providerId"
            value={this.state.searchFilter.providerId}
            onChange={this._onProviderChange}
          >
            <MenuItem value="-1">All Providers</MenuItem>
            {physicians &&
              physicians.length > 0 &&
              physicians.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.id}>
                    {val.lastName}, {val.firstName}{" "}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
    );
  };

  render() {
    const { reportSummary } = this.props;
    const { searchFilter, isAdmin, startDate, endDate } = this.state;

    return (
      <main className="main-container user-container bg-white">
        <div className="content-area scrollbar h-100">
          <div className="container-fluid container-padding">
            <div className="mb-4">
              {!isMobile && (
                <div className="clearfix mb-2">
                  <Typography>
                    <Link
                      className="back-dashboard-btn d-inline-flex align-items-center text-decoration-none"
                      href="javascript:void(0)"
                      onClick={this._backToDashboard.bind(this)}
                    >
                      <span class="material-icons mr-2">
                        keyboard_backspace
                      </span>
                      Back to Dashboard
                    </Link>
                  </Typography>
                </div>
              )}
              {!isMobile && (
                <div class="d-flex align-items-center justify-content-between flex-wrap mb-n2">
                  <h1 class="font-18 quicksand-regular text-charcoal mb-2 text-left">
                    Reports
                  </h1>
                </div>
              )}
            </div>
            <div className="row report-dashboard-filter">
              <div className="col-12 mb-4 pb-2">
                <div className="d-flex align-items-center flex-wrap mb-n3 justify-content-between">
                  <div className="d-flex align-items-center">
                    {isAdmin && this.renderProviders()}
                  </div>
                  <div className="d-flex align-items-center justify-content-end flex-grow-1">
                    {!isMobile && (
                      <Link
                        onClick={() => this.setState({ startDPOpen: true })}
                      >
                        <div className="mr-3 dateRange">Date Range</div>
                      </Link>
                    )}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        views={["year", "month", "date"]}
                        open={this.state.startDPOpen}
                        onOpen={() => this.setState({ startDPOpen: true })}
                        onClose={() => this.setState({ startDPOpen: false })}
                        value={this.state.startDate}
                        title="Start Date"
                        onChange={this._onStartDateChange}
                        okLabel={"Select End Date"}
                        onAccept={() => this.setState({ endDPOpen: true })}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        TextFieldComponent={() => null}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        views={["year", "month", "date"]}
                        open={this.state.endDPOpen}
                        onOpen={() => this.setState({ endDPOpen: true })}
                        onClose={() => this.setState({ endDPOpen: false })}
                        value={this.state.endDate}
                        title="End Date"
                        onChange={this._onEndDateChange}
                        dateFormat="MM/dd/yyyy"
                        onAccept={this._onEndDateAccept}
                        minDate={startDate}
                        maxDate={new Date()}
                        TextFieldComponent={() => null}
                      />
                    </MuiPickersUtilsProvider>

                    <div className="inner-tab-wrapper">
                      <Tabs
                        id="controlled-tab-example"
                        onSelect={this._onTabSelect}
                        activeKey={searchFilter.lastDays}
                        className="mb-0"
                      >
                        {startDate && endDate && (
                          <Tab
                            className={""}
                            eventKey={"custom"}
                            title={this._formateRangeDate()}
                          />
                        )}

                        <Tab className={""} eventKey={"0"} title="Today"></Tab>
                        <Tab
                          className={""}
                          eventKey={"7"}
                          title="Last 7 Days"
                        ></Tab>
                        <Tab
                          className={""}
                          eventKey={"30"}
                          title="Last 30 Days"
                        ></Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="report-dashboard-container container-fluid">
              <div className="row justify-content-between">
                <div className="col-sm-6">
                  <div className="report-lists">
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                      <div>
                        <h3 className="mb-0">Total Scheduled</h3>
                      </div>
                      {!isMobile && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn box-shadow-none text-uppercase"
                            onClick={() => this._goToReportDetails("all")}
                          >
                            View All
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h2 className="mb-0">
                        {reportSummary?.data?.totalScheduled}
                      </h2>
                      <h4 className="mb-0">appoinments</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="report-lists">
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                      <div>
                        <h3 className="mb-0">Successful</h3>
                      </div>
                      {!isMobile && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn box-shadow-none text-uppercase"
                            onClick={() => this._goToReportDetails("completed")}
                          >
                            View All
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h2 className="mb-0">
                        {reportSummary?.data?.successful}
                      </h2>
                      <h4 className="mb-0">sessions</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="report-lists">
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                      <div>
                        <h3 className="mb-0">Missed</h3>
                      </div>
                      {!isMobile && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn box-shadow-none text-uppercase"
                            onClick={() => this._goToReportDetails("missed")}
                          >
                            View All
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h2 className="mb-0">{reportSummary?.data?.missed}</h2>
                      <h4 className="mb-0">appointments</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="report-lists">
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-8">
                      <div>
                        <h3 className="mb-0">Failed</h3>
                      </div>
                      {!isMobile && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="btn box-shadow-none text-uppercase"
                            onClick={() => this._goToReportDetails("failed")}
                          >
                            View All
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h2 className="mb-0">{reportSummary?.data?.failed}</h2>
                      <h4 className="mb-0">sessions</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Reportdashboards;
