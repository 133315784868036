export const IS_LOADING = 'IS_LOADING';

export const COMMON_SET_USERINFO = 'COMMON_SET_USERINFO';

export const GET_ALL_PHYSICIANS = "GET_ALL_PHYSICIANS";
export const PHYSICIAN_LIST_SUCCESS = "PHYSICIAN_LIST_SUCCESS";
export const PHYSICIAN_LIST_ERROR = "PHYSICIAN_LIST_ERROR";


export const GET_PHYSICIANS_AVAILABILITY = "GET_PHYSICIANS_AVAILABILITY";
export const PHYSICIANSAVAILABILITY_LIST_SUCCESS = "PHYSICIANSAVAILABILITY_LIST_SUCCESS";
export const PHYSICIANSAVAILABILITY_LIST_ERROR = "PHYSICIANSAVAILABILITY_LIST_ERROR";

export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_ERROR = "APPOINTMENT_LIST_ERROR";
export const APPOINTMENT_SAVE_SUCCESS = "APPOINTMENT_SAVE_SUCCESS";
export const APPOINTMENT_SAVE_ERROR = "APPOINTMENT_SAVE_ERROR";
export const APPOINTMENT_CANCEL_SUCCESS = "APPOINTMENT_CANCEL_SUCCESS";
export const APPOINTMENT_CANCEL_ERROR = "APPOINTMENT_CANCEL_ERROR";
export const APPOINTMENT_STATUS_SUCCESS = "APPOINTMENT_STATUS_SUCCESS";
export const APPOINTMENT_STATUS_ERROR = "APPOINTMENT_STATUS_ERROR";


export const GET_PAST_APPOINTMENTS = "GET_PAST_APPOINTMENTS";
export const PASTAPPOINTMENT_LIST_SUCCESS = "PASTAPPOINTMENT_LIST_SUCCESS";
export const PASTAPPOINTMENT_LIST_ERROR = "PASTAPPOINTMENT_LIST_ERROR";


export const SET_SEARCHTXT_PATIENT = "SET_SEARCHTXT_PATIENT";


export const GET_PATIENTS = "GET_PATIENTS";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_ERROR = "PATIENT_LIST_ERROR";
export const PATIENT_ADD_SUCCESS = "PATIENT_ADD_SUCCESS";
export const PATIENT_ADD_ERROR = "PATIENT_ADD_ERROR";
export const PATIENT_UPDATE_SUCCESS = "PATIENT_UPDATE_SUCCESS";
export const PATIENT_UPDATE_ERROR = "PATIENT_UPDATE_ERROR";

export const TWILIO_TOKEN_GENERATE_SUCCESS = "TWILIO_TOKEN_GENERATE_SUCCESS";
export const TWILIO_TOKEN_GENERATE_ERROR = "TWILIO_TOKEN_GENERATE_ERROR";

export const FEEDBACK_APPOINTMENT_SUCCESS = "FEEDBACK_APPOINTMENT_SUCCESS";
export const FEEDBACK_APPOINTMENT_ERROR = "FEEDBACK_APPOINTMENT_ERROR";


export const CLEAR_DATA = 'CLEAR_DATA';


export const GET_USERS = "GET_USERS";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_ERROR = "USER_ADD_ERROR";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const GET_TEAMS = "GET_TEAMS";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_ERROR = "TEAM_LIST_ERROR";
export const TEAM_ADD_SUCCESS = "TEAM_ADD_SUCCESS";
export const TEAM_ADD_ERROR = "TEAM_ADD_ERROR";

export const USER_DEACTIVE_SUCCESS = "USER_DEACTIVE_SUCCESS";
export const USER_DEACTIVE_ERROR = "USER_DEACTIVE_ERROR";
export const USER_ACTIVE_SUCCESS = "USER_ACTIVE_SUCCESS";
export const USER_ACTIVE_ERROR = "USER_ACTIVE_ERROR";


export const REPORTSUMMARY_GET_SUCCESS = "REPORTSUMMARY_GET_SUCCESS";
export const REPORTSUMMARY_GET_ERROR = "REPORTSUMMARY_GET_ERROR";
export const REPORTLIST_GET_SUCCESS = "REPORTLIST_GET_SUCCESS";
export const REPORTLIST_GET_ERROR = "REPORTLIST_GET_ERROR";

export const APPLOCATION_INFO_SUCCESS = 'APPLOCATION_INFO_SUCCESS';
export const APPLOCATION_INFO_ERROR = 'APPLOCATION_INFO_ERROR';

export const APPOINTMENT_PARTICIPANTS_SUCCESS = 'APPOINTMENT_PARTICIPANTS_SUCCESS';
export const APPOINTMENT_PARTICIPANTS_ERROR = 'APPOINTMENT_PARTICIPANTS_ERROR';
