import * as actionTypes from './actionTypes';
import { alertService } from '../../data/services/alertService';

import { ConferenceService } from '../../data/services/conferenceService';
//import {AlertHelper} from '../../common/AlertHelper';
//import {logger} from '../../common/logger';

export const generateTwillioToken = (shortcode) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  postTwillioToken(dispatchEvent, shortcode);
};

function postTwillioToken(dispatchEvent, shortcode) {
  ConferenceService.postTwillioToken(shortcode)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });

      if (response?.status === 'success') {

        dispatchEvent({
          type: actionTypes.TWILIO_TOKEN_GENERATE_SUCCESS,
          payload: response.data,
        });
      } else if (response.status === 'error' && response.statusCode === 404) {

        dispatchEvent({
          type: actionTypes.TWILIO_TOKEN_GENERATE_ERROR,
          payload: response,
        });
      } else {
        throw response;
     }

    })
    .catch(error => {
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.TWILIO_TOKEN_GENERATE_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const joinAppointmentSession = (appointmentId) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  getTokenForAppointmentSession(dispatchEvent, appointmentId);
};

function getTokenForAppointmentSession(dispatchEvent, appointmentId) {

  ConferenceService.joinAppointmentSession(appointmentId)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      if (response?.status === 'success') {

        dispatchEvent({
          type: actionTypes.TWILIO_TOKEN_GENERATE_SUCCESS,
          payload: response.data,
        });
      } else {
         throw response;
      }
    })
    .catch(error => {
      console.log(error);
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.TWILIO_TOKEN_GENERATE_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const getApplicationInfo = (shortcode) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  getApplicationInfoByShortcode(dispatchEvent, shortcode);
};

function getApplicationInfoByShortcode(dispatchEvent, shortcode) {
  ConferenceService.getAppointmentInfo(shortcode)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });    
      if (response.status === 'success') {

        dispatchEvent({
          type: actionTypes.APPLOCATION_INFO_SUCCESS,
          payload: response.data,
        });
      } else {
        throw response;
      }

    })
    .catch(error => {

      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.APPLOCATION_INFO_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}