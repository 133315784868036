import { connect } from 'react-redux';
import Conference from '../../../Components/PageContainer/Conference/Conference';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    return {
        //physicians: state.physician.physicians,
        userInfo: state.common.userInfo,
        conference: state.conference,
        appliationInfo: state.common.appliationInfo,
        appointmentStatus: state.appointment.appointmentStatus,
        appointmentParticipants: state.appointment.appointmentParticipants,
    };
};

const mapDispatchToProps = dispatch => ({
    generateTwillioToken: (shortcode) => dispatch(actionCreators.generateTwillioToken(shortcode)),
    getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
    getApplicationInfo: (shortcode) => dispatch(actionCreators.getApplicationInfo(shortcode)),
    getAppointmentStatus: (appointmentId) => dispatch(actionCreators.appointmentStatus(appointmentId)),
    getAppointmentParticipants: (shortcode) => dispatch(actionCreators.appointmentParticipants(shortcode)),
    joinAppointmentSession: (appointmentId) => dispatch(actionCreators.joinAppointmentSession(appointmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conference);
