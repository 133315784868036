import { connect } from 'react-redux';
import Patientsearch from '../../../Components/PageContainer/Patient/Patientsearch';
import PatientModal from '../../../Components/PageContainer/Patient/PatientModal';

import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {    
    return {
        patients: state.patient.patients,
        physicians: state.physician.physicians,
        patientAdd: state.patient.patientAdd,
        patientUpdate:state.patient.patientUpdate,
    };
};

const mapDispatchToProps = dispatch => ({
    getPatients: (serchtxt, physicians) => dispatch(actionCreators.patientList(serchtxt, physicians)),
    postPatient: (patient, physicians) => dispatch(actionCreators.addPatient(patient, physicians)),
    //getPhysicianList: () => dispatch(actionCreators.physicianList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patientsearch, PatientModal);