import Http from "../../Services/Http";
// import data from "../../data.json";
import {
  APINAME_REPORTS,
  URL_REPORTDETAILS,
  URL_REPORTS,
} from "../../Containers/Common/APIUtils";

const api = new Http({});
var moment = require("moment");

//Get Report Summary API call
const getReportSummary = async (searchFilter) => {
  let apiName = APINAME_REPORTS;
  let path = URL_REPORTS;

  let providerId = searchFilter.providerId;
  let days = searchFilter.lastDays;

  const isCustomLastDays = searchFilter.lastDays === "custom";
  const isToday = searchFilter.lastDays === "0";

  let startDate = isToday
    ? moment().startOf("day").format()
    : isCustomLastDays
    ? moment(searchFilter.startDate).startOf("day").format()
    : moment().startOf("day").subtract(days, "d").format();

  let endDate = isToday
    ? moment().endOf("day").format()
    : isCustomLastDays
    ? moment(searchFilter.endDate).endOf("day").format()
    : moment().endOf("day").subtract(1, "d").format();

  let queryParams = {
    provider: providerId,
    start: startDate,
    end: endDate,
  };
  console.log(queryParams);
  // fetch report summary from api
  let response = await api.get(apiName, path, queryParams);
  // fetch user from static json file
  // let response = data.reportSummary[0];
  return response;
};

const getReportList = async (searchFilter, pageInfo = {}) => {
  let apiName = APINAME_REPORTS;
  let path = URL_REPORTDETAILS;
  //console.log(searchFilter);
  let providerId = searchFilter.providerId;
  let days = searchFilter.lastDays;
  let status = searchFilter.status;

  const isCustomLastDays = searchFilter.lastDays === "custom";
  const isToday = searchFilter.lastDays === "0";

  let startDate = isToday
    ? moment().startOf("day").format()
    : isCustomLastDays
    ? moment(searchFilter.startDate).startOf("day").format()
    : moment().startOf("day").subtract(days, "d").format();

  let endDate = isToday
    ? moment().endOf("day").format()
    : isCustomLastDays
    ? moment(searchFilter.endDate).endOf("day").format()
    : moment().endOf("day").subtract(1, "d").format();

  let queryParams = {
    provider: providerId,
    start: startDate,
    end: endDate,
    status: status,
    page: pageInfo.page,
    //start: pageInfo.start,
    count: pageInfo.count,
  };
  //console.log(queryParams);
  // fetch report summary from api

  let response = await api.get(apiName, path, queryParams);
  // fetch user from static json file
  // let response = data.reportList[0];
  return response;
};

export const ReportService = {
  getReportSummary,
  getReportList,
};
