import React, { Component } from 'react';
import CustomImg from '../../Global/CustomImg/CustomImg';
import Branding from "../../../Services/Branding";

class Overcapicity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoImageUrl: null
        };
    }

    async setBranding() {
        const branding = await Branding.getBranding(this.props.conference);
        if (this.state.logoImageUrl !== branding.logoImageUrl) {
            this.setState({ logoImageUrl: branding.logoImageUrl });
        }
    }

    async componentWillMount() {
        this.setBranding();
    }

    render() {
        return (
            <div className="thankyou-page-wrapper scrollbar">
                <div className="scroll-min-height d-flex flex-column align-items-center justify-content-center">
                    <div className="mb-4"><CustomImg src={this.state.logoImageUrl} className="img-fluid" alt="Logo" /></div>

                    <div className="text-center thankyou-page-text">
                        <p className="mb-0">The video session you are attempting to join is already at capacity. If you feel you have received this message in error, please contact your provider.

</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default Overcapicity;
