import React, { Component } from 'react';
import CustomImg from '../../Global/CustomImg/CustomImg';
class Endsession extends Component {
  render() {
    return (
      <div className="thankyou-page-wrapper scrollbar">
        <div className="scroll-min-height d-flex flex-column align-items-center justify-content-center">
          <div className="mb-4"><CustomImg src="sportsmed-logo.svg" className="img-fluid" alt="Logo" /></div>
          <div className="thankyou-page-icon bg-primary mb-3">
            <CustomImg src="icon-video-session.svg" alt="icon session" />
          </div>

          <div className="text-center thankyou-page-text">
            <h2>Sorry</h2>
            <p className="mb-0">A link has been Expired or Invalid.</p>
          </div>
        </div>
      </div>
    )
  }
}


export default Endsession;
