import * as actionTypes from './actionTypes';
import { alertService } from '../../data/services/alertService';
import { Patient } from '../../Models/index';
import { PatientService } from '../../data/services/index';
//import {AlertHelper} from '../../common/AlertHelper';
//import {logger} from '../../common/logger';



export const setSearchTxtPatient = (serchTxt) => dispatchEvent => {
  try {
    let response = serchTxt;
    dispatchEvent({ type: actionTypes.SET_SEARCHTXT_PATIENT, payload: response });
    return response;
  } catch (e) {

  }
}

export const patientList = (searchTxt) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_PATIENTS });
  getPatientList(dispatchEvent, searchTxt);
};

function getPatientList(dispatchEvent, searchTxt) {

  PatientService.getPatientList(searchTxt)
    .then(response => {

      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      let modifiedRes = [];

      if(response?.status === "error") {
        throw response;
      }
      response.data.forEach((val, index) => {
        let obj = {};
        obj[Patient.id] = val.id;
        obj[Patient.firstName] = val.firstName;
        obj[Patient.lastName] = val.lastName;
        obj[Patient.phone] = val.phone;
        obj[Patient.birthDate] = val.birthDate;
        obj[Patient.email] = val.email;
        obj[Patient.procedureDesc] = val.procedureDesc;
        obj[Patient.procedureDate] = val.procedureDate;
        obj[Patient.physicianId] = val.physicianId;
        obj[Patient.physician] = val.physicianId ? `${val.physicianLastName}, ${val.physicianFirstName}` : "";

        modifiedRes.push(obj);
      });

      dispatchEvent({
        type: actionTypes.PATIENT_LIST_SUCCESS,
        payload: modifiedRes,
      });

     })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      //
      dispatchEvent({
        type: actionTypes.PATIENT_LIST_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

export const addPatient = (patientInfo, physicians) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_PATIENTS });
  postPatient(dispatchEvent, patientInfo, physicians);
};

function postPatient(dispatchEvent, patientInfo, physicians) {
  PatientService.postPatient(patientInfo)
    .then(response => {

      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      if (response?.status === 'success') {

        let obj = {};
        obj[Patient.id] = response.id;
        obj[Patient.firstName] = response.firstName;
        obj[Patient.lastName] = response.lastName;
        obj[Patient.phone] = response.phone;
        obj[Patient.birthDate] = response.birthDate;
        obj[Patient.procedureDesc] = response.procedureDesc;
        obj[Patient.email] = response.email;
        obj[Patient.procedureDate] = response.procedureDate;
        obj[Patient.physicianId] = response.physicianId;
        let physican_name = physicians.filter((data, key) => { return data.id === response.physicianId }).map((data) => { return (data.lastName + ', ' + data.firstName) });

        obj[Patient.physician] = physican_name[0];
        dispatchEvent({
          type: actionTypes.PATIENT_ADD_SUCCESS,
          payload: { 'data': obj, 'status': response?.status },
        });
      } else if (response?.status === 'error') {

        dispatchEvent({
          type: actionTypes.PATIENT_ADD_ERROR,
          payload: response,
        });
        // alertService.error(response.message, { autoClose: true });
        // if (response.statusCode === '400') {
        //   alertService.error('A Patient with this info already exists. Please modify your entry, or search for this patient.');
        // }

        throw response;
      } 
    })
    .catch(error => {
      console.log(error);
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.PATIENT_ADD_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const updatePatient = (patientInfo, physicians) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_PATIENTS });
  putPatient(dispatchEvent, patientInfo, physicians);
};

function putPatient(dispatchEvent, patientInfo, physicians) {

  PatientService.putPatient(patientInfo)
    .then(response => {

      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });

      if (response?.status === 'success') {
        alertService.success('Patient info updated successfully.', { autoClose: true });
        let obj = {};
        obj[Patient.id] = response.id;
        obj[Patient.firstName] = response.firstName;
        obj[Patient.lastName] = response.lastName;
        obj[Patient.phone] = response.phone;
        obj[Patient.birthDate] = response.birthDate;
        obj[Patient.procedureDesc] = response.procedureDesc;
        obj[Patient.email] = response.email;
        obj[Patient.procedureDate] = response.procedureDate;
        obj[Patient.physicianId] = response.physician;
        obj[Patient.physician] = physicians.filter((data, key) => { return data.id === response.physician }).map((data) => { return data.name });

        dispatchEvent({
          type: actionTypes.PATIENT_UPDATE_SUCCESS,
          payload: { 'data': obj, 'status': response?.status },
        });
      } else if (response?.status === 'error') {

        dispatchEvent({
          type: actionTypes.PATIENT_UPDATE_ERROR,
          payload: response,
        });
        if (response?.statusCode === '400') {
          alertService.error('A Patient with this info already exists. Please modify your entry, or search for this patient.', { autoClose: true });
        }
         else {
          throw response;
        }
      } 
    })
    .catch(error => {
      // console.log("error ====>>>", error);
      // alertService.error("Something went wrong. Please try again.", {
      //   autoClose: true,
      // });

      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.PATIENT_UPDATE_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}
