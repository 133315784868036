import { useState, useEffect, useRef } from 'react';
import React from "react";
import { isMobile } from "mobile-device-detect";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';
import { Animated } from "react-animated-css";
import { TrunkPage } from 'twilio/lib/rest/trunking/v1/trunk';

const Videocontrols = ({
  handleAudioToggle,
  handleVideoToggle,
  audio,
  video,
  screenShare,
  handleScreenShareToggle,
  isMainPhysician,
  handleCameraFlip,
  cameraList,
  isFrontCamera,
  isScreenSharing = false,
  clickOnWin
}) => {

  const [openCameraDialog, setOpenCameraDialog] = React.useState(false);
  const [showVideoControll, setShowVideoControll] = React.useState(true);
  const toggalControll = () => {
    //alert(showVideoControll);
    if (showVideoControll) {
      setShowVideoControll(false);
    } else {
      setShowVideoControll(true);
    }
  }
  // const handleClickOutside = (event) => {
  //   alert(event.target);
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     setShowVideoControll(true);
  //   }
  // };
  useEffect(() => {
    
    setShowVideoControll(!clickOnWin);

  }, [clickOnWin]);

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // });

  return (
    <>
      {/* <Control>
        <Circle onClick={handleAudioToggle}>
          <Image src={audio ? Mic : MicOff} />
        </Circle>
         <Circle endCall onClick={handleCallDisconnect}>
          <Image src={End} />
        </Circle> 
        <Circle onClick={handleVideoToggle}>
          <Image src={video ? Video : VideoOff} />
        </Circle>
      </Control> */}
      <div className={!showVideoControll ? 'd-none' : ''}>
        <div className="video-icon-wrap" style={{ zIndex: 2 }}>
          <div className="video-icon" onClick={handleAudioToggle}>
            {audio ? (
               <Tooltip title="mic on" placement="top">
                <span className="material-icons"> mic </span>
               </Tooltip>
            ) : (
              <Tooltip title="mic off" placement="top">
                  <span className="material-icons"> mic_off </span>
               </Tooltip>
              )}
          </div>

          {!isScreenSharing && (
            <div className="video-icon" onClick={handleVideoToggle}>
              {video ? (
                <Tooltip title="video on" placement="top">
                    <span className="material-icons"> videocam </span>
                </Tooltip>
              ) : (
                <Tooltip title="video off" placement="top">
                  <span className="material-icons"> videocam_off </span>
                </Tooltip>
                )}
            </div>
          )}

          {isMainPhysician && !isMobile && (
            <div className="video-icon" onClick={handleScreenShareToggle}>
              {screenShare ? (
                <Tooltip title="screen share" placement="top">
                    <span className="material-icons"> screen_share </span>
                </Tooltip>
              ) : (

                <Tooltip title="cancel presentation" placement="top">
                <span className="material-icons"> cancel_presentation </span>
                </Tooltip>   
                )}
            </div>
          )}
          {cameraList && cameraList.length > 1 && (
            <>
              <div
                className="video-icon"
                onClick={() => {
                  if (cameraList.length === 2) {
                    handleCameraFlip(isFrontCamera ? cameraList[1].deviceId : cameraList[0].deviceId);
                  } else if (cameraList.length > 2) {
                    setOpenCameraDialog(true);
                  }
                }}>
                <span className="material-icons"> cameraswitch </span>
              </div>
              <div>
                {cameraList.length > 2 && (
                  <Select
                    style={{ opacity: 0, visibility: "hidden" }}
                    onChange={(e) => {
                      handleCameraFlip(e.target.value);
                      setOpenCameraDialog(false);
                    }}
                    open={openCameraDialog}
                    onOpen={() => setOpenCameraDialog(true)}
                    onClose={() => setOpenCameraDialog(false)}>
                    {cameraList ?.map(({ label, deviceId }) => (
                      <MenuItem key={deviceId} value={deviceId}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </>
          )}

        </div>
      </div>
    </>
  );
};

export default Videocontrols;
