import React from 'react';

class CustomImg extends React.Component {

    render() {
        let image_path = '';
        try {
            image_path = require('../../../Asset/images/' + this.props.src);
        } catch(err){
            image_path = require('../../../Asset/images/no_image.svg');
        }

        return (
            <img width={this.props.width} src={image_path} className={this.props.className} resizemode="contain" alt={this.props.alt} />
        );
    }
}

export default CustomImg;
