import React, { Component } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import ScheduleApptModal from "../../../Containers/PageContainer/Patient/ScheduleAppointment";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import {isMobile} from "mobile-device-detect";

class PastAppointmentList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showApptPopup: false,
            scheduleInfo: '',
            pastappointments: '',
            pageInfo: ''
        };
        this.setState({ pastappointments: this.props.pastappointments.data, pageInfo: this.props.pastappointments.page });
        //this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.filtekey !== this.props.filtekey) {
            this.setState({ pastappointments: '' });
        }
        if (prevProps.pastappointments !== this.props.pastappointments) {
            let appointments = [];

            // if (prevProps.pastappointments.data !== undefined) {
            //     prevProps.pastappointments.data.forEach((val, index) => {
            //         appointments.push(val);
            //     });
            // }
            if (this.state.pastappointments !== undefined && this.state.pastappointments.length > 0) {
                this.state.pastappointments.forEach((val, index) => {
                    appointments.push(val);
                });
            }
            if (this.props.pastappointments.data !== undefined) {
                this.props.pastappointments.data.forEach((val, index) => {
                    appointments.push(val);
                });
            }
            let pageinfo = '';
            if (this.props.pastappointments.meta !== undefined) {
                pageinfo = this.props.pastappointments.meta;
            }
            this.setState({ pastappointments: appointments, pageInfo: pageinfo });
        }

    }
    toggleApptPopup = (scheduleInfo) => {
        this.setState({
            showApptPopup: !this.state.showApptPopup,
            scheduleInfo: scheduleInfo
        });
    }

    renderPatientName(val, attendee_name) {
        if (val.patientName === ", ") {
            return (
                <span className="font-italic">PHI Removed</span>
            );
        }

        return (
            <span className="roboto-bold d-flex"><span>{val.patientName}</span>
                {attendee_name.length > 0 ?
                    <Tooltip title={attendee_name.map((val, index) => {
                        return (<span className="d-block">{val}</span>);
                    })} arrow placement="bottom-start">
                        <span className="attendee-popover-icon ml-3"> <span className="material-icons"> people </span> </span>
                    </Tooltip> : ''}
            </span>
        );
    }

    render() {

        return (
            <div className="tab-pane fade show active  bg-white" id="past" role="tabpanel" aria-labelledby="past-appointments" >

                <div className="table-responsive div-table">
                    {/* <!--Table--> */}
                    <table className={this.state.pastappointments.length > 0 ? "d-table w-100" : "noRecordsHideOverflow"}>
                        {/* <!--Table Head--> */}
                        <thead className="table-head text-dusty-gray">
                            <tr className="position-relative">
                                <td className="td-title-1 position-relative">
                                    <span className="d-inline-block text-capitalize">Date</span>
                                    {/* <a href="# " className={"filter-btn text-decoration-none d-inline-block position-absolute ml-2"}>
                                        <div><span className="icon-filter d-inline-block filter-down"></span></div>
                                        <div><span className="icon-filter filter-up d-inline-block"></span></div>
                                    </a> */}
                                </td>
                                <td className="td-title-2 text-capitalize">Patient</td>
                                <td className="td-title-3 text-capitalize">Procedure</td>
                                <td className="td-title-4 text-capitalize">Procedure Date</td>
                                <td className="td-title-5  text-capitalize">{this.props.isClinicanUser === true ? 'Physician' : 'Provider'}</td> <td className="td-title-6 text-capitalize">Phone</td>
                                <td className="td-title-7"></td>
                                <td className="td-title-8"></td>
                            </tr>
                        </thead>
                        {/* <!--Table Body--> */}
                        <tbody className="table-body">
                            {this.state.pastappointments && this.state.pastappointments.length > 0 ? this.state.pastappointments.map((val, index) => {
                                let apptStatusClass = classNames({
                                    'icon-sucess': (val.status === "COMPLETED" || val.roomStatus === 'COMPLETE'),
                                    'icon-error': (val.roomStatus === 'MISSED' || val.roomStatus === 'CANCELLED'),
                                    'icon-user-times': val.roomStatus === 'MISSED_CLINICIAN',
                                    'icon-exclamation': val.roomStatus === 'FAILED',
                                });
                                let attendee_name = [];
                                if (val.attendee && val.attendee.length > 0) {
                                    val.attendee.forEach((val, index) => {
                                        if(val.attendee_name!=''){
                                            attendee_name.push(val.attendee_name);
                                        }
                                    })
                                }
                                return (<tr key={index} className="lh-16">
                                    <td>
                                        <div className="time-date-box text-center text-dusty-gray py-2">
                                            <span className="d-block font-21">
                                                <Moment className="" format="MM/DD/YY">
                                                    {val.startDate}
                                                </Moment>
                                            </span>
                                            <Moment format="hh:mm A">
                                                {val.startDate}
                                            </Moment>
                                        </div>
                                    </td>
                                    <td>
                                        {this.renderPatientName(val, attendee_name)}
                                        <span className="d-block"><span className="d-inline-block text-dusty-gray">DOB&nbsp;</span>
                                            {val.patientDOB ?
                                                <Moment className="" format="MM/DD/YYYY">
                                                    {val.patientDOB}
                                                </Moment>
                                                : null}
                                        </span>
                                    </td>
                                    <td>
                                        {val.procedureDesc && val.procedureDesc !== 'NA' ? val.procedureDesc : 'n/a'}
                                    </td>
                                    <td>
                                        {val.procedureDate &&
                                        val.procedureDate !== "NA" &&
                                        moment(val.procedureDate).isValid() ? (
                                            <Moment className="" format="MM/DD/YYYY">
                                                {val.procedureDate}
                                            </Moment>
                                        ) : (
                                            "n/a"
                                        )}
                                    </td>
                                    <td>{this.props.isClinicanUser === true ? val.physician : val.resource}</td>
                                    <td><a href={'tel:'+val.patientPhone}><NumberFormat format="(###) ###-####" displayType={'text'} mask="_" value={val.patientPhone} /></a></td>
                                    <td className="text-center">
                                        {/* <a href={void (0)} onClick={() => this.toggleApptPopup(val)} className={"edit-icon-btn p-3 text-decoration-none d-flex align-items-center justify-content-center"}>
                                            <span className="icon-edit"></span>
                                        </a> */}
                                        <IconButton href={void (0)} onClick={() => this.toggleApptPopup(val)} className={"edit-icon-btn p-3 text-decoration-none d-flex align-items-center justify-content-center"}>
                                            <span className="icon-edit"></span>
                                        </IconButton>
                                    </td>
                                    <td className=" text-center">
                                        <span className={"font-21 " + apptStatusClass}></span>
                                    </td>
                                </tr>)
                            })

                                :
                                <tr className="d-flex w-100">
                                {/* <tr><td colSpan="8" className="text-center">No Record Found</td></tr>*/}
                                <td colSpan="8" className="d-flex w-100 justify-content-center ">
                                  No Record Found
                              </td>
                              </tr>
                            }
                        </tbody>
                    </table>
                    {this.state.pastappointments.length >= 10 && 
                    
                    <div className="pt-2 text-center">
                        <Button disabled={this.state.pageInfo.current_page >= this.state.pageInfo.last_page} onClick={() => this.props.loadMorePastApp(this.state.pageInfo.current_page + 1)} className="btn btn-primary btn-small btn-shadow text-uppercase text-white">Load more</Button>
                    </div>
                    }
                </div>
                {this.state.showApptPopup ?
                    <ScheduleApptModal scheduleInfo={this.state.scheduleInfo} closePopup={this.toggleApptPopup.bind(this)} showPopup={this.state.showApptPopup}></ScheduleApptModal>
                    : null}
            </div >
        );
    }
}

export default PastAppointmentList;
