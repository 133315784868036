import React, { Component } from 'react';
import Moment from 'react-moment';
//import PatientModal from '../Patient/PatientModal';
import NumberFormat from 'react-number-format';
import PatientModal from '../../../Containers/PageContainer/Patient/PatientModel';
import ScheduleApptModal from '../../../Containers/PageContainer/Patient/ScheduleAppointment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

class Patientsearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            appointmentDate: new Date(),
            appointmentTime: '',
            show: false,
            showPopup: false,
            showApptPopup: false,
            patientInfo: ''
        };
        //this.handleSubmit = this.handleSubmit.bind(this);
        //console.log(this.props.searchTxt);
    }
    async componentDidMount() {
        //await this.props.getPatients(this.props.searchTxt);
        this.fetchPatients();
        //await this.props.getPhysicianList();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.searchTxt !== this.props.searchTxt) {
            this.fetchPatients();
        }

        if (prevProps.patientAdd !== this.props.patientAdd) {
            if (this.props.patientAdd) {
                if (this.props.patientAdd.status === 'success') {
                    this.fetchPatients();
                }
            }
        }
    }
    async fetchPatients() {
        await this.props.getPatients(this.props.searchTxt, this.props.physicians);
    }
    showScheduleAppt = () => {
        this.setState({ show: true });
    }
    closeSchAppt = () => {
        this.setState({ show: false })
    }
    appointmentDateChange = (date) => {
        this.setState({
            appointmentDate: date
        });
    }
    appointmentTimeChange = (time) => {
        this.setState({
            appointmentTime: time
        });
    }
    togglePatientPopup(patientInfo) {
        this.fetchPatients();
        this.setState({
            showPopup: !this.state.showPopup,
            patientInfo: patientInfo
        });
    }
    toggleApptPopup = (patientInfo) => {
        this.setState({
            showApptPopup: !this.state.showApptPopup,
            patientInfo: patientInfo
        });
    }

    render() {
        return (
            <div className="h-100">
                <h2 className="font-18 quicksand-regular text-charcoal pb-2">Search Results</h2>
                <div className="content-tab-panel">
                    <div className="tab-content bg-white">
                        <div className="table-responsive div-table">
                            <table className={this.props.patients.length > 0 ? "d-table w-100" : "noRecordsHideOverflow"}>
                                <thead className="table-head text-dusty-gray">
                                    <tr className="position-relative">
                                        <td className="td-title-2">Patient</td>
                                        <td className="td-title-3">Procedure</td>
                                        <td className="td-title-4">Procedure Date</td>
                                        <td className="td-title-5">Physician</td>
                                        <td className="td-title-6">Phone</td>
                                        <td className="td-title-7"></td>
                                        <td className="td-title-8"></td>
                                    </tr>
                                </thead>

                                <tbody className="table-body">
                                    {this.props.patients && this.props.patients.length > 0 ? this.props.patients.map((val, index) => {
                                        return (

                                            <tr className="lh-16" key={index}>
                                                <td>
                                                    <span className="roboto-bold">{val.lastName}, {val.firstName}</span>
                                                    <span className="d-block"><span className="d-inline-block text-dusty-gray">DOB&nbsp;</span>
                                                        <Moment className="" format="MM/DD/YYYY">
                                                            {val.birthDate}
                                                        </Moment>
                                                    </span>
                                                </td>
                                                <td>
                                                    {
                                                        val.procedureDesc && val.procedureDesc !== 'NA'
                                                            ?
                                                            val.procedureDesc
                                                            : 'n/a'
                                                    }
                                                </td>
                                                <td>
                                                    {val.procedureDate && val.procedureDate !== 'NA'
                                                        ?
                                                        <Moment className="" format="MM/DD/YYYY">
                                                            {val.procedureDate}
                                                        </Moment> : 'n/a'}
                                                </td>
                                                <td>{val.physician}</td>
                                                <td>
                                                    <a href={'tel:'+val.phone}>
                                                        <NumberFormat format="(###) ###-####" displayType={'text'} mask="_" value={val.phone} />
                                                    </a>
                                                </td>
                                                <td className=" text-center">
                                                    {/* <a href="javascript:void(0);" onClick={() => this.togglePatientPopup(val)} className="edit-icon-btn p-3 text-decoration-none d-flex align-items-center justify-content-center">
                                                        <span className="icon-edit"></span>
                                                    </a> */}
                                                    <IconButton onClick={() => this.togglePatientPopup(val)} className="edit-icon-btn p-3 text-decoration-none d-flex align-items-center justify-content-center">
                                                        <span className="icon-edit"></span>
                                                    </IconButton>
                                                </td>
                                                <td className="text-center">
                                                    {/* <button className="btn btn-primary session-start-btn text-white btn-small text-uppercase" onClick={() => this.toggleApptPopup(val)}>Schedule Appt</button> */}
                                                    <Button className="btn btn-primary session-start-btn text-white btn-small text-uppercase" onClick={() => this.toggleApptPopup(val)}>
                                                        Schedule Appt
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                        :
                                      // <tr><td colSpan="7" className="text-center">No Patients Found</td></tr>
                                        <tr className="d-flex w-100">
                                        <td colSpan="7" className="no-records-td d-flex w-100 justify-content-center ">
                                          No Record Found
                                      </td>
                                      </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {this.state.showApptPopup ?
                    <ScheduleApptModal patientInfo={this.state.patientInfo} closePopup={this.toggleApptPopup.bind(this)} showPopup={this.state.showApptPopup}></ScheduleApptModal>
                    : null}
                {this.state.showPopup ?
                    <PatientModal patientInfo={this.state.patientInfo} closePopup={this.togglePatientPopup.bind(this)} showPopup={this.state.showPopup} physicians={this.props.physicians}></PatientModal>
                    : null
                }
            </div>
        );
    }
}

export default Patientsearch;
