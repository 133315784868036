import React, {Component} from 'react';
import "./User.css";
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import UserModel from '../../../Containers/PageContainer/User/UserModel';
import {DebounceInput} from 'react-debounce-input';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CustomImg from '../../Global/CustomImg/CustomImg';
import Storage from '../../../Providers/Storage';
import {UserService, alertService} from "../../../data/services";
import PatientSearchbox from '../../../Containers/PageContainer/Layout/PatientSearchbox';

class Users extends Component {

    useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                width: '100%',
                maxWidth: 360,
                backgroundColor: theme.palette.background.paper,
            },
            paper: {
                width: '80%',
                maxHeight: 435,
            },
        }),
    );
    classes = this.useStyles;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            contextMenuOpen: false,
            filterMenuOpen: false,
            anchorEl: null,
            value: '',
            showPopup: false,
            userInfo: '',
            teamList: [],
            roleList: [],
            filterBy: {
                searchrole: '',
                searchteam: '',
                searchuser: '',
                searchinactive: ''
            },
            sortOn: 'name',
            sortBy: 'asc',
            userInfoPopup: '',
            loginUserPractice: Storage.get("userpractice")
        };
        this.setAnchorEl = this.setAnchorEl.bind(this);

    }

    async componentWillMount() {
        //await this.props.getCurrentUserInfo();
        this.setState({
            roleList: [
                {
                    id: 1,
                    name: "Provider",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Staff",
                    checked: false,
                }
            ]
        });
    }

    async componentDidMount() {
        this.fetchUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchuser !== this.props.searchuser) {
            this.fetchUsers();
        }

        if (prevProps.userAdd !== this.props.userAdd) {
            if (this.props.userAdd) {
                if (this.props.userAdd.status === 'success') {
                    this.fetchUsers();
                }
            }
        }
        if (prevProps.userUpdate !== this.props.userUpdate) {
            if (this.props.userUpdate) {
                if (this.props.userUpdate.status === 'success') {
                    //this.fetchUsers();
                }
            }
        }

        if (prevProps.teams !== this.props.teams) {
            if (this.props.teams) {
                this.setState({'teamList': this.props.teams});
            }
        }

        if (prevProps.userDeactivate !== this.props.userDeactivate ||
            prevProps.userActivate !== this.props.userActivate) {
            this.handleClose();
            this.fetchUsers();
        }

        if (prevProps.userInfo !== this.props.userInfo){
            if (this.props.userInfo) {
                this.setState({'userInfo': this.props.userInfo});
            }
        }

    }

    async fetchUsers(sortOn = '', sortBy = '') {
        let filterBy = this.state.filterBy;

        if (sortOn === '') {
            sortOn = this.state.sortOn;
        }

        if (sortBy === '') {
            sortBy = this.state.sortBy;
        } else {
            this.setState({sortBy: sortBy, sortOn: sortOn});
        }

        await this.props.getUsers(filterBy, sortOn, sortBy, this.state.loginUserPractice);
        await this.props.getTeams();
    }

    handleResetUserPassword = async (userInfo) => {
        this.closeMenu();

        const newPassword = window.confirm(`Are you sure you want to reset ${userInfo.firstName} ${userInfo.lastName}'s password?`);
        if (newPassword) {
            userInfo.temporaryPassword = true;
            await UserService.putUser(userInfo);
            window.alert("Password reset successfully.\nTemporary password sent to registered email.");
        }
    };

    handleDeactivateAccount = (userInfo,patientCount) => {
        if(patientCount!==0){
            alertService.error("There are patients associated with this physician. Change the physician for those patients first.",{ autoClose: true });
            return;
        }
        this.setState({open: true, value: userInfo.id});
        this.closeMenu();
    };

    handleActivateAccount = (userInfo) => {
        this.closeMenu();
        this.props.activateAccount(userInfo.id);
    };

    handleClose = () => {
        this.setState({open: false});
    };

    deactivateAccount = (userId) => {
        const isLoggedInUser = this.props.userInfo.id === userId;
        this.props.deactivateAccount(userId, isLoggedInUser);
    }

    toggleUserPopup(userInfo) {
        this.setState({
            showPopup: !this.state.showPopup,
            userInfo: userInfo
        });
        this.closeMenu();
    }

    closeUserPopup() {
        this.fetchUsers();
        this.setState({
            showPopup: !this.state.showPopup,
        });

    }

    showMenu = (event) => {
        this.setAnchorEl(event.currentTarget);
    };

    showListMenu = (event, val) => {
        this.setState({
            anchorEl: event.currentTarget,
            contextMenuOpen: !this.state.contextMenuOpen,
            userInfoPopup: val
        })
    }

    setAnchorEl(value) {
        if (value.id === "filterMenuBtn") {
            this.setState({
                anchorEl: value,
                filterMenuOpen: !this.state.filterMenuOpen
            })
        }
        // else if (value.id === "contextMenuBtn") {
        //     this.setState({
        //         anchorEl: value,
        //         contextMenuOpen: !this.state.contextMenuOpen
        //     })
        // }
    }

    closeMenu = () => {
        this.setState({
            contextMenuOpen: false,
            filterMenuOpen: false,
        })
    };

    handleSearchInput(e) {
        let filterBy = this.state.filterBy;
        if (!filterBy[e.target.name]) {
            filterBy[e.target.name] = [];
        }
        if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                filterBy[e.target.name].push(e.target.value);
            } else {
                let index = filterBy[e.target.name].indexOf(e.target.value);
                filterBy[e.target.name].splice(index, 1);
            }
        } else {
            filterBy[e.target.name] = e.target.value;
        }
        this.setState({filterBy: filterBy});
        this.fetchUsers();
    }

    //clear filter
    clearFilter() {
        let filterBy = this.state.filterBy;
        filterBy.searchrole = '';
        filterBy.searchteam = '';
        filterBy.searchuser = '';
        filterBy.searchinactive = '';
        this.setState({filterBy: filterBy});
        this.fetchUsers();
    }

    //return to dashboard
    backToDashboard() {
        this.props.history.push({
            pathname: "/",
        });
    }

    // change sort order on column
    changeSortOrder(sortOn, sortBy) {
        //let sortBy = this.state.sortBy;
        //let sortOn = sortOn;
        if (sortBy === 'asc') {
            sortBy = 'desc';
        } else {
            sortBy = 'asc';
        }
        //this.setState({ 'sortBy': sortBy, 'sortOn': sortOn });

        this.fetchUsers(sortOn, sortBy);
    }

    clearSearchInput = () => {
        let filterBy = this.state.filterBy;
        filterBy['searchuser'] = '';
        this.setState({filterBy: filterBy});
        this.fetchUsers();
    }

    onSearchCallback = (serachTxt) => {
        let filterBy = this.state.filterBy;
        if (!filterBy['searchuser']) {
            filterBy['searchuser'] = [];
        }
        filterBy['searchuser'] = serachTxt;
        this.setState({filterBy: filterBy});
        this.fetchUsers();
    }

    render() {
        return (
            <main className="main-container user-container bg-white">
                <div className="content-area scrollbar h-100">
                    <div className="container-fluid container-padding">
                        <div className="mb-4">
                            <div className="clearfix mb-2">
                                <Typography>
                                    <Link className="back-dashboard-btn d-inline-flex align-items-center text-decoration-none" href="javascript:void(0)" onClick={this.backToDashboard.bind(this)}>
                                        <span className="material-icons mr-2"> keyboard_backspace </span>
                                        Back to Dashboard
                                    </Link>
                                </Typography>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap mb-n2">
                                <h2 className="font-18 quicksand-regular text-charcoal mb-2 text-left">Manage Users</h2>
                                <Button className="btn btn-primary text-uppercase text-white mb-2 px-4 " onClick={() => this.toggleUserPopup('')}>Add User</Button>
                            </div>
                        </div>
                        <div className="mb-4 pb-2">
                            <div className="d-flex align-items-center flex-wrap mb-n3">
                                <div className="search-bar mx-0 mr-4 pr-2 mb-3">
                                    <div className="form-group mb-0">
                                        {/* <DebounceInput type="text"
                                                       debounceTimeout={250}
                                                       placeholder="Search Users"
                                                       className="searchInput form-control bg-white-sand border-0 search-input"
                                                       onChange={this.handleSearchInput.bind(this)} value={this.state.filterBy.searchuser} name='searchuser'
                                        /> */}
                                        <PatientSearchbox 
                                        id="searchuser" 
                                        debounceRate={250}
                                        onSearchCallback={this.onSearchCallback}
                                        placeholder="Search Users"
                                        />
                                        {/* {this.state.filterBy.searchuser.length > 0 ?
                                            <Button onClick={this.clearSearchInput} className="clearBtn btn-clear btn p-0">
                                                <CustomImg src="icon_clear.svg" alt="Clear Icon"/>
                                            </Button>
                                            : ''} */}
                                    </div>
                                </div>

                                <div className="filter-box mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="mr-4">
                                            <Button id="filterMenuBtn" variant="outlined" className="btn btn-border-primary filter-outline-btn pl-2 pr-0" aria-controls="filterMenu" aria-haspopup="true" onClick={this.showMenu}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor">
                                                    <path
                                                        d="M15.5 12H5v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v.5H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H3v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V14h10.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0-5H13v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V7H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H11v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V9h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0-5H9v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V2H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H7v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V4h6.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                                                </svg>
                                                filters
                                                <span className="material-icons text-primary"> arrow_drop_down </span>
                                            </Button>
                                            <Menu id="filterMenu" anchorEl={this.state.anchorEl}
                                                  open={this.state.filterMenuOpen} onClose={this.closeMenu}
                                                  getContentAnchorEl={null}
                                                  anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                                                  transformOrigin={{vertical: "top", horizontal: "left"}}>

                                                <div className="filter-dropdown-menu">
                                                    <span className="title d-block font-18 quicksand-bold mb-4">Filter By</span>
                                                    <div className="d-flex align-content-start flex-wrap">
                                                        <div className="filter-team">
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend" className="checkbox-formlabel">Team</FormLabel>
                                                                <FormGroup>
                                                                    {this.state.teamList && this.state.teamList.map((team) =>

                                                                        <FormControlLabel key={team.id}
                                                                                          className="form-checkbox"
                                                                                          control={<Checkbox onChange={this.handleSearchInput.bind(this)} name='searchteam' color="primary"/>}
                                                                                          label={team.name} value={team.name} checked={this.state.filterBy.searchteam.indexOf(team.name) !== -1}
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                            </FormControl>
                                                        </div>
                                                        <div className="filter-role">

                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend" className="checkbox-formlabel">Role</FormLabel>
                                                                <FormGroup>
                                                                    {this.state.roleList.map((role) =>
                                                                        <FormControlLabel key={role.id}
                                                                                          className="form-checkbox"
                                                                                          control={<Checkbox onChange={this.handleSearchInput.bind(this)} name='searchrole' color="primary"/>} value={role.name}
                                                                                          label={role.name} checked={this.state.filterBy.searchrole.indexOf(role.name) !== -1}
                                                                        />
                                                                    )}
                                                                    <FormControlLabel
                                                                        className="form-checkbox"
                                                                        control={<Checkbox onChange={this.handleSearchInput.bind(this)} name='searchrole' color="primary"/>}
                                                                        label='Admin' value='Admin' checked={this.state.filterBy.searchrole.indexOf('Admin') !== -1}
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>

                                                            <FormControl component="fieldset" className="mt-3">
                                                                <FormLabel component="legend" className="checkbox-formlabel">Active</FormLabel>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        className="form-checkbox"
                                                                        control={<Checkbox onChange={this.handleSearchInput.bind(this)} name='searchinactive' color="primary"/>}
                                                                        label='Include Inactive' value='Inactive' checked={this.state.filterBy.searchinactive.indexOf('Inactive') !== -1}
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Menu>
                                        </div>

                                        <div className="">
                                            {this.state.filterBy.searchrole !== '' || this.state.filterBy.searchteam !== '' || this.state.filterBy.searchuser !== '' || this.state.filterBy.searchinactive !== '' ?
                                                <div className="">
                                                    <Typography>
                                                        <Link className="text-primary font-14" href="javascript:void(0)" onClick={this.clearFilter.bind(this)}>
                                                            Clear all filters
                                                        </Link>
                                                    </Typography>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-tab-panel user-content-tab-panel">
                            <div className="tab-content bg-white p-0 border-0">
                                <div className="table-responsive div-table">
                                    <table className="d-table w-100">
                                        <thead className="table-head text-363636">
                                        <tr className="position-relative">
                                            <td className="td-title-2 position-relative">
                                                <div className="d-flex align-items-center gap-4">
                                                    <a href="javascript:void(0)" className="filter-btn text-decoration-none d-inline-block" onClick={() => this.changeSortOrder('name', this.state.sortBy)}>
                                                        <DisplayArrow sortOn={this.state.sortOn} sortColumn='name' sortBy={this.state.sortBy}></DisplayArrow>
                                                    </a>
                                                    <span className="d-inline-block">Name</span>
                                                </div>
                                            </td>
                                            <td className="td-title-3 position-relative">
                                                <div className="d-flex align-items-center gap-4">
                                                    <a href="javascript:void(0)" className="filter-btn text-decoration-none d-inline-block" onClick={() => this.changeSortOrder('email', this.state.sortBy)}>
                                                        <DisplayArrow sortOn={this.state.sortOn} sortColumn='email' sortBy={this.state.sortBy}></DisplayArrow>
                                                    </a>
                                                    <span className="d-inline-block">Email </span>
                                                </div>
                                            </td>
                                            <td className="td-title-4 position-relative">
                                                <div className="d-flex align-items-center gap-4">
                                                    <a href="javascript:void(0)" className="filter-btn text-decoration-none d-inline-block" onClick={() => this.changeSortOrder('team', this.state.sortBy)}>
                                                        <DisplayArrow sortOn={this.state.sortOn} sortColumn='team' sortBy={this.state.sortBy}></DisplayArrow>
                                                    </a>
                                                    <span className="d-inline-block">Team</span>
                                                </div>
                                            </td>
                                            <td className="td-title-5 position-relative">
                                                <div className="d-flex align-items-center gap-4">
                                                    <a href="javascript:void(0)" className="filter-btn text-decoration-none d-inline-block" onClick={() => this.changeSortOrder('role', this.state.sortBy)}>
                                                        <DisplayArrow sortOn={this.state.sortOn} sortColumn='role' sortBy={this.state.sortBy}></DisplayArrow>
                                                    </a>
                                                    <span className="d-inline-block">Role</span>
                                                </div>
                                            </td>
                                            <td className="td-80"></td>
                                        </tr>
                                        </thead>

                                        <tbody className="table-body">
                                        
                                        {this.props.users && this.props.users.length > 0 ? this.props.users.map((val, index) => {
                                                return (
                                                    <tr className={`lh-16 ${val.enabled ? '' : 'user-disabled'}`} key={index}>
                                                        <td className="roboto-medium">
                                                            <div className="d-flex align-items-center flex-wrap flex-gap-4">
                                                                <span className="d-inline-block mr-4">{val.name}</span>
                                                                {val.isAdmin ? <span className="tag-class">Admin</span> : ''}
                                                            </div>
                                                        </td>
                                                        <td>{val.email}</td>
                                                        <td>
                                                            <div className="d-flex flex-wrap flex-gap-4">
                                                                {val.teams && val.teams
                                                                    .map((t, index) =>
                                                                        <React.Fragment key={index}>
                                                                            <span className="tag-class"> {t}</span>
                                                                        </React.Fragment>
                                                                    )}
                                                            </div>
                                                        </td>
                                                        <td>{val.role}</td>
                                                        <td className="text-center">
                                                            <Button id="contextMenuBtn" aria-controls="contextMenu" aria-haspopup="true" onClick={(e) => this.showListMenu(e, val)}>
                                                                <span className="material-icons"> more_horiz </span>
                                                            </Button>
                                                            <Menu key={index} id="contextMenu" className="context-menu" anchorEl={this.state.anchorEl}
                                                                  open={this.state.contextMenuOpen && this.state.userInfoPopup === val} onClose={this.closeMenu}
                                                                  getContentAnchorEl={null}

                                                                  anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                                                                  transformOrigin={{vertical: "top", horizontal: "center"}}>

                                                                <MenuItem onClick={() => this.toggleUserPopup(this.state.userInfoPopup)}>Manage Account</MenuItem>
                                                                <MenuItem disabled={!val.enabled} onClick={() => this.handleResetUserPassword(this.state.userInfoPopup)}>Reset Password</MenuItem>

                                                                {val.enabled ?
                                                                    <MenuItem onClick={() => this.handleDeactivateAccount(this.state.userInfoPopup,val.patientCount)} className="text-red">Deactivate Account</MenuItem> :
                                                                    <MenuItem onClick={() => this.handleActivateAccount(this.state.userInfoPopup)} className="text-red">Activate Account</MenuItem>
                                                                }
                                                            </Menu>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="5" className="text-center">No Results Found</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ConfirmationDialogRaw
                        classes={{
                            paper: this.classes.paper,
                        }}
                        id="ringtone-menu"
                        keepMounted
                        open={this.state.open}
                        onClose={this.handleClose}
                        userId={this.state.value}
                        deactivateAccount={this.deactivateAccount.bind(this.state.value)}
                    />
                </div>
                {this.state.showPopup ?
                    <UserModel userInfo={this.state.userInfo} teamList={this.state.teamList} closePopup={this.closeUserPopup.bind(this)} showPopup={this.state.showPopup} userPractice={this.state.loginUserPractice}></UserModel>
                    : null
                }
            </main>
        );
    }
}

class ConfirmationDialogRaw extends Component {

    handleCancel = () => {
        this.props.onClose();
    };

    handleOk = () => {
        //alert(this.props.userId)
        this.props.onClose();
        this.props.deactivateAccount(this.props.userId);
    };

    handleChange = (event) => {
        this.setState(event.target.value);
    };

    render() {
        return (
            <div>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    aria-labelledby="confirmation-dialog-title"
                    className="modal-656 modal-dialog-design"
                    open={this.props.open}
                >
                    <DialogTitle id="confirmation-dialog-title" className="modal-header"> Deactivate Account </DialogTitle>
                    <DialogContent dividers className="modal-body">
                        Are you sure you want to deactivate this account?
                    </DialogContent>
                    <DialogActions className="modal-body">
                        <div className="dialog-footer">
                            <Button variant="outlined" autoFocus onClick={this.handleCancel} className="btn btn-border-primary box-shadow-none text-uppercase mr-3">
                                Cancel
                            </Button>
                            <Button onClick={this.handleOk} className="btn btn-red text-uppercase px-4 ml-0">
                                Deactivate
                            </Button>
                        </div>
                    </ DialogActions>
                </ Dialog>
            </div>
        );
    }

}

class DisplayArrow extends Component {

    render() {
        if (this.props.sortBy === 'desc' && this.props.sortColumn === this.props.sortOn) {
            return (
                <div>
                    <div><span className="icon-filter d-inline-block filter-down"></span></div>
                </div>
            );
        } else if (this.props.sortBy === 'asc' && this.props.sortColumn === this.props.sortOn) {
            return (
                <div>
                    <div><span className="icon-filter filter-up d-inline-block"></span></div>
                </div>
            );
        } else {
            return (
                <div>
                    <div><span className="icon-filter d-inline-block filter-down"></span></div>
                    <div><span className="icon-filter filter-up d-inline-block"></span></div>
                </div>
            );
        }
    }

}

export default Users;
