import "date-fns";
import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { alertService } from "../../../data/services/alertService";

class PatientModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.state = {
      procedureDate: null,
      show: true,
      fields: {},
      errors: {},
    };
    // set fields state for edit patient popup

    //this.handleChange = this.handleChange.bind(this);
    //this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {
    if (this.props.patientInfo) {
      let fields = this.state.fields;
      fields["id"] = this.props.patientInfo.id;
      fields["firstName"] = this.props.patientInfo.firstName;
      fields["lastName"] = this.props.patientInfo.lastName;
      fields["phone"] = this.props.patientInfo.phone;
      fields["birthDate"] = moment(this.props.patientInfo.birthDate).toDate();
      fields["email"] = this.props.patientInfo.email;
      fields["procedureDesc"] = this.props.patientInfo.procedureDesc;
      fields["procedureDate"] = null;
      if (
        this.props.patientInfo.procedureDate !== "" &&
        this.props.patientInfo.procedureDate !== "NA"
      ) {
        fields["procedureDate"] = this.props.patientInfo.procedureDate;
      }
      if (
        this.props.patientInfo.birthDate !== "" &&
        this.props.patientInfo.birthDate !== "NA"
      ) {
        fields["birthDate"] = new Date(this.props.patientInfo.birthDate);
      }
      fields["physician"] = this.props.patientInfo.physicianId;
      this.setState({
        fields,
      });
    } else {
      let physician = "";
      if (this.props.physicians) {
        if (this.props.physicians.length === 1) {
          physician = this.props.physicians[0].id;
        }
      }
      this.setState({
        fields: {
          id: "",
          firstName: "",
          lastName: "",
          phone: "",
          birthDate: null,
          email: "",
          procedureDesc: "",
          procedureDate: null,
          physician: physician,
        },
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.patientAdd !== this.props.patientAdd) {
      if (this.props.patientAdd) {
        if (this.props.patientAdd.status === "success") {
          this.props.closePopup();
        } else if (
          this.props.patientAdd.status === "error" &&
          this.props.patientAdd.statusCode === 400
        ) {
          //alertService.error(
          //  "A Patient with this info already exists. Please modify your entry, or search for this patient."
          //);
        }
      }
    }
    if (prevProps.patientUpdate !== this.props.patientUpdate) {
      if (this.props.patientUpdate) {
        if (this.props.patientUpdate.status === "success") {
          this.props.closePopup();
        } else if (
          this.props.patientUpdate.status === "error" &&
          this.props.patientUpdate.statusCode === 400
        ) {
          alertService.error(
            "A Patient with this info already exists. Please modify your entry, or search for this patient.", { autoClose: true }
          );
        }
      }
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    if (!String(e.target.value).startsWith(" ")) {
      const val = String(e.target.value).replace(/  +/g, " ");
      fields[e.target.name] = val;
      // fields[e.target.name] = e.target.value;
      if (e.target.name === "procedureDesc") {
        if (e.target.value.length < 255) {
          this.setState({
            fields,
          });
        }
      } else {
        this.setState({
          fields,
        });
      }
    } else {
      const val = e.target.value.replace(/ +/g, "");
      fields[e.target.name] = val;
      this.setState({
        fields,
      });
    }
  }
  inputChangedHandler = (values) => {
    let fields = this.state.fields;
    fields["phone"] = values.value;
    this.setState({
      fields,
    });
  };
  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;

      fields["birthDate"] = moment(fields["birthDate"]).format("MM/DD/YYYY");

      if (fields["procedureDate"] && fields["procedureDate"] !== "") {
        fields["procedureDate"] = moment(fields["procedureDate"]).format(
          "MM/DD/YYYY"
        );
      } else {
        fields["procedureDate"] = "";
      }
      if (fields.id !== "" && fields.id !== undefined) {
        this.props.putPatient(fields, this.props.physicians);
      } else {
        fields.id = "";
        this.props.postPatient(fields, this.props.physicians);
      }

      let prev_fields = this.state.fields;
      prev_fields["birthDate"] = new Date(prev_fields["birthDate"]);
      if (
        prev_fields["procedureDate"] !== undefined &&
        prev_fields["procedureDate"] !== ""
      ) {
        prev_fields["procedureDate"] = new Date(prev_fields["procedureDate"]);
      } else {
        prev_fields["procedureDate"] = null;
      }
      this.setState({ fields: prev_fields });
      //fields = {};
      //this.setState({ fields: prev_fields });
      //alert("Form submitted");
      //this.props.closePopup();
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    
    if (!fields["firstName"].match(/^[a-z A-Z,.'-]+$/)) {
      formIsValid = false;
      errors["firstName"] = "Enter a valid name";
    }

    if (!fields["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "This field is required";
    }
    
    if (!fields["lastName"].match(/^[a-z A-Z,.'-]+$/)) {
      formIsValid = false;
      errors["lastName"] = "Enter a valid name";
    }

    if (!fields["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "This field is required";
    }

    if (!fields["birthDate"]) {
      formIsValid = false;
      errors["birthDate"] = "This field is required";
    } else if (
      fields["birthDate"] !== "" &&
      !moment(fields["birthDate"]).isValid()
    ) {
      formIsValid = false;
      errors["birthDate"] = "This field is invalid";
    }

    if (fields["procedureDate"] && !moment(fields["procedureDate"]).isValid()) {
      formIsValid = false;
      errors["procedureDate"] = "This field is invalid";
    }
    if (!fields["email"]) {
      // formIsValid = false;
      // errors["email"] = "*This field is required";
    } else if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "This field is invalid";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "This field is required";
    } else if (fields["phone"].length !== 10) {
      formIsValid = false;
      errors["phone"] = "This field is invalid";
    }
    // if (!fields["physician"]) {
    //     formIsValid = false;
    //     errors["physician"] = "Please select Physician";
    // }

    // if (typeof fields["phone"] !== "undefined") {
    //     if (!fields["phone"].match(/^[0-9]{10}$/)) {
    //         formIsValid = false;
    //         errors["phone"] = "Please enter valid Phone";
    //     }
    // }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose = () => {
    this.setState({ show: false });
  };

  birthDateChange = (date) => {
    let fields = this.state.fields;
    fields["birthDate"] = date;
    this.setState({
      fields,
    });
  };

  dateKeyDown = (e) => {
    e.preventDefault();
  }

  procedureDateChange = (date) => {
    let fields = this.state.fields;
    fields["procedureDate"] = date;
    this.setState({
      fields,
    });
  };
  render() {
    const elem = document.querySelectorAll('input[name=birthDate]')[0];
    if(elem){
      elem.setAttribute('disabled', "true")
    }

    return (
      <div>
        <Modal
          className="modal-656"
          show={this.props.showPopup}
          onHide={this.props.closePopup}
          backdrop={"static"}
          keyboard={false}
        >
          <Modal.Header>
            {this.state.fields.id !== "" &&
            this.state.fields.id !== undefined ? (
              <Modal.Title className="font-18 font-quicksand">
                Edit Patient
              </Modal.Title>
            ) : (
              <Modal.Title className="font-18 font-quicksand">
                New Patient
              </Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {/* <!--New Patient Dialog Content--> */}
            <form
              method="post"
              name="userRegistrationForm"
              onSubmit={this.submituserRegistrationForm.bind(this)}
            >
              <div id="newPatientContent" className="modal-content">
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                        <div className="form-group modal-col">
                          {/* <label htmlFor="firstName">First Name<span> *</span></label>
                                                     <input type="text" className="form-control" id="firstName" name="firstName" value={this.state.fields.firstName} onChange={this.handleChange.bind(this)} />
                                                    */}
                          <TextField
                            type="text"
                            label="First Name *"
                            id="firstName"
                            name="firstName"
                            value={this.state.fields.firstName}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.errors.firstName ? true : false}
                            helperText={this.state.errors.firstName}
                            className="w-100"
                          />
                          {/* <div className="errorMsg">{this.state.errors.firstName}</div> */}
                        </div>

                        <div className="form-group modal-col">
                          {/*<label htmlFor="lastName">Last Name<span> *</span></label>
                                                     <input type="text" className="form-control" id="lastName" name="lastName"
                                                        value={this.state.fields.lastName} onChange={this.handleChange.bind(this)} /> */}
                          <TextField
                            type="text"
                            label="Last Name *"
                            id="lastName"
                            name="lastName"
                            value={this.state.fields.lastName}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.errors.lastName ? true : false}
                            helperText={this.state.errors.lastName}
                            className="w-100"
                          />
                          {/* <div className="errorMsg">{this.state.errors.lastName}</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                        <div className="form-group position-relative modal-col">
                          {/* <label htmlFor="birthDate">DOB<span> *</span></label>
                                                     <DatePicker
                                                        className="form-control datepicker bg-transparent"
                                                        selected={this.state.fields.birthDate}
                                                        name="birthDate"
                                                        onChange={this.birthDateChange}
                                                        dateFormat="MM/dd/yyyy"
                                                    /> */}

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              // disableToolbar
                              variant="inline"
                              views={["year", "month", "date"]}
                              label="DOB *"
                              format="MM/dd/yyyy"
                              value={this.state.fields.birthDate}
                              name="birthDate"
                              onChange={this.birthDateChange}
                              InputAdornmentProps={{ position: "end" }}
                              error={this.state.errors.birthDate ? true : false}
                              helperText={this.state.errors.birthDate}
                              className="w-100"
                              disableFuture={true}
                              onKeyDown={this.dateKeyDown}
                            />
                          </MuiPickersUtilsProvider>
                          {/* <div className="errorMsg">{this.state.errors.birthDate}</div> */}
                        </div>

                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Email"
                            id="email"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.errors.email ? true : false}
                            helperText={this.state.errors.email}
                            className="w-100"
                          />

                          {/* <div className="errorMsg">{this.state.errors.phone}</div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                        <div className="form-group modal-col">
                          <TextField
                            type="text"
                            label="Procedure"
                            id="procedureDesc"
                            name="procedureDesc"
                            value={this.state.fields.procedureDesc}
                            onChange={this.handleChange.bind(this)}
                            className="w-100"
                          />
                        </div>

                        <div className="form-group position-relative modal-col">
                          {/*<label htmlFor="procedureDate">Procedure Date</label>
                                                     <DatePicker
                                                        className="form-control datepicker bg-transparent"
                                                        selected={this.state.fields.procedureDate}
                                                        name="procedureDate"
                                                        onChange={this.procedureDateChange}
                                                        dateFormat="MM/dd/yyyy"
                                                    /> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              // disableToolbar
                              variant="inline"
                              views={["year", "month", "date"]}
                              label="Procedure Date"
                              format="MM/dd/yyyy"
                              name="procedureDate"
                              value={this.state.fields.procedureDate}
                              onChange={this.procedureDateChange}
                              InputAdornmentProps={{ position: "end" }}
                              className="w-100"
                              helperText={this.state.errors.procedureDate}
                              error={
                                this.state.errors.procedureDate ? true : false
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                        <div className="form-group modal-col">
                          {/* <label htmlFor="appointmentType">Physician <span> *</span></label>
                                                    <select className="custom-select form-control w-100" id="appointmentType" name="physician" value={this.state.fields.physician} onChange={this.handleChange.bind(this)}>
                                                        <option value="">Select Physician</option>
                                                        {this.props.physicians.map((val, index) => {
                                                            return (
                                                                <option key={index} value={val.id}>{val.name} </option>
                                                            )
                                                        })}
                                                    </select> */}

                          <FormControl className="w-100">
                            <InputLabel id="appointmentTypeLable">
                              Physician
                            </InputLabel>
                            <Select
                              className="w-100"
                              labelId="appointmentTypeLable"
                              id="appointmentType"
                              name="physician"
                              value={this.state.fields.physician}
                              onChange={this.handleChange.bind(this)}
                            >
                              {/* <MenuItem value=""> Select Physician </MenuItem> */}
                              {this.props.physicians.map((val, index) => {
                                return (
                                  <MenuItem key={index} value={val.id}>
                                    {val.lastName}, {val.firstName}{" "}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          {/* <div className="errorMsg">{this.state.errors.physician}</div> */}
                        </div>

                        <div className="form-group modal-col">
                          {/* <label htmlFor="phone">Phone <span> *</span></label> */}
                          {/* <input type="text" className="form-control" id="phone" name="phone" value={this.state.fields.phone} onChange={this.handleChange.bind(this)} /> */}
                          <NumberFormat
                            label="Phone *"
                            customInput={TextField}
                            format="(###) ###-####"
                            className="w-100"
                            mask="_"
                            id="phone"
                            name="phone"
                            onValueChange={this.inputChangedHandler}
                            value={this.state.fields.phone}
                            error={this.state.errors.phone ? true : false}
                            helperText={this.state.errors.phone}
                          />

                          {/* <div className="errorMsg">{this.state.errors.phone}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-border-primary box-shadow-none text-uppercase mr-3" data-dismiss="modal" onClick={this.props.closePopup}>Cancel</button>
                                <button type="submit" className="btn btn-primary text-uppercase px-4 ml-0">
                                    {this.state.fields.id !== '' && this.state.fields.id !== undefined ?
                                        'SAVE' :
                                        'NEXT'
                                    }
                                </button> */}

                <Button
                  variant="outlined"
                  onClick={this.props.closePopup}
                  className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                  data-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary text-uppercase px-4 ml-0"
                >
                  {this.state.fields.id !== "" &&
                  this.state.fields.id !== undefined
                    ? "SAVE"
                    : "NEXT"}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default PatientModal;
