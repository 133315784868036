import {

  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_LIST_ERROR,
  PASTAPPOINTMENT_LIST_SUCCESS,
  PASTAPPOINTMENT_LIST_ERROR,
  APPOINTMENT_SAVE_SUCCESS,
  APPOINTMENT_SAVE_ERROR,
  APPOINTMENT_STATUS_SUCCESS,
  APPOINTMENT_STATUS_ERROR,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_CANCEL_ERROR,
  APPOINTMENT_PARTICIPANTS_SUCCESS,
  APPOINTMENT_PARTICIPANTS_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
  appointments: [],
  pastappointments: [],
  appointmentStatus: [],
  appointmentCancel: [],
};

export const appointmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPOINTMENT_LIST_ERROR:
    case APPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        appointments: action.payload
      };
    case PASTAPPOINTMENT_LIST_ERROR:
    case PASTAPPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        pastappointments: action.payload
      };
    case APPOINTMENT_SAVE_SUCCESS:
    case APPOINTMENT_SAVE_ERROR:
      return {
        ...state,
        appointmentAdd: action.payload
      };
    case APPOINTMENT_STATUS_SUCCESS:
    case APPOINTMENT_STATUS_ERROR:
      return {
        ...state,
        appointmentStatus: action.payload
      };
    case APPOINTMENT_PARTICIPANTS_SUCCESS:
    case APPOINTMENT_PARTICIPANTS_ERROR:
      return {
        ...state,
        appointmentParticipants: action.payload
      };
      
    case APPOINTMENT_CANCEL_SUCCESS:
    case APPOINTMENT_CANCEL_ERROR:
      return {
        ...state,
        appointmentCancel: action.payload
      };
    default:
      return state;
  }
};
