import { connect } from 'react-redux';
import Reportlists from '../../../Components/PageContainer/Reports/Reportlist';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    return {
        //users: state.user.users,        
        physicians: state.physician.physicians,
        reportList: state.report.reportList,
    };
};
const mapDispatchToProps = dispatch => ({
    //getUsers: (filterBy, sortOn, sortBy, practice) => dispatch(actionCreators.userList(filterBy, sortOn, sortBy, practice)),    
    getPhysicianList: () => dispatch(actionCreators.physicianList()),
    getReportList: (searchFilter, pageInfo) => dispatch(actionCreators.getReportList(searchFilter, pageInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reportlists);