import React from "react";
import "./Room.css";
import IconSvg from "../Svgicon/Svgicon";
import Video from "twilio-video";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Participant from "./Participant";
import Waitingmsg from "./Waitingmsg";
import Branding from "../../../Services/Branding";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import TwilioVideo from "twilio-video";
import { first, sortBy } from "lodash";
import { isMobile } from "mobile-device-detect";
import captureVideoFrame from "capture-video-frame";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { alertService } from "../../../data/services/alertService";
import CustomImg from "../../Global/CustomImg/CustomImg";
import { isString } from "lodash";
import CameraBlockMsg from "./CameraBlockMsg";
import Videocontrols from "./Videocontrols";
import OutsideClickHandler from 'react-outside-click-handler';

class Room extends React.Component {
  localVideoTrack = null;
  localAudioTrack = null;
  stream = null;

  constructor(props) {
    super(props);
    this.state = {
      room: null,
      showEndSessionConfirm: false,
      showSessionQuality: false,
      fields: { completed: "true" },
      toggleAudio: true,
      toggleVideo: true,
      logoImageUrl: null,
      videoRoom: null,
      isSharescreenStart: false,
      annotateMenuOpen: false,
      participantMenuOpen: false,
      anchorEl: null,
      showLoadImage: false,
      isLockScreen: false,
      annotate_sel: "Annotate",
      isMainPhysician: props.userId === props.scheduleInfo.physicianId || false,
      isPhysianJoined: false,
      showCameraBlocked: false,
      showCameraBlockMsg: false,
      localParticipant: "",
      mainViewportAttendee: null,
      topRightViewportAttendee: null,
      sideViewportAttendees: [],
      wipFeature: true,
      isFrontCamera: true,
      remoteUserVideotrack: [],
      clickOnWin: false,
      networkQuality: null,
      isPhysicianAlreadyJoined: false
    };
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
  }

  async setBranding() {
    const branding = await Branding.getBranding(this.props.applicationInfo);
    if (this.state.logoImageUrl !== branding.logoImageUrl) {
      this.setState({ logoImageUrl: branding.logoImageUrl });
    }
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //     return this.state.isPhysianJoined != nextState.isPhysianJoined;
  // }
  componentDidMount() {
    //check the browser has permission for camera and mic, if not or denied by user then displaying the error message
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((s) => {
        this.stream = s;
        return true;
      })
      .catch(function (err) {
        //alert('ddd');
        return false;
        //this.cameraPermisionPopup();
        //alertService.error("Your camera and microphone are blocked.Application requires access to your camera and microphone. Click the camera blocked icon in your browser's address bar and then refresh.");
      })
      .then((res) => {
        if (!res) {
          this.setState({ showCameraBlockMsg: true });
        } else {
          if (this.state.showCameraBlockMsg) this.setState({ showCameraBlockMsg: false });
          this._initTwilioRoom();
        }
      });

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);

    this.interval = setInterval(() => {
      this.setState({ clickOnWin: true });
    }, 6000);
  }

  _closeCameraBlockedPopup = () => {
    this.setState({ showCameraBlocked: false, showCameraBlockMsg: true });
  };

  async componentWillMount() {
    this.setBranding();
    this.props.getCurrentUserInfo();
  }

  _initTwilioRoom = async () => {
    if (!this.props.token || !isString(this.props.token)) {
      console.log("No room token.");
      return;
    }

    this.localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    this.setState({ localVideoTrack: this.localVideoTrack });

    this.localAudioTrack = await TwilioVideo.createLocalAudioTrack();
    this.setState({ localAudioTrack: this.localAudioTrack });

    const room = await Video.connect(this.props.token, {
      name: this.props.roomName,
      tracks: [this.localVideoTrack, this.localAudioTrack],
    }).catch((e) => {
      console.log("Error connecting==>", e);
    });

    if (room) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        var cameraList = devices.filter((d) => d.kind === "videoinput") || [];
        this.setState({ cameraList });
      });

      const participants = Array.from(room.participants).map((x) => x[1]);
      participants.push(room.localParticipant);
      if (room.participants.length > 0) {
        console.log(room.participants);
        room.participants.videoTracks.forEach((track) => {
          if (track.kind == 'video') {
            let remoteUserVideotrack = this.state.remoteUserVideotrack;
            remoteUserVideotrack[track.trackSid] = {};
            remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
            this.setState({ remoteUserVideotrack: remoteUserVideotrack });
            console.log(remoteUserVideotrack);
          }
        });

      }
      let isPhysicianjoied = participants.find((x) => x.identity === "physician");
      if (isPhysicianjoied) this.setState({ isPhysianJoined: true });
    }

    this.setState({ room: room });

    //console.log("Room", room);

    this.determineAttendees();
    room.on("participantConnected", (participant) => {
      //console.log("participantConnected", participant, room);
      this.setState({ room: room });
      if (participant.identity === "physician") {
        this.setState({ isPhysianJoined: true });
      }
      this.determineAttendees();
      ///console.log(participant);
      let remoteUserVideotrack = this.state.remoteUserVideotrack;
      participant.videoTracks.forEach((track) => {
        if (track.kind == 'video') {
          remoteUserVideotrack[track.trackSid] = {};
          remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
        }
      });
      this.setState({ remoteUserVideotrack: remoteUserVideotrack });
      //console.log(this.state.remoteUserVideotrack);
      // let remoteUserVideotrack = [];
      // remoteUserVideotrack[track.trackSid] = {};
      // remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
      // this.setState({ remoteUserVideotrack: remoteUserVideotrack });
    });
    room.on("participantDisconnected", (participant) => {
      //console.log("participantDisconnected", participant, room);
      // when physician disconnect,redirect user to endsession page.
      if (participant.identity === "physician") {
        this.setState({ isPhysianJoined: false });
        //this.props.endSessionRed();
      }
      this.setState({ room: room });
      this.determineAttendees();

      let remoteUserVideotrack = this.state.remoteUserVideotrack;
      participant.videoTracks.forEach((track) => {
        if (track.kind == 'video') {
          remoteUserVideotrack[track.trackSid] = {};
          remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
        }
      });
      this.setState({ remoteUserVideotrack: remoteUserVideotrack });
    });
    room.on("reconnecting", (error) => {
      this.determineAttendees();
      console.log("reconnecting", error, room);
      /* if(error && error.message) {
        alertService.error(error.message, {autoClose: true});
      } */
    });
    room.on("reconnected", () => {
      this.determineAttendees();
      console.log("reconnected", room);
      this.setState({ networkQuality: null });
    });
    room.on("disconnected", (room, error) => {
      this.localVideoTrack.stop();
      this.localVideoTrack = null;
      this.localAudioTrack.stop();
      this.localAudioTrack = null;
      console.log("disconnected", room, error);
      if(error && error.message) {
        if(error.code === 53205) {
          this.setState({ isPhysicianAlreadyJoined: true });
          alertService.error(error.message, {autoClose: true});
          setTimeout(function () {
            window.location.href = "/";
          }, 100);
        } else if(error.code === 53001 || error.code === 53405) {
          this.setState({ networkQuality: error.message });
        }
      }
    });
    room.on("participantReconnecting", (remoteParticipant) => {
      this.determineAttendees();
      console.log("participantReconnecting", remoteParticipant, room);
    });
    room.on("participantReconnected", (remoteParticipant) => {
      this.determineAttendees();
      console.log("participantReconnected", remoteParticipant, room);
    });
    room.on('trackDisabled', track => {
      if (track.kind == 'video') {
        let remoteUserVideotrack = this.state.remoteUserVideotrack;
        remoteUserVideotrack[track.trackSid] = {};
        remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
        this.setState({ remoteUserVideotrack: remoteUserVideotrack });
        console.log(remoteUserVideotrack);
      }
      // hide or remove the media element related to this track
    });
    room.on('trackEnabled', track => {
      console.log(track);
      if (track.kind == 'video') {
        let remoteUserVideotrack = this.state.remoteUserVideotrack;
        remoteUserVideotrack[track.trackSid] = {};
        remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;
        this.setState({ remoteUserVideotrack: remoteUserVideotrack });
        console.log(remoteUserVideotrack);
      }
      // hide or remove the media element related to this track
    });
  };

  shareScreen = async () => {
    //alert('call');
    //const localVideoTrack = await TwilioVideo.createLocalVideoTrack();
    //this.setState({ localVideoTrack });
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia();

      // const screenLocalTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);

      // screenLocalTrack.once('stopped', () => {
      //     this.stopTrack("screenTrack");
      // });

      //console.log(stream);
      stream.getVideoTracks()[0].addEventListener("ended", () => {
        console.log("screensharing has ended");
        this.setState({ showLoadImage: false });
        this.stopTrack("screenTrack");
      });
      stream.getVideoTracks()[0].addEventListener("start", () => {
        console.log("screensharing has started");
      });

      const newScreenTrack = await first(stream.getVideoTracks());

      this.setState({
        screenTrack: new TwilioVideo.LocalVideoTrack(newScreenTrack),
      });
      //console.log(newScreenTrack);
      console.log(this.state.localVideoTrack);

      let videoRoom = this.state.room;

      videoRoom.localParticipant.publishTrack(newScreenTrack);
      videoRoom.localParticipant.unpublishTrack(this.state.localVideoTrack);
      this.setState({ room: videoRoom });
      this.setState({ isSharescreenStart: true });
    } catch (error) {
      console.log(error);
      //this.stopScreenTrack();
      this.handleClickButton("Stop Screen");

      this.setState({
        errorMessage: error.message,
        isSharescreenStart: false,
      });
    }
  };
  stopScreenTrack = () => this.stopTrack("screenTrack");
  stopTrack = async (trackName) => {
    if (this.state.isSharescreenStart) {
      const track = this.state[trackName];
      if (track) {
        track.stop();
        this.setState({ [trackName]: null });
      }
      // this.setState({ isSharescreenStart: false });

      let localVideoTrack = await TwilioVideo.createLocalVideoTrack();
      this.setState({ localVideoTrack });

      let videoRoom = this.state.room;
      if (videoRoom !== null) {
        videoRoom.localParticipant.unpublishTrack(track);
        videoRoom.localParticipant.publishTrack(localVideoTrack);
      }
      this.setState({ room: videoRoom, isSharescreenStart: false });
    }
  };

  handleAudioToggle = () => {
    this.state.room.localParticipant.audioTracks.forEach((track) => {
      if (track.track.isEnabled) {
        track.track.disable();
      } else {
        track.track.enable();
      }
      this.setState({ toggleAudio: track.track.isEnabled });
    });
  };

  handleVideoToggle = () => {
    this.state.room.localParticipant.videoTracks.forEach((track) => {
      if (track.track.isEnabled) {
        track.track.disable();
      } else {
        track.track.enable();
      }
      this.setState({ toggleVideo: track.track.isEnabled });
    });
  };

  handleCameraFlip = async (id) => {
    if (!id || !this.localVideoTrack) return;
    this.localVideoTrack
      .restart({ deviceId: id })
      .then(() => {
        this.setState({ isFrontCamera: !this.state.isFrontCamera });
      })
      .catch((e) => {
        console.log("Error while switching camera==>", e);
      });
  };

  endSessionConfirmation = () => {
    this.setState({ showEndSessionConfirm: true });
  };
  closeEndsessionConfirmPopup = () => {
    this.setState({ showEndSessionConfirm: false });
  };
  closeSessionQualityPopup = () => {
    this.setState({ showSessionQuality: false });
  };
  async endSession() {
    this.setState({ showEndSessionConfirm: false });
    this.setState({ showSessionQuality: true });
    if (this.state.room) {
      this.state.room.disconnect();
      // const stream = await navigator.mediaDevices.getDisplayMedia();
      // let tracks = stream.getTracks();
      // tracks.forEach(function (track) {
      //     track.stop();
      // });
    }
    //this.props.endSessionRed();
  }
  handleChange = (e) => {
    let fields_val = this.state.fields;
    fields_val[e.target.name] = e.target.value;
    this.setState(fields_val);
  };

  async submitSessionQualityFrom(e, props) {
    e.preventDefault();
    await this.props.submitSessionQuality(this.props.appointmentId, this.state.fields);

    //this.closeSessionQualityPopup();
    //this.state.room.disconnect();
    //this.props.endSessionRed();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedbackAppointment !== this.props.feedbackAppointment) {
      if (this.props.feedbackAppointment) {
        if (this.props.feedbackAppointment.status === "success") {
          this.closeSessionQualityPopup();
          if (this.state.room) {
            this.state.room.disconnect();
          }
          this.props.endSessionRed();
        }
      }
    }
    if (this.props.userId && this.props.userId !== prevProps.userId) {
      if (this.props.userId === this.props.scheduleInfo.physicianId) {
        this.setState({ isMainPhysician: true });
      }
    }

    if (this.props.clickOnWin !== prevProps.clickOnWin) {
      this.setState({ clickOnWin: this.props.clickOnWin });
    }
  }

  componentWillUnmount() {
    this.handleUnload();
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);

    if (this.stream) {
      this.stream.getTracks().forEach(async (track) => {
        track.stop();
      });
    }

    if (this.localAudioTrack) {
      this.localAudioTrack.stop();
    }
    if (this.localVideoTrack) {
      this.localVideoTrack.stop();
    }
  }

  handleBeforeUnload(e) {
    const message =
      "Warning!\n\nNavigating away from this page will delete your text if you haven't already saved it.";
    e.returnValue = message;
    return message;
  }

  handleUnload() {
    if (this.state.room) {
      this.state.room.disconnect();
    }
    //this.props.endSessionRed();
  }

  editorRef = React.createRef();

  handleClickButton = (annotate) => {
    //const editorInstance = this.editorRef.current.getInstance();
    const editorInstance = null;

    switch (annotate) {
      case "Share Screen":
        this.setState({ showLoadImage: true });
        this.shareScreen();
        return;
      case "Stop Screen":
        this.setState({ showLoadImage: false });
        this.stopScreenTrack();
        return;
      case "Add Text":
        if (!editorInstance) return;
        editorInstance.addText();
        this.closeAnnotateMenu();
        this.setState({ annotate_sel: "Text" });
        return;
      case "Free Hand":
        if (!editorInstance) return;
        editorInstance.stopDrawingMode();
        editorInstance.startDrawingMode("FREE_DRAWING", {
          width: 10,
          color: "rgba(255,0,0,0.5)",
        });
        this.setState({ annotate_sel: "Free Hand" });
        this.closeAnnotateMenu();
        return;
      case "Arrow":
        if (!editorInstance) return;
        editorInstance.stopDrawingMode();
        editorInstance.startDrawingMode("LINE_DRAWING", {
          width: 10,
          color: "rgba(255,0,0,0.5)",
          arrowType: {
            tail: "triangle",
          },
        });
        this.setState({ annotate_sel: "Arrow" });
        this.closeAnnotateMenu();
        return;
      case "Circle":
        if (!editorInstance) return;
        editorInstance.stopDrawingMode();
        editorInstance.setDrawingShape("circle", {
          fill: "transparent",
          stroke: "red",
          strokeWidth: 3,
          rx: 100,
          ry: 100,
          isRegular: true,
        });

        editorInstance.startDrawingMode("SHAPE", {
          width: 20,
        });
        this.setState({ annotate_sel: "Circle" });
        this.closeAnnotateMenu();
        return;
      case "Rectangle":
        if (!editorInstance) return;
        editorInstance.stopDrawingMode();
        editorInstance.setDrawingShape("rect", {
          fill: "transparent",
          stroke: "red",
          strokeWidth: 3,
          width: 100,
          height: 300,
          rx: 0,
          ry: 0,
          isRegular: true,
        });

        editorInstance.startDrawingMode("SHAPE", {
          width: 20,
        });
        this.setState({ annotate_sel: "Rectangle" });
        this.closeAnnotateMenu();
        return;

      case "ROM":
        //     editorInstance.loadImageFromFile(file).then(result => {
        //         console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
        //         console.log('new : ' + result.newWidth + ', ' + result.newHeight);
        //    });
        return;

      default:
        return;
    }

    this.closeAnnotateMenu();
  };

  openAnnotateMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      annotateMenuOpen: !this.state.annotateMenuOpen,
    });
  };

  closeAnnotateMenu = () => {
    this.setState({
      annotateMenuOpen: false,
    });
  };

  openParticipantMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      participantMenuOpen: !this.state.participantMenuOpen,
    });
  };

  closeParticipantMenu = () => {
    this.setState({
      participantMenuOpen: false,
    });
  };

  handleLockScreenButton = () => {
    this.setState({ isLockScreen: true });
    const frame = captureVideoFrame("my-video", "png");
    //console.log(frame);
    // Show the image
    //const img = document.getElementById("my-screenshot");
    if (frame.dataUri) {
      //img.setAttribute("src", frame.dataUri);
      const editorInstance = this.editorRef.current.getInstance();
      editorInstance
        .loadImageFromURL(
          editorInstance.toDataURL({ format: "png", width: 1000, height: 500 }),
          "FilterImage"
        )
        .then(() => {
          editorInstance.addImageObject(frame.dataUri).then((objectProps) => {
            console.log(objectProps.id);
            //editorInstance.addObjectAfter(objectProps.id);
          });
        });
    }
  };
  handleUnLockScreenButton = () => {
    // remove all image objects
    const editorInstance = this.editorRef.current.getInstance();
    editorInstance.clearObjects();
    //this.stopScreenTrack();
    this.setState({ isLockScreen: false });
  };

  onCopyLink = () => {
    this.setState({ copied: true });
    this.closeParticipantMenu();
    alertService.success("Link has been copied.", { autoClose: true });
  };

  determineAttendees() {
    /* Scenario 1: Patient View
     *   - Main viewport: Physician
     *   - Top Right viewport: Patient
     *   - Side Viewport(s): Attendee(s)
     * Scenario 2: Physician View
     *   - Main viewport: Patient
     *   - Top Right viewport: Physician
     *   - Side Viewport(s): Attendee(s)
     * Scenario 3: Attendee View (patient present)
     *   - Main viewport: Patient
     *   - Top Right viewport: Physician
     *   - Side Viewport(s): Attendee(s)
     * Scenario 4: Attendee View (patient absent)
     *   - Main viewport: Self
     *   - Top Right viewport: Physician
     *   - Side Viewport(s): Other Attendee(s)
     */

    let mainViewportAttendee;
    let topRightViewportAttendee;
    let sideViewportAttendees = [];

    if (this.state.room) {
      const participants = Array.from(this.state.room.participants).map((x) => x[1]);
      //console.log("Rendering for ", this.state.room.localParticipant.identity);

      switch (this.state.room.localParticipant.identity) {
        case "patient":
          topRightViewportAttendee = this.state.room.localParticipant;

          mainViewportAttendee = participants.find((x) => x.identity === "physician");
          //console.log(mainViewportAttendee);
          if (this.state.isPhysianJoined) {
            sideViewportAttendees = participants.filter((x) => x.identity !== "physician");
          }
          if (this.state.localParticipant === "" || this.state.localParticipant !== "patient") {
            this.setState({ localParticipant: "patient" });
          }
          //console.log(participants);
          if (participants.length > 0) {

            let remoteUserVideotrack = this.state.remoteUserVideotrack;
            participants.forEach((participant) => {
              participant.videoTracks.forEach((track) => {
                if (track.kind == 'video') {

                  remoteUserVideotrack[track.trackSid] = {};
                  remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;

                  console.log(remoteUserVideotrack);
                }
              });
            });
            this.setState({ remoteUserVideotrack: remoteUserVideotrack });
          }
          break;
        case "physician":
          mainViewportAttendee = participants.find((x) => x.identity === "patient");
          //console.log(mainViewportAttendee);
          topRightViewportAttendee = this.state.room.localParticipant;
          sideViewportAttendees = participants.filter((x) => x.identity !== "patient");
          if (this.state.localParticipant === "" || this.state.localParticipant !== "physician") {
            this.setState({ localParticipant: "physician" });
          }

          if (participants.length > 0) {

            let remoteUserVideotrack = this.state.remoteUserVideotrack;
            participants.forEach((participant) => {
              participant.videoTracks.forEach((track) => {
                if (track.kind == 'video') {

                  remoteUserVideotrack[track.trackSid] = {};
                  remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;

                  console.log(remoteUserVideotrack);
                }
              });
            });
            this.setState({ remoteUserVideotrack: remoteUserVideotrack });
          }
          break;
        default:
          mainViewportAttendee = participants.find((x) => x.identity === "physician");

          if (mainViewportAttendee) {
            console.log(mainViewportAttendee);
            topRightViewportAttendee = participants.find((x) => x.identity === "patient");

            sideViewportAttendees.push(this.state.room.localParticipant);
            console.log(sideViewportAttendees);
            if (this.state.isPhysianJoined) {
              participants.forEach((x) => {
                if (x.identity === "attendee1" || x.identity === "attendee2") {
                  sideViewportAttendees.push(x);
                }
              });
            }
          } else {
            mainViewportAttendee = '';
            sideViewportAttendees.push(this.state.room.localParticipant);
            if (this.state.isPhysianJoined) {
              topRightViewportAttendee = participants.find((x) => x.identity === "patient");
              participants.forEach((x) => {
                if (x.identity === "attendee1" || x.identity === "attendee2") {
                  sideViewportAttendees.push(x);
                }
              });
            }
          }
          if (this.state.localParticipant === "" || this.state.localParticipant !== "attendee") {
            this.setState({ localParticipant: "attendee" });
          }

          if (participants.length > 0) {

            let remoteUserVideotrack = this.state.remoteUserVideotrack;
            participants.forEach((participant) => {
              participant.videoTracks.forEach((track) => {
                if (track.kind == 'video') {

                  remoteUserVideotrack[track.trackSid] = {};
                  remoteUserVideotrack[track.trackSid].isTrackEnabled = track.isTrackEnabled;

                  console.log(remoteUserVideotrack);
                }
              });
            });
            this.setState({ remoteUserVideotrack: remoteUserVideotrack });
          }
          break;
      }
    }

    this.setState({
      mainViewportAttendee,
      topRightViewportAttendee,
      sideViewportAttendees,
    });
  }
  clickOnWin() {
    let clickOnWin = !this.state.clickOnWin;
    this.setState({ clickOnWin: clickOnWin });
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.setState({ clickOnWin: true });
    }, 6000); // call function every 60sec
  }

  //return to dashboard
  _refreshPage = () => {
    window.location.reload();
  };
  
  render() {
    //console.log("Room-state-", this.state);
    //console.log("Room-props-", this.props);
    const { wipFeature, showCameraBlockMsg, showCameraBlocked, isMainPhysician } = this.state;
    return (
      <div>
        {/* {!isMobile && !this.state.isSharescreenStart ?
                    <div onClick={() => this.shareScreen()}>Share Screen</div>
                    : ''}
                {!isMobile && this.state.isSharescreenStart ?
                    <div onClick={() => this.stopScreenTrack()}>Stop Screen</div> : ''} */}
        {!this.state.showSessionQuality && !this.state.networkQuality && (
          <React.Fragment>
            {!isMobile && !wipFeature && isMainPhysician ? (
              //!isMobile ?
              <div className="session-controls">
                <ul className="d-flex">
                  <li>
                    {!this.state.showLoadImage ? (
                      <Button
                        className="btn-control"
                        onClick={() => this.handleClickButton("Share Screen")}>
                        <span>
                          <IconSvg name="share" color="#000000" size={14} />
                          {/* <IconSvg name="stop" color="#000000" size={14} /> */}
                          <span className="d-block btn-label">
                            Share
                            <em className="d-block">Screen</em>
                          </span>
                        </span>
                      </Button>
                    ) : (
                        <Button
                          className="btn-control"
                          onClick={() => this.handleClickButton("Stop Screen")}>
                          <span>
                            <IconSvg name="stop" color="#000000" size={14} />
                            <span className="d-block btn-label">
                              Share
                            <em className="d-block">Screen</em>
                            </span>
                          </span>
                        </Button>
                      )}
                  </li>

                  <li
                    style={{
                      display: this.state.isSharescreenStart ? "block" : "none",
                    }}>
                    <Button className="btn-control" onClick={this.openAnnotateMenu}>
                      <span>
                        <IconSvg name="annotate" color="#000000" size={14} />
                        <span className="d-block btn-label">{this.state.annotate_sel}</span>
                      </span>
                      <span className="material-icons">keyboard_arrow_down</span>
                    </Button>

                    <Menu
                      id="annotate-menu"
                      className="session-control-dropdown"
                      anchorEl={this.state.anchorEl}
                      open={this.state.annotateMenuOpen}
                      onClose={this.closeAnnotateMenu}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}>
                      <MenuItem onClick={() => this.handleClickButton("Free Hand")}>
                        Free-Hand
                      </MenuItem>
                      <MenuItem onClick={() => this.handleClickButton("Add Text")}>Text</MenuItem>
                      <MenuItem onClick={() => this.handleClickButton("Arrow")}>Arrow</MenuItem>
                      <MenuItem onClick={() => this.handleClickButton("Circle")}>Circle</MenuItem>
                      <MenuItem onClick={() => this.handleClickButton("Rectangle")}>
                        Rectangle
                      </MenuItem>
                    </Menu>
                  </li>

                  <li>
                    {this.state.isLockScreen ? (
                      <Button
                        className="btn-control"
                        onClick={() => this.handleUnLockScreenButton()}>
                        <span>
                          <IconSvg name="unlock" color="#000000" size={14} />
                          <span className="d-block btn-label">
                            Unlock
                            <em className="d-block">Screen</em>
                          </span>
                        </span>
                      </Button>
                    ) : (
                        <Button className="btn-control" onClick={() => this.handleLockScreenButton()}>
                          <span>
                            <IconSvg name="lock" color="#000000" size={14} />
                            <span className="d-block btn-label">
                              Lock
                            <em className="d-block">Screen</em>
                            </span>
                          </span>
                        </Button>
                      )}
                  </li>

                  <li>
                    <Button
                      className="btn-control"
                      onClick={() => this.handleClickButton("ROM")}
                      disabled="disabled">
                      <span>
                        <IconSvg name="rom" color="#000000" size={14} />
                        <span className="d-block btn-label">ROM</span>
                      </span>
                    </Button>
                  </li>

                  <li>
                    <Button className="btn-control" onClick={this.openParticipantMenu}>
                      <span>
                        <IconSvg name="participant" color="#000000" size={22} />
                        <span className="d-block btn-label">
                          Add
                          <em className="d-block">Participants</em>
                        </span>
                      </span>
                    </Button>

                    <Menu
                      id="participant-menu"
                      className="session-control-dropdown"
                      anchorEl={this.state.anchorEl}
                      open={this.state.participantMenuOpen}
                      onClose={this.closeParticipantMenu}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}>
                      <CopyToClipboard
                        text={window.location.href + "/" + this.props.scheduleInfo.shortcode}
                        onCopy={this.onCopyLink}>
                        <MenuItem>Copy Link</MenuItem>
                      </CopyToClipboard>
                      <MenuItem disabled="disabled">Send Email</MenuItem>
                      <MenuItem disabled="disabled">Send SMS</MenuItem>
                    </Menu>
                  </li>

                  <li>
                    <Button className="btn-control" disabled="disabled">
                      <span>
                        <IconSvg name="viewimg" color="#000000" size={16} />
                        <span className="d-block btn-label">View Images</span>
                      </span>
                    </Button>
                  </li>
                </ul>
              </div>
            ) : (
                ""
              )}

            <div className="d-flex flex-wrap">
              <div
                className=""
                style={{
                  display:
                    !wipFeature && (this.state.showLoadImage || this.state.isLockScreen)
                      ? "block"
                      : "none",
                }}>
                {!wipFeature && (
                  <ImageEditor
                    ref={this.editorRef}
                    includeUI={{
                      menu: ["rotate", "draw", "shape", "icon", "text", "mask"],
                      initMenu: "filter",
                      uiSize: {
                        width: "1000px",
                        height: "700px",
                      },
                      menuBarPosition: "bottom",
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={700}
                    selectionStyle={{
                      cornerSize: 20,
                      rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}
                    theme={this.customTheme}
                  />
                )}
              </div>

              <div className="flex-grow-1" style={{ display: "block" }}>
                {!this.state.isLockScreen ? (
                  <div className="participant-content bg-black participant-auto-height">
                    <div className="room h-100">
                      <div className="patricipant-wrapper h-100 position-relative">
                        {this.state.topRightViewportAttendee && (
                          <div className="local-participant top-right-viewport">
                            <Participant
                              participant={this.state.topRightViewportAttendee}
                              remoteUserVideotrack={this.state.remoteUserVideotrack}
                              isLocal={
                                this.state.localParticipant === "patient" ||
                                this.state.localParticipant === "physician"
                              }
                            />
                          </div>
                        )}
                        <div className="local-participant-mul side-viewport">
                          {this.state.sideViewportAttendees
                            ? this.state.sideViewportAttendees.map((p) => (
                              <div
                                key={p.sid + "-c"}
                                className="local-participant local-participant-2">
                                <Participant
                                  key={p.sid}
                                  participant={p}
                                  isLocal={this.state.localParticipant === "attendee"}
                                  remoteUserVideotrack={this.state.remoteUserVideotrack}
                                />
                              </div>
                            ))
                            : ""}
                        </div>
                        <div className="remote-participants h-100">
                          <div className={`remote-participant-aspct-3-by-4 main-viewport ${!this.state.isMainPhysician ? 'share-screen-video' : ''}`}>
                            {this.state.mainViewportAttendee ?
                              <Participant participant={this.state.mainViewportAttendee} remoteUserVideotrack={this.state.remoteUserVideotrack} />
                                 :
                                <Waitingmsg
                                  shortcode={this.props.shortcode}
                                  isPhysician={this.state.isMainPhysician}
                                  isPhysianJoined={this.state.isPhysianJoined}
                                  logoImageUrl={this.state.logoImageUrl}
                                />
                              }
                          </div>
                        </div>
                        {this.localVideoTrack && (
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              this.clickOnWin()
                            }}
                          >
                            <div className="video-cn">
                              <Videocontrols
                                handleAudioToggle={this.handleAudioToggle}
                                handleVideoToggle={this.handleVideoToggle}
                                audio={this.state.toggleAudio}
                                video={this.state.toggleVideo}
                                isMainPhysician={isMainPhysician}
                                screenShare={!this.state.showLoadImage}
                                cameraList={this.state.cameraList}
                                isFrontCamera={this.state.isFrontCamera}
                                handleCameraFlip={this.handleCameraFlip}
                                clickOnWin={this.state.clickOnWin}
                                handleScreenShareToggle={() =>
                                  this.state.showLoadImage
                                    ? this.handleClickButton("Stop Screen")
                                    : this.handleClickButton("Share Screen")
                                }
                              />
                            </div>
                          </OutsideClickHandler>
                        )}
                      </div>
                    </div>
                    {showCameraBlockMsg && !showCameraBlocked && (
                      <CameraBlockMsg logoImageUrl={this.state.logoImageUrl} />
                    )}
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
            <div className="text-right pt-4" style={{ padding: "23px 27px" }}>
              {this.props.isClinican === true && !this.state.isPhysicianAlreadyJoined ? (
                <Button
                  type="button"
                  className="btn btn-primary text-uppercase px-4 ml-0 end-session-btn"
                  onClick={this.endSessionConfirmation}>
                  End Session
                </Button>
              ) : null}
            </div>
          </React.Fragment>
        )}

        {/* End session confirm popup */}
        <div>
          <Modal
            show={this.state.showEndSessionConfirm}
            onHide={this.closeEndsessionConfirmPopup}
            keyboard={false}>
            <Modal.Header>
              <Modal.Title className="font-18 font-quicksand">End Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to end this session?</Modal.Body>
            <Modal.Footer></Modal.Footer>
            <div className="modal-footer">
              <Button
                variant="outlined"
                type="button"
                className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                data-dismiss="modal"
                onClick={this.closeEndsessionConfirmPopup}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={() => this.endSession()}
                className="btn btn-primary text-uppercase px-4 ml-0">
                End Session
              </Button>
            </div>
          </Modal>
        </div>
        {/* End session confirm popup */}
        {/* Session Quality popup */}

        <Modal
          show={this.state.showSessionQuality}
          onHide={this.closeSessionQualityPopup}
          backdrop={"static"}
          keyboard={false}>
          <Modal.Header>
            <Modal.Title className="font-18 font-quicksand">Session Quality</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              name="sessionQualityForm"
              onSubmit={this.submitSessionQualityFrom.bind(this)}>
              <div id="newPatientContent" className="modal-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="">
                        <FormControl className="form-check-label">
                          <RadioGroup
                            aria-label="completeGroup"
                            name="completeGroup"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.fields.completed}>
                            <FormControlLabel
                              value={"true"}
                              control={
                                <Radio color="primary" id="completedChecked" name="completed" />
                              }
                              label="Completed Successfully"
                            />
                            <FormControlLabel
                              value={"false"}
                              control={
                                <Radio color="primary" id="notcompletedChecked" name="completed" />
                              }
                              label="Not Completed"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="form-group">
                      <TextField
                        className="w-100"
                        id="exampleFormControlTextarea3"
                        name="sessionIssue"
                        label="Session Issues"
                        multiline
                        rows={3}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {/*<Button variant="outlined" type="button" className="btn btn-border-primary box-shadow-none text-uppercase mr-3" data-dismiss="modal" onClick={this.closeSessionQualityPopup}>Cancel</Button>*/}
                <Button type="submit" className="btn btn-primary text-uppercase px-4 ml-0">
                  Save
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Session Quality popup */}

        {/* Camera and mic has blocked popup */}
        <div>
          <Modal
            show={this.state.showCameraBlocked}
            onHide={this._closeCameraBlockedPopup}
            keyboard={false}>
            <Modal.Header>
              <Modal.Title className="font-18 font-quicksand">
                Your camera and microphone are blocked
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Application requires access to your camera and microphone. Click the camera blocked
              icon <CustomImg src="ic_blocked_camera.svg" />
              in your browser's address bar and then refresh.
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
            <div className="modal-footer">
              <Button
                variant="outlined"
                type="button"
                className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                data-dismiss="modal"
                onClick={this._closeCameraBlockedPopup}>
                Close
              </Button>
            </div>
          </Modal>
        </div>

        {this.state.networkQuality && (
          <div className="thankyou-page-wrapper scrollbar">
                <div className="scroll-min-height d-flex flex-column align-items-center justify-content-center">
                    <div className="mb-4"><CustomImg src={this.state.logoImageUrl} className="img-fluid" alt="Logo" /></div>
                    <div className="text-center thankyou-page-text">
                        <p className="mb-0">Poor network connection detected. Please check your internet connection and try again.</p>
                        <Button
                          type="button"
                          className="btn btn-primary text-uppercase px-4 ml-0 mt-2"
                          onClick={this._refreshPage.bind(this)}>
                          Refresh Page
                        </Button>
                    </div>
                </div>
          </div>
        )}
      </div>
    );
  }
}

export default Room;
