import { connect } from 'react-redux';
import Dashboard from '../../../Components/PageContainer/Dashboard/Dashboard';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    return {
        //appointments: state.appointment.appointments,
        serchtxtpatient: state.patient.serchtxtpatient,
        //pastappointments: state.appointment.pastappointments,
        //physicians: state.physician.physicians,
        userInfo: state.common.userInfo,
        //appointmentStatus:state.appointment.appointmentStatus,
        appointmentAdd: state.appointment.appointmentAdd,        
    };
};

const mapDispatchToProps = dispatch => ({
    //getAppointmentList: (appointment_date, physicians) => dispatch(actionCreators.appointmentList(appointment_date, physicians)),
    //getPastAppointments: (physicians) => dispatch(actionCreators.pastAppointmentList(physicians)),
    getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
    setSearchTxtPatient: (serchTxt) => dispatch(actionCreators.setSearchTxtPatient(serchTxt)),
    //getAppointmentStatus: (appointmentId) => dispatch(actionCreators.appointmentStatus(appointmentId)),
    //getPhysicianList: () => dispatch(actionCreators.physicianList()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);