import { connect } from 'react-redux';
import Room from '../../../Components/PageContainer/Conference/Room';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    return {
        feedbackAppointment: state.conference.feedbackAppointment,
        userInfo: state.common.userInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    submitSessionQuality: (appointmentId, data) => dispatch(actionCreators.submitSessionQuality(appointmentId, data)),
    getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);