import {
  SET_SEARCHTXT_PATIENT,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_ERROR,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  USER_ADD_SUCCESS,
  USER_ADD_ERROR,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  TEAM_ADD_SUCCESS,
  TEAM_ADD_ERROR,
  USER_DEACTIVE_SUCCESS,
  USER_DEACTIVE_ERROR,
  USER_ACTIVE_SUCCESS,
  USER_ACTIVE_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
  serchtxtpatient: [],
  patients: [],
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SEARCHTXT_PATIENT:
      return {
        ...state,
        serchtxtpatient: action.payload
      };
    case USER_LIST_SUCCESS:
    case USER_LIST_ERROR:
      return {
        ...state,
        users: action.payload
      };
    case USER_ADD_SUCCESS:
    case USER_ADD_ERROR:
      return {
        ...state,
        userAdd: action.payload
      };
    case USER_UPDATE_SUCCESS:
    case USER_UPDATE_ERROR:
      return {
        ...state,
        userUpdate: action.payload
      };
    case USER_DEACTIVE_SUCCESS:
    case USER_DEACTIVE_ERROR:
      return {
        ...state,
        userDeactivate: action.payload
      };
    case USER_ACTIVE_SUCCESS:
    case USER_ACTIVE_ERROR:
      return {
        ...state,
        userActivate: action.payload
      }
    case TEAM_LIST_SUCCESS:
    case TEAM_LIST_ERROR:
      return {
        ...state,
        teams: action.payload
      };
    case TEAM_ADD_SUCCESS:
    case TEAM_ADD_ERROR:
      return {
        ...state,
        teamAdd: action.payload
      };
    default:
      return state;
  }
};
