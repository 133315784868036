import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { alertService } from "../../../data/services/alertService";
import _ from "lodash";

const TeamsModal = (props) => {
    const {
    open,
    teams,
    modTeamsList: modTeamsListProps,
    userInfo: { teams: userteams, id: userId },
  } = props;
  const [origteamsList, setOrigTeamsList] = useState([]);
  const [modTeamsList, setModTeamsList] = useState([]);
  const [newTeam, setNewTeam] = useState({ checked: false, label: "" });

  useEffect(() => {
    if (teams && teams.length > 0 && !modTeamsList.length) {
      let origTeamsList = [];
      let tmpIndex = [];
      teams.forEach((item, idx) => {
        origTeamsList[idx] = item.name;
        if (userteams?.includes(item.name)) {
          tmpIndex[idx] = item.name;
        } else {
          tmpIndex[idx] = null;
        }
      });

      setOrigTeamsList([...origTeamsList]);
      setModTeamsList([...tmpIndex]);
    }
    if (teams && teams.length > 0 && modTeamsList.length) {
      let tmpIndex = [];
      teams.forEach((item, idx) => {
        if (userteams?.includes(item.name)) {
          tmpIndex[idx] = item.name;
        } else {
          tmpIndex[idx] = null;
        }
      });
      setModTeamsList((prevProps) => {
        if (!_.isEqual(prevProps, tmpIndex)) {
          return prevProps;
        } else {
          return tmpIndex;
        }
      });
    }
  }, [teams]);

  useEffect(()=> {
    if(modTeamsListProps && modTeamsListProps.length) {
      setModTeamsList([...modTeamsListProps]);
    } 
  },[modTeamsListProps]);

  const handleChangeTeamlist = (e, index) => {
    let tmpModTeamsList = [...modTeamsList];
    tmpModTeamsList[index] = e.target.checked ? e.target.value : null;
    setModTeamsList([...tmpModTeamsList]);
  };

useEffect(()=>{
  if(!open) {
    setNewTeam({label: "", checked: false});
  }
},[open]);

const handleSave =  useCallback(() => {
  let tmpModTeamsList = [...modTeamsList];
  let notNullTeams = tmpModTeamsList.filter(teams => teams !== null);
  const isAddnew = newTeam.checked;
  if(notNullTeams.length > 10 ){
    alertService.error('Teams selected exceeds 10 in count.',{autoClose: true})
  } 
  else if (notNullTeams.length === 10 && isAddnew){
    alertService.error('Teams selected exceeds 10 in count.',{autoClose: true})
  }
  else {
    if(newTeam.label) {
      let new_team = {};
          new_team["name"] = newTeam?.label?.trim();
          new_team["clinicId"] = userId;
  
          const isPresentTeam = origteamsList.filter(team => team ===  newTeam.label.trim())
          if(isPresentTeam.length){
            alertService.error('Team name already exits.',{autoClose: true});
            new_team["name"] = "";
            setNewTeam({...newTeam, label: ""});
          } else {
            props.postTeam(new_team);
            if(newTeam.checked){
              tmpModTeamsList.push(newTeam.label);
              setModTeamsList([...tmpModTeamsList]);
            }
            props.onClose([...tmpModTeamsList]);
          }
    } else {
      props.onClose([...tmpModTeamsList]);
    }
  }
},[modTeamsList, newTeam, origteamsList, props, userId]);


  return (
    <div className="d-flex align-content-start flex-wrap">
      <div className="filter-team-modal">
        <FormControl component="fieldset">
          <FormGroup>
            {origteamsList.map((team, index) => (
              <FormControlLabel
                className="form-checkbox"
                control={
                  <Checkbox
                  id="existingTeam"
                  value={team}
                  onChange={(e) => handleChangeTeamlist(e, index)}
                  name={team}
                  checked={modTeamsList[index]}
                  color="primary"
                  />
                }
                label={team}
              />
            ))}

            <div className="d-flex align-items-center">
              <FormControlLabel
                className="form-checkbox mr-0 mb-0"
                control={
                  <Checkbox
                    id="newTeam"
                    name={newTeam.checked}
                    onChange={e=> setNewTeam({...newTeam, checked: e.target.checked})}
                    color="primary"
                  />
                }
              />
              <TextField
                type="text"
                id="standard-name"
                margin="normal"
                className="my-0 pl-1"
                value={newTeam.label}
                name="new_team"
                onChange={e=>{
                  if(e.target.value.length > 64) return;
                  if(!e.target.value.startsWith(" ")){
                    const val = e.target.value.replace(/  +/g, ' ');
                    if(val.match(/^[a-zA-Z0-9\s\-_&']*$/)) {
                      setNewTeam({...newTeam, label: val})
                    }
                  } else {
                    const val = e.target.value.replace(/ +/g, '');
                    setNewTeam({...newTeam, label: val})
                  }
                }}
              />
            </div>
            <div className="mt-4">
              <Button
                type="button"
                className="btn btn-primary text-uppercase px-4 ml-0"
                onClick={() => handleSave()}
              >
                SAVE
              </Button>
            </div>
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default TeamsModal;
