import { IS_LOADING } from '../actions/actionTypes';
import { combineReducers } from 'redux';

import { commonReducer } from './commonReducers';
import { appointmentReducer } from './appointmentReducers';
import { patientReducer } from './patientReducers';
import { userReducer } from './userReducers';
import { physicianmentReducer } from './physicianReducers';
import { conferenceReducer } from './conferenceReducers';
import { reportReducer } from './reportReducers';

const INITIAL_STATE = false;

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading;

    default:
      return state;
  }
};


export default combineReducers({
  isLoading: loadingReducer,
  appointment: appointmentReducer,
  patient: patientReducer,
  physician: physicianmentReducer,
  conference: conferenceReducer,
  common: commonReducer,
  user: userReducer,
  report: reportReducer
});
