import { TWILIO_TOKEN_GENERATE_SUCCESS, TWILIO_TOKEN_GENERATE_ERROR, FEEDBACK_APPOINTMENT_SUCCESS, FEEDBACK_APPOINTMENT_ERROR} from '../actions/actionTypes';

const INITIAL_STATE = {
  serchtxtpatient: [],
  patients: [],
  twillioInfo: {},
  feedbackAppointment: {}
};

export const conferenceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TWILIO_TOKEN_GENERATE_SUCCESS:
    case TWILIO_TOKEN_GENERATE_ERROR:

      return {
        ...state,
        twillioInfo: action.payload
      };
    case FEEDBACK_APPOINTMENT_SUCCESS:
    case FEEDBACK_APPOINTMENT_ERROR:
      return {
        ...state,
        feedbackAppointment: action.payload
      };
    
    default:
      return state;
  }
};
