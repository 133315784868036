import React, { useState, useEffect, Component } from 'react';
import Button from '@material-ui/core/Button';
import CustomImg from '../Global/CustomImg/CustomImg';
// import { DebounceInput } from 'react-debounce-input';

function PatientSearchbox(props) {
    const { setSearchTxtPatient, onSearchCallback, id, debounceRate, placeholder } = props;
    const [searchPat, setSearchPat] = useState("");
    const [debouncedInputValue, setDebouncedInputValue] = useState("");

    const rate = debounceRate || 500;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(searchPat);
        }, rate);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPat, rate]);

    useEffect(() => {
        if(onSearchCallback) {
            onSearchCallback(debouncedInputValue)
        } else {
            setSearchTxtPatient(debouncedInputValue);
        }
    }, [debouncedInputValue]);

    const searchPatient = (e) => {
        if (!String(e.target.value).startsWith(" ")) {
            const val = String(e.target.value).replace(/  +/g, " ");
            setSearchPat(val);
        }
        else {
            const val = e.target.value.replace(/ +/g, "");
            setSearchPat(val);
        }
    }

    const clearSearchInput = () => {
        setSearchPat("");
    }

    return (
    <React.Fragment>
        <div id={id || "patientSearchBox"}>
            <div className={!placeholder ? "search-bar": ""}>
                <div className="form-group mb-0">
                    <input type="text" value={searchPat} onChange={(event) => searchPatient(event)}
                        placeholder={placeholder || "Patient Search"}
                        className="searchInput form-control bg-white-sand border-0 search-input" />
                    {searchPat.length > 0 ?
                        <Button onClick={clearSearchInput} className="clearBtn btn-clear btn p-0">
                            <CustomImg src="icon_clear.svg" alt="Clear Icon" />
                        </Button>
                        : ''}
                </div>
            </div>
        </div>
    </React.Fragment>);

}

export default PatientSearchbox;