import { connect } from 'react-redux';
import AppointmentlistWrapper from '../../../Components/PageContainer/Dashboard/AppointmentlistWrapper';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {

    return {
        appointments: state.appointment.appointments?.data,
        appointmentMeta: state.appointment.appointments?.meta,
        physicians: state.physician.physicians,
        appointmentStatus: state.appointment.appointmentStatus,
        appointmentAdd: state.appointment.appointmentAdd,
        appointmentCancel: state.appointment.appointmentCancel,
        userInfo: state.common.userInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    getAppointmentList: (appointment_date, physicians, pageInfo) => dispatch(actionCreators.appointmentList(appointment_date, physicians, pageInfo)),
    getAppointmentStatus: (appointmentId) => dispatch(actionCreators.appointmentStatus(appointmentId)),
    getPhysicianList: () => dispatch(actionCreators.physicianList()),
    getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentlistWrapper);
