import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../../Components/Layout/Header';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    // console.log("Header TaskReducer ",TaskReducer);        
    return {
        physicians: state.physician.physicians,
        patientAdd: state.patient.patientAdd,
        userInfo: state.common.userInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    //getPhysicianList: () => dispatch(actionCreators.physicianList()),
    setSearchTxtPatient: (serchTxt) => dispatch(actionCreators.setSearchTxtPatient(serchTxt)),
    getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
    currentUserSignout:()=>dispatch(actionCreators.currentUserSignout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));