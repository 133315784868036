import React, { useState, useLayoutEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Appointmentlist from "./Appointmentlist";
import momentTZ from "moment-timezone";

const AppointmentlistWrapper = (props) => {
  const [tmpAppointments, setTmpAppointments] = useState([]);
  const [pageInfo, setPageInfo] = useState({ ...props.appointmentMeta });
  const [startDate, setStartDate] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasPagination, setHasPagination] = useState(false);
  const [updatedArr, setUpdatedArr] = useState(false);

  const isGreaterThanToday = (date) =>
    momentTZ(date).tz("America/Chicago").format("MM/DD/YYYY") ===
      momentTZ().tz("America/Chicago").format("MM/DD/YYYY") ||
    momentTZ(date).tz("America/Chicago").format("MM/DD/YYYY") >
      momentTZ().tz("America/Chicago").format("MM/DD/YYYY");

  useLayoutEffect(() => {
    setTmpAppointments([]);
  }, []);

  const constructState = useCallback(() => {
    if (!updatedArr) {
      if (props.appointments && props.appointments.length > 0) {
        if (!hasPagination) {
          setTmpAppointments(props.appointments);
        }
        if (hasPagination) {
          const currAppArr = [...tmpAppointments, ...props.appointments];
          // if (
          //   props?.appointmentMeta?.last_page >
          //   props?.appointmentMeta?.current_page
          // ) {

            setTmpAppointments((prevProps) => {
              if (!_.isEqual(prevProps, currAppArr)) {
                return [...prevProps, ...props.appointments];
              }
            });
          // }
          if (
            props?.appointmentMeta?.last_page ===
            props?.appointmentMeta?.current_page
          ) {
            // setTmpAppointments((prevProps) => {
            //   if (!_.isEqual(prevProps, currAppArr)) {
            //     return [...prevProps, ...props.appointments];
            //   }
            // });
            setUpdatedArr(true);
          }
        }
      } else {
        setTmpAppointments([]);
      }
    }
  }, [
    tmpAppointments,
    props.appointmentMeta,
    props.appointments,
    hasPagination,
    updatedArr,
  ]);

  useLayoutEffect(() => {
    if (props.appointments && props.appointments.length > 0) {
      constructState();
    } else {
      setTmpAppointments([]);
    }
  }, [props.appointmentMeta, props.appointments]);

  useLayoutEffect(() => {
    if (
      props.appointmentMeta?.last_page > props.appointmentMeta?.current_page
    ) {
      setHasPagination(true);
    }
    setPageInfo(props.appointmentMeta);
  }, [props.appointmentMeta]);

  useLayoutEffect(() => {
    if (startDate) {
      setPageNumber(1);
      setTmpAppointments([]);
      setHasPagination(false);
      setUpdatedArr(false);
    }
  }, [startDate]);

  useLayoutEffect(() => {
    if (pageInfo && pageInfo.page) {
      if (pageInfo?.last_page > pageInfo?.current_page) {
        setHasPagination(true);
      }
      setPageNumber(pageInfo.page);
    }
  }, [pageInfo]);

  const loadMorePastApp = useCallback(async () => {
    if (startDate) {
      setPageNumber(pageNumber + 1);
      const pageInfo = {
        page: pageNumber + 1,
        limit: 10,
      };

      await props.getAppointmentList(startDate, props.physicians, pageInfo);
    }
  }, [startDate, pageNumber, props]);

  const loadMoreSection = useCallback(
    (startDate) => {
      setStartDate(startDate);

      const button = (
        <>
          {
            tmpAppointments?.length >= 10 && !isGreaterThanToday(startDate) && (
              <div className="pt-2 text-center">
                <Button
                  disabled={pageInfo?.current_page >= pageInfo?.last_page}
                  onClick={() => loadMorePastApp()}
                  className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
                >
                  Load more
                </Button>
              </div>
            )
          }
        </>
      );

      return button;
    },
    [pageInfo, loadMorePastApp, tmpAppointments]
  );

  const handleDateChange = async (date) => {
    let pageDet = { page: 1, limit: 10 };
    if (
      momentTZ(date).tz("America/Chicago").format("MM/DD/YYYY") ===
      momentTZ().tz("America/Chicago").format("MM/DD/YYYY")
    ) {
      pageDet = { page: 1, limit: 1000 };
    }

    if (
      momentTZ(date).tz("America/Chicago").format("MM/DD/YYYY") >
      momentTZ().tz("America/Chicago").format("MM/DD/YYYY")
    ) {
      pageDet = {};
    }

    await props.getAppointmentList(date, props.physicians, pageDet);
  };

  return (
    <>
      {pageNumber && (
        <Appointmentlist
          {...props}
          appointments={tmpAppointments}
          loadMoreSection={loadMoreSection}
          pageNumber={pageNumber}
          handleDateChange={handleDateChange}
        />
      )}
    </>
  );
};

export default React.memo(AppointmentlistWrapper);
