import React, {Component} from "react";
import Main from "../../Components/Main/Main";
import {CustomSignIn} from "./SignIn";

class AuthWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ""
        };
        this.updateUsername = this.updateUsername.bind(this);
    }

    updateUsername(newUsername) {
        this.setState({username: newUsername});
    }

    render() {
        const url = window.location.href;
        const isConferenceUrl =
            url.indexOf("/v/") > -1 ||
            url.indexOf("/a/") > -1 ||
            url.indexOf("/e") > -1;

        return (
            <div className="flex-1 h-100">

                {isConferenceUrl ? "" :
                    <CustomSignIn
                        authState={this.props.authState}
                        updateUsername={this.updateUsername}
                        onStateChange={this.props.onStateChange}
                    />
                }

                <Main authState={this.props.authState} onStateChange={this.props.onStateChange}/>
            </div>
        );
    }
}

export default AuthWrapper;
