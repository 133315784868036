import {Auth} from 'aws-amplify';
import {ConferenceService} from '../data/services/conferenceService';

const brands = {
    sportsmed: {
        imageUrl: "sportsmed-logo.svg",
        imageAlt: "SportsMed Connect"
    },
    mouse: {
        imageUrl: "mouse-logo.png",
        imageAlt: "Mouse Clinic"
    },
    aoc: {
        imageUrl: "aoc-logo.png",
        imageAlt: "Alabama Orthopaedic Clinic"
    },
    sportslinkmd: {
        imageUrl: "sportslinkmd-logo.png",
        imageAlt: "SportsLinKMD"
    },
    urgentcare: {
        imageUrl: "sportsmed-logo.svg",
        imageAlt: "Urgent Care Clinic"
    }
};
brands.default = brands.sportsmed;

export default class Branding {
    logoImageUrl = null;

    static async getBrandingFromUser(user) {
        if (user.attributes && user.attributes["custom:Practice"]) {
            return brands[user.attributes["custom:Practice"].toLowerCase()];
        }
        return null;
    }

    static async getBrandingFromConference(appointment) {
        if (appointment && appointment.clinic) {
            return brands[appointment.clinic.toLowerCase()];
        }
        if (appointment && appointment.clinicName) {
            return brands[appointment.clinicName.toLowerCase()];
        }
    }

    static async getBrandingFromUrl() {
        const m = window.location.pathname.match(/\/auth\/(.*)$/i);
        if (m) {
            return brands[m[1].toLowerCase()];
        }
        return null;
    }

    static async getBranding(appointment = null) {
        const b = new Branding();
        let brand = null;

        // Get the brand from the current appointment, or the authenticated user; otherwise try to derive it from the url

        if (appointment) {
            brand = await Branding.getBrandingFromConference(appointment);
        }

        if (!brand) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                brand = await Branding.getBrandingFromUser(user);
            } catch (err) {
                brand = await Branding.getBrandingFromUrl();
            }
        }

        if (!brand) {
            const x = localStorage.getItem("CURRENT_BRAND");
            if (x) {
                try {
                    brand = JSON.parse(x);
                } catch (e) {
                }
            }
        }

        if (!brand) {
            brand = brands.default;
        }

        localStorage.setItem("CURRENT_BRAND", JSON.stringify(brand));

        b.logoImageUrl = brand.imageUrl;
        b.logoImageAlt = brand.imageAlt;

        return b;
    }
}
