import {
  COMMON_SET_USERINFO, APPLOCATION_INFO_SUCCESS, APPLOCATION_INFO_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
  userInfo: [],
  appliationInfo: []
};

export const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case COMMON_SET_USERINFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case APPLOCATION_INFO_SUCCESS:
    case APPLOCATION_INFO_ERROR:   
      return {
        ...state,
        appliationInfo: action.payload
      };
    default:
      return state;
  }
};
