import { connect } from 'react-redux';
import Users from '../../../Components/PageContainer/Users/User';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    return {
        users: state.user.users,
        userAdd: state.user.userAdd,
        userUpdate: state.user.userUpdate,
        teams: state.user.teams,
        userDeactivate: state.user.userDeactivate,
        userActivate: state.user.userActivate,
        userInfo: state.common.userInfo
    };
};
const mapDispatchToProps = dispatch => ({
    getUsers: (filterBy, sortOn, sortBy, practice) => dispatch(actionCreators.userList(filterBy, sortOn, sortBy, practice)),
    getTeams: () => dispatch(actionCreators.teamList()),
    postUser: (user) => dispatch(actionCreators.addUser(user)),
    deactivateAccount: (user, isLoggedInUser) => dispatch(actionCreators.deactivateAccount(user, isLoggedInUser)),
    activateAccount: (user) => dispatch(actionCreators.activateAccount(user)),
    //getCurrentUserInfo: () => dispatch(actionCreators.getCurrentUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
