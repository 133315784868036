import * as actionTypes from './actionTypes';
import {Auth} from 'aws-amplify';
import Storage from '../../Providers/Storage';
import {history} from '../../Providers/History';
import {UserService} from '../../data/services/userService';

//import {CommonService} from '../../data/services/commonService';


export const clearData = () => dispatchEvent => {
    dispatchEvent({type: actionTypes.CLEAR_DATA});
};

export const getCurrentUserInfo = () => dispatchEvent => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    return getLoginUserinfo(dispatchEvent);
};

async function getLoginUserinfo(dispatchEvent) {
    let userInfo = await Auth.currentUserInfo();

    if (userInfo) {
        try {
            const me = await UserService.whoAmI();
            userInfo = Object.assign(userInfo, me);
            userInfo.userId = userInfo.id;
        } catch (e) {
            console.warn(e);
            userInfo = {attributes: {}};
        }
    }

    dispatchEvent({
        type: actionTypes.COMMON_SET_USERINFO,
        payload: userInfo,
    });

    return userInfo;
}


export const currentUserSignout = () => dispatchEvent => {
    userSignout(dispatchEvent);
};

async function userSignout(dispatchEvent) {
    await Auth.signOut();
    Storage.remove("username");
    Storage.remove("userpractice");
    history.push({
        pathname: "/"
    });
    // window.location.reload();
}
