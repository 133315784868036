import Http from "../../Services/Http";
import {
  URL_USERS,
  APINAME_USERS,
  APINAME_TEAMS,
  URL_TEAMS,
  URL_WHOAMI,
} from "../../Containers/Common/APIUtils";
const api = new Http({});

//Get Users API call
const getUserList = async (filterBy, sortOn, sortBy, practice = "") => {
  let apiName = APINAME_USERS;
  let path = URL_USERS;
  //let apiName = 'patientsApi';
  //let path = '/patients';

  let queryParams = {
    roles: (filterBy.searchrole || []).join(","),
    teams: (filterBy.searchteam || []).join(","),
    search: filterBy.searchuser,
    includeInactive: filterBy.searchinactive,
    sort: sortOn,
    sortDirection: sortBy,
    practice: practice ? practice : "",
  };
  // fetch user from api
  let response = await api.get(apiName, path, queryParams);
  // fetch user from static json file
  // let response = data.users[0];
  return response;
};

// Add user API call
const postUser = async (user) => {
  let apiName = APINAME_USERS;
  let path = URL_USERS;
  let response = "";
  if (user !== "") {
    response = await api.post(apiName, path, user);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
};

// Update user API call
const putUser = async (user) => {
  let apiName = APINAME_USERS;
  let path = URL_USERS + "/" + user.id;
  let response = "";
  if (user !== "") {
    response = await api.put(apiName, path, user);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
};

//Get Users API call
const getTeamList = async () => {
  let apiName = APINAME_TEAMS;
  let path = URL_TEAMS;

  // fetch team from api
  let response = await api.get(apiName, path);
  //console.log(response);
  // fetch team from static json file
  //let response = data.teams[0];

  return response;
};

const postTeam = async (team) => {
  let apiName = APINAME_TEAMS;
  let path = URL_TEAMS;
  let response = "";
  if (team !== "") {
    response = await api.post(apiName, path, team);
  }
  //console.log(response);
  //response = data.postTeam[0].data;
  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
};

const putDeactiveAccount = async (userid) => {
  let apiName = APINAME_USERS;
  let path = URL_USERS + "/" + userid + "/deactivate";

  let response = "";
  if (userid !== "") {
    response = await api.put(apiName, path);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
};

const putActiveAccount = async (userid) => {
  let apiName = APINAME_USERS;
  let path = URL_USERS + "/" + userid + "/activate";

  let response = "";
  if (userid !== "") {
    response = await api.put(apiName, path);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
};

const whoAmI = async () => {
  let apiName = APINAME_USERS;
  let path = URL_WHOAMI;
  let response = await api.get(apiName, path);
  return response;
};

export const UserService = {
  getUserList,
  postUser,
  getTeamList,
  postTeam,
  putUser,
  putDeactiveAccount,
  putActiveAccount,
  whoAmI,
};
