import { SET_SEARCHTXT_PATIENT, PATIENT_LIST_SUCCESS, PATIENT_LIST_ERROR, PATIENT_ADD_SUCCESS, PATIENT_ADD_ERROR, PATIENT_UPDATE_SUCCESS, PATIENT_UPDATE_ERROR } from '../actions/actionTypes';

const INITIAL_STATE = {
  serchtxtpatient: [],
  patients: [],
};

export const patientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SEARCHTXT_PATIENT:
      return {
        ...state,
        serchtxtpatient: action.payload
      };
    case PATIENT_LIST_SUCCESS:
    case PATIENT_LIST_ERROR:
      return {
        ...state,
        patients: action.payload
      };
    case PATIENT_ADD_SUCCESS:
    case PATIENT_ADD_ERROR:
      return {
        ...state,
        patientAdd: action.payload
      };
    case PATIENT_UPDATE_SUCCESS:
    case PATIENT_UPDATE_ERROR:    
      return {
        ...state,
        patientUpdate: action.payload
      };

    default:
      return state;
  }
};
