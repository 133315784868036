import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PatientSearchbox from '../../../Components/Layout/PatientSearchbox';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {
    // console.log("Header TaskReducer ",TaskReducer);        
    return {

    };
};

const mapDispatchToProps = dispatch => ({
    setSearchTxtPatient: (serchTxt) => dispatch(actionCreators.setSearchTxtPatient(serchTxt)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientSearchbox));