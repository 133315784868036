import { connect } from 'react-redux';
import ScheduleApptModal from '../../../Components/PageContainer/Patient/ScheduleApptModal';
import * as actionCreators from '../../../store/actions/index';

const mapStateToProps = state => {        
    return {
        //pastappointments: state.appointment.pastappointments
        physicians: state.physician.physicians,
        physician_availabilities: state.physician.physician_availabilities,
        appointmentAdd: state.appointment.appointmentAdd,
        appointmentCancel:state.appointment.appointmentCancel,
    };
};

const mapDispatchToProps = dispatch => ({
    getPastAppointments: (filter) => dispatch(actionCreators.pastAppointmentList(filter)),
    postAppointment: (appointment) => dispatch(actionCreators.submitAppointment(appointment)),
    physicianAvailability: (appointmentDate, physicianId) => dispatch(actionCreators.physicianAvailability(appointmentDate, physicianId)),
    cancelAppointment:(appointmentId)=>dispatch(actionCreators.cancelAppointment(appointmentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleApptModal);