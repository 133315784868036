import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import UserModal from '../../../Components/PageContainer/Users/UserModal';

const mapStateToProps = state => {
    return {
        userAdd: state.user.userAdd,
        userUpdate: state.user.userUpdate,
        teams:state.user.teams,
        teamAdd:state.user.teamAdd,
        loggedInUser: state.common.userInfo,

    };
};

const mapDispatchToProps = dispatch => ({
    postUser: (user) => dispatch(actionCreators.addUser(user)),
    postTeam:(team) => dispatch(actionCreators.addTeam(team)),
    putUser: (user, removeSelfAsAdmin) => dispatch(actionCreators.updateUser(user, removeSelfAsAdmin)),
    getTeams: () => dispatch(actionCreators.teamList())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);