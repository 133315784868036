import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

class AddAttendee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
    };
    let fields = this.state.fields;
    fields["attendee_name"] = null;
    fields["attendee_phone"] = null;
    fields["attendee_email"] = null;

    this.setState({
      fields,
    });
  }
  componentWillMount() {
    let errors = {};
    errors["attendee_name"] = "";
    errors["attendee_phone"] = "";
    errors["attendee_email"] = "";
    this.setState({
      errors: errors,
    });
    let attendee = this.props.attendee;
    if (attendee) {
      let fields = this.state.fields;
      fields["attendee_name"] = attendee["attendee_name"];
      fields["attendee_phone"] = attendee["attendee_phone"];
      fields["attendee_email"] = attendee["attendee_email"];
      this.setState({ fields });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      let errors = {};
      errors["attendee_name"] = "";
      errors["attendee_phone"] = "";
      errors["attendee_email"] = "";
      if (this.props.errors.attendee_phone[this.props.index]) {
        errors["attendee_phone"] = this.props.errors.attendee_phone[this.props.index];
      }
      if (this.props.errors.attendee_name[this.props.index]) {
        errors["attendee_name"] = this.props.errors.attendee_name[this.props.index];
      }
      if (this.props.errors.attendee_email[this.props.index]) {
        errors["attendee_email"] = this.props.errors.attendee_email[this.props.index];
      }
      this.setState({
        errors: errors,
      });
    }
  }
  inputChange(e) {
    let fields = this.state.fields;
    if (!String(e.target.value).startsWith(" ")) {
    const val = String(e.target.value).replace(/  +/g, " ");
    fields[e.target.name] = val;
    this.setState({
      fields,
    });
    this.props.handelAttendee(this.state.fields, this.props.index);
   } else {
    const val = String(e.target.value).replace(/ +/g, "");
    fields[e.target.name] = val;
    this.setState({
      fields,
    });
    this.props.handelAttendee(this.state.fields, this.props.index);
   }
  }
  inputChangedHandler = (values) => {
    let fields = this.state.fields;
    fields["attendee_phone"] = values.value;
    this.setState({
      fields,
    });
    this.props.handelAttendee(this.state.fields, this.props.index);
  };
  render() {
    return (
      <>
        <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
          <div className="form-group modal-col">
            <TextField
              type="text"
              label="Attendee Name"
              id="handelAttendee"
              name="attendee_name"
              value={this.state.fields.attendee_name}
              onChange={this.inputChange.bind(this)}
              className="w-100"
              error={this.state.errors.attendee_name !== "" ? true : false}
              helperText={this.state.errors.attendee_name}
            />
          </div>

          <div className="form-group modal-col">
            <NumberFormat
              label="Attendee Phone"
              customInput={TextField}
              format="(###) ###-####"
              className="w-100"
              mask="_"
              id="attendee_phone"
              name="attendee_phone"
              onValueChange={this.inputChangedHandler}
              value={this.state.fields.attendee_phone}
              error={this.state.errors.attendee_phone !== "" ? true : false}
              helperText={this.state.errors.attendee_phone}
            />
          </div>
        </div>

        <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
          <div className="modal-col"></div>
          <div className="form-group modal-col">
            <TextField
              type="text"
              label="Attendee Email"
              id="email"
              name="attendee_email"
              value={this.state.fields.attendee_email}
              onChange={this.inputChange.bind(this)}
              error={this.state.errors.attendee_phone !== "" ? true : false}
              className="w-100"
              error={
                (this.state.fields.attendee_phone === "" &&
                  this.state.errors.attendee_phone !== "") ||
                this.state.errors.attendee_email !== ""
                  ? true
                  : false
              }
              helperText={this.state.errors.attendee_email}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AddAttendee;
