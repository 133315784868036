import Http from '../../Services/Http';
import {
    URL_APPOINTMENT, APINAME_APPOINTMENT, URL_APPOINTMENT_CANCEL, URL_APPOINTMENT_STATUS, APINAME_VISITS, URL_PAST_APPOINTMENT
} from '../../Containers/Common/APIUtils';
import _ from "lodash";

var moment = require('moment');
const api = new Http({});

const getAppointmentList = async (selected_date, pageInfo) => {
    let apiName = '';
    let path = '';
    let begin = '';
    let end = '';
    let queryParams = {};
    let response = {};
    let response_futureappt = {};
    let response_pastappt = {};
    // selected date is greater than current date then make call of future api

    if (moment(selected_date).format('MM/DD/YYYY') >= moment().format('MM/DD/YYYY')) {
        apiName = APINAME_APPOINTMENT;
        path = URL_APPOINTMENT;
        begin = moment(selected_date).startOf('day').format();

        if (moment(selected_date).format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')) {
            begin = moment().subtract(30, 'm').format();
        }

        end = moment(selected_date).endOf('day').format();
        queryParams = {
            "begin": begin,
            "end": end
        };
        // fetch Appointment from api
        response_futureappt = await api.get(apiName, path, queryParams);
        response = response_futureappt;
    }
    // selected date is less than current date then make call of past api
    if (moment(selected_date).format('MM/DD/YYYY') <= moment().format('MM/DD/YYYY')) {
        apiName = APINAME_VISITS;
        path = URL_PAST_APPOINTMENT('');
        queryParams = _.isEmpty(pageInfo) ? {
            //"begin": moment().subtract(1, 'days').format('YYYY-MM-DD'),
            //"end": moment().subtract(1, 'days').format('YYYY-MM-DD'),
            "asc": true,
            "begin": moment(selected_date).format('YYYY-MM-DD'),
            "end": moment(selected_date).format('YYYY-MM-DD'),
        }: { 
            "asc": true,
            "begin": moment(selected_date).format('YYYY-MM-DD'),
            "end": moment(selected_date).format('YYYY-MM-DD'),
            ...pageInfo
        } ;
        // fetch Appointment from api
        response_pastappt = await api.get(apiName, path, queryParams);
        response = response_pastappt;
    }
    if (response_futureappt.data && response_futureappt.data !== undefined && response_pastappt.data && response_pastappt.data !== undefined) {
        let extingapp = [];
        response_pastappt.data = response_pastappt.data.filter((data, key) => {
            if (extingapp.indexOf(data.id) === -1) {
                extingapp.push(data.id);
                return {data , meta: response_pastappt.meta };
            }
            return null;
        });
        response_futureappt.data = response_futureappt.data.filter((data, key) => {
            if (extingapp.indexOf(data.id) === -1) {
                extingapp.push(data.id);
                return {data , meta: response_pastappt.meta };
            }
            return null;
        });
        response.data = [...response_futureappt.data, ...response_pastappt.data];
    }

    console.log("response.data ", response.data);
    return response;
};


const getPastAppointmentList = async (filter, pageInfo) => {

    let apiName = APINAME_VISITS;
    let path = URL_PAST_APPOINTMENT(filter);


    let queryParams = {
        //"begin": moment().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD'),
        //"end": moment().subtract(1, 'days').format('YYYY-MM-DD'),
        // "asc": false,
        // "limit": 10,
    };

    if (Object.keys(pageInfo).length > 0) {
        queryParams = {
            "page": pageInfo.page,
            "asc": false,
            "limit": 10,
        }
    } else {
        queryParams = {
            "asc": false,
            "limit": 10,
        }
    }

    // fetch Appointment from api
    let response = await api.get(apiName, path, queryParams);

    // fetch Appointment from static json file
    // let response = '';
    // if (filter === 'completed') {
    //   response = data.pastappointment.completed;
    // } else if (filter === 'missed') {
    //   response = data.pastappointment.missed;
    // } else if (filter === 'failed') {
    //   response = data.pastappointment.failed;
    // } else {
    //   response = data.pastappointment.all;
    // }
    return response;
};

const postAppointment = async (data) => {
    let apiName = APINAME_APPOINTMENT;
    let path = URL_APPOINTMENT;
    let response = "";
    if (data !== '') {
        if (data.appointmentId && data.appointmentId !== undefined) {
            await api.put(APINAME_APPOINTMENT, URL_APPOINTMENT_CANCEL(data.appointmentId), {});
        }
        response = await api.post(apiName, path, data);
    }

    // fetch data from static json file
    //response = data.postPatient;
    return response;
}

const postCancelAppointment = async (appointmentId) => {
    let apiName = APINAME_APPOINTMENT;
    let path = URL_APPOINTMENT_CANCEL(appointmentId);
    let data = {};
    let response = "";

    response = await api.put(apiName, path, data);


    // fetch data from static json file
    //response = data.postPatient;
    return response;
}

const getAppointmentStatus = async (appointmentId) => {
    let apiName = APINAME_APPOINTMENT;
    let path = URL_APPOINTMENT_STATUS(appointmentId);
    //let data = {};
    let response = "";
    response = await api.get(apiName, path, '', false, false);
    // fetch data from static json file
    //response = data.appointmentStatus[0];
    //console.log(response);
    return response;
}

export const AppointmetService = {
    getAppointmentList, getPastAppointmentList, postAppointment, postCancelAppointment, getAppointmentStatus
};
