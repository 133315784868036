import React from 'react';
import SvgPath from "../../../Asset/images/svg-sprite.svg";
import PropTypes from 'prop-types';

const Svgicon = ({ name, color, size }) => (
    <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
      <use xlinkHref={`${SvgPath}#icon-${name}`} />
    </svg>
  );
  
  Svgicon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
  };
  
  export default Svgicon;
