import "date-fns";
import React from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AddAttendee from "./addAttendee";
import { ConsoleLogger } from "@aws-amplify/core";
import momentTZ from 'moment-timezone';
var moment = require("moment");
class ScheduleApptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDatetime: new Date(),
      appointmentDate: new Date(),
      appointmentTime: "",
      scheduleInfo: "",
      fields: {},
      errors: {},
      isAllowCancel: false,
      physician_availabilities: [],
      attendee_limit: 1,
      attendees: [0],
      attendee: [{ attendee_name: "", attendee_phone: "", attendee_email: "" }],
    };

    let fields = this.state.fields;
    fields["patientId"] = "";
    fields["patientName"] = "";
    fields["patientPhone"] = "";
    fields["patientBirthDate"] = "";
    fields["physician"] = "";
    fields["resourceId"] = "";
    fields["appointmentDate"] = null;
    fields["procedureDesc"] = "";

    fields["procedureDate"] = null;
    fields["isAllowCancel"] = false;
    fields["appointmentType"] = "";
    fields["appointmentTime"] = "";
    fields["attendee_name"] = [];
    fields["attendee_phone"] = [];
    fields["attendee_email"] = [];
    //fields['attendee'] = [{ 'attendee_name': '', 'attendee_phone': '', 'attendee_email': '' }];

    this.setState({
      fields,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appointmentAdd !== this.props.appointmentAdd) {
      if (this.props.appointmentAdd) {
        if (this.props.appointmentAdd.status === "success") {
          this.props.closePopup();
        }
        //console.log(this.props.patientAdd);
      }
    }

    if (prevProps.appointmentCancel !== this.props.appointmentCancel) {
      if (this.props.appointmentCancel) {
        if (this.props.appointmentCancel.status === "success") {
          this.props.closePopup();
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var pa = nextProps.physician_availabilities;
    if (pa && pa !== prevState.physician_availabilities) {
      if (pa.length > 0 && nextProps.scheduleInfo) {
        var temp = [];
        var dateFound = false;
        const d2 = moment(nextProps.scheduleInfo.startDate).format();

        const found = pa.find((p) => moment(d2).isSame(moment(p.start).format()));

        if (found) {
          dateFound = true;
        } else {
          pa.forEach((a) => {
            const d1 = moment(a.start).format();

            if (moment(d1).isAfter(moment(d2)) && !dateFound) {
              temp.push({ start: nextProps.scheduleInfo.startDate });
              temp.push(a);
              dateFound = true;
            } else {
              temp.push(a);
            }
          });
          pa = temp;
        }
      }
      return {
        physician_availabilities: pa,
      };
    }
  }

  componentDidMount() {
    if (this.props.scheduleInfo) {
      //console.log(this.props.scheduleInfo);
      let fields = this.state.fields;
      fields["appointmentId"] = this.props.scheduleInfo.id;
      fields["patientId"] = this.props.scheduleInfo.pid;
      fields["patientName"] = this.props.scheduleInfo.patientName;
      fields["patientPhone"] = this.props.scheduleInfo.patientPhone;
      fields["patientBirthDate"] = new Date(this.props.scheduleInfo.patientDOB);
      fields["physician"] = this.props.scheduleInfo.physician;
      fields["resourceId"] = this.props.scheduleInfo.physicianId;
      fields["procedureDesc"] = this.props.scheduleInfo.procedureDesc;
      fields["procedureDate"] = null;
      if (
        this.props.scheduleInfo.procedureDate !== undefined &&
        this.props.scheduleInfo.procedureDate !== "NA" &&
        this.props.scheduleInfo.procedureDate !== "" &&
        this.props.scheduleInfo.procedureDate !== "0000-00-00 00:00:00"
      ) {
        fields["procedureDate"] = new Date(this.props.scheduleInfo.procedureDate);
      }
      fields["appointmentType"] = this.props.scheduleInfo.appointmentType;
      fields["appointmentDate"] = null;
      if (this.props.scheduleInfo.startDate !== "") {
        fields["appointmentDate"] = new Date(this.props.scheduleInfo.startDate);
        fields["appointmentTime"] = moment(this.props.scheduleInfo.startDate).format();
        //this.appointmentDateChange(this.props.scheduleInfo.startDate);
        this.getAvaliableTimeSlot();
      }
      fields["isAllowCancel"] = false;
      if (this.props.scheduleInfo.startDate) {
        if (moment(this.props.scheduleInfo.startDate).isAfter(moment())) {
          fields["isAllowCancel"] = true;
        }
      }
      if (this.props.scheduleInfo.attendee) {
        fields["attendee"] = {};
        fields["attendee"] = this.props.scheduleInfo.attendee;
        fields["attendee_name"] = this.props.scheduleInfo.attendee.map((x) => x.attendee_name);
        fields["attendee_email"] = this.props.scheduleInfo.attendee.map((x) => x.attendee_email);
        fields["attendee_phone"] = this.props.scheduleInfo.attendee.map((x) => x.attendee_phone);
        this.setState({ attendees: this.props.scheduleInfo.attendee });
      } else {
        fields["attendee"] = [{ attendee_name: "", attendee_phone: "", attendee_email: "" }];
      }
      this.setState({ fields });
    } else {
      let fields = this.state.fields;
      fields["attendee"] = [{ attendee_name: "", attendee_phone: "", attendee_email: "" }];
    }

    if (this.props.patientInfo) {
      let fields = this.state.fields;
      fields["patientId"] = this.props.patientInfo.id;
      fields["patientName"] =
        this.props.patientInfo.lastName + ", " + this.props.patientInfo.firstName;
      fields["patientPhone"] = this.props.patientInfo.phone;
      fields["patientBirthDate"] = new Date(this.props.patientInfo.birthDate);
      fields["physician"] = this.props.patientInfo.physician;
      //fields['resourceId'] = this.props.patientInfo.physicianId;
      //fields['resourceId'] = '';
      fields["appointmentDate"] = null;
      fields["procedureDesc"] = "";
      if (this.props.patientInfo.procedureDesc && this.props.patientInfo.procedureDesc !== "NA") {
        fields["procedureDesc"] = this.props.patientInfo.procedureDesc;
      }
      fields["procedureDate"] = null;
      fields["isAllowCancel"] = false;
      if (
        this.props.patientInfo.procedureDate !== undefined &&
        this.props.patientInfo.procedureDate !== "NA" &&
        this.props.patientInfo.procedureDate !== "" &&
        this.props.patientInfo.procedureDate !== "0000-00-00 00:00:00"
      ) {
        fields["procedureDate"] = this.props.patientInfo.procedureDate;
      }
      this.getAvaliableTimeSlot();

      // when only provider in list, it default selected
      let resourceId = "";
      if (this.props.physicians) {
        if (this.props.physicians.length === 1) {
          resourceId = this.props.physicians[0].id;
        }
      }
      fields["resourceId"] = resourceId;

      this.setState({ fields });
    }
  }

  appointmentDateChange = (date) => {
    let fields = this.state.fields;
    fields["appointmentDate"] = date;
    this.setState({
      fields,
    });
    if (moment(date).isValid()) {
      this.getAvaliableTimeSlot();
    }
  };
  appointmentTimeChange = (time) => {
    let fields = this.state.fields;
    fields["appointmentTime"] = time;
    this.setState({
      fields,
    });
  };
  async getAvaliableTimeSlot() {
    if (
      this.state.fields["appointmentDate"] !== undefined &&
      this.state.fields["appointmentDate"] !== "" &&
      this.state.fields["appointmentDate"] !== null &&
      this.state.fields["resourceId"] !== undefined &&
      this.state.fields["resourceId"] !== ""
    ) {
      let appointmentDate = moment(this.state.fields["appointmentDate"]).format();
      let physicianId = this.state.fields["resourceId"];
      await this.props.physicianAvailability(appointmentDate, physicianId);
    }
  }
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if(e.target.name === "procedureDesc"){
      if(e.target.value.length < 255) {
        this.setState({
          fields,
        });
      }
    } else {
      this.setState({
        fields,
      });
    }
    if (e.target.name === "resourceId") {
      this.getAvaliableTimeSlot();
    }
  }
  handelAttendee(attendee, index) {
    let fields = this.state.fields;
    fields['attendee'][index] = [];
    //fields['attendee_name'].push({index:attendee.attendee_name});
    fields['attendee'][index]["attendee_name"] = attendee.attendee_name;
    fields['attendee'][index]["attendee_email"] = attendee.attendee_email;
    fields['attendee'][index]["attendee_phone"] = attendee.attendee_phone;
    this.setState({ fields });
  }
  async submitscheduleApptForm(e) {
    e.preventDefault();
    if (this.validateForm() && this.validTime()) {
      let fields = {};
      fields = this.state.fields;
      let begin =
        moment(fields["appointmentDate"]).format("MM/DD/YYYY") +
        " " +
        moment(fields["appointmentTime"]).format("HH:mm:ss");

      fields["begin"] = moment(begin).format();
      fields["end"] = moment(begin).add(10, "minutes").format();
      fields["patientBirthDate"] = moment(fields["patientBirthDate"]).format("MM/DD/YYYY");
      await this.props.postAppointment(fields);
      fields = {};
      //this.setState({ fields: fields });
      //this.props.closePopup();
    }
  }

  procedureDateChange = (date) => {
    let fields = this.state.fields;
    fields["procedureDate"] = date;
    this.setState({
      fields,
    });
  };
  validateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;
    if (!fields["appointmentType"]) {
      formIsValid = false;
      errors["appointmentType"] = "This field is required";
    }
    if (!fields["resourceId"]) {
      formIsValid = false;
      errors["resourceId"] = "This field is required";
    }

    if (!fields["appointmentDate"]) {
      formIsValid = false;
      errors["appointmentDate"] = "This field is required";
    } else if (!moment(fields["appointmentDate"]).isValid()) {
      formIsValid = false;
      errors["appointmentDate"] = "This field is invalid";
    }

    if (!fields["appointmentTime"]) {
      formIsValid = false;
      errors["appointmentTime"] = "This field is required";
    }
    if (fields["procedureDate"] && !moment(fields["procedureDate"]).isValid()) {
      formIsValid = false;
      errors["procedureDate"] = "This field is invalid";
    }
    errors["attendee_name"] = [];
    errors["attendee_phone"] = [];
    errors["attendee_email"] = [];
    this.state.fields.attendee.forEach((val, index) => {
      //console.log(fields['attendee_name'][index]);
      //console.log('email:'+fields['attendee_email'][index]);
      ///console.log('phone:'+fields['attendee_phone'][index]);
      if (
        val.attendee_name === "" &&
        (val.attendee_email !== "" || val.attendee_phone !== "")
      ) {
        errors["attendee_name"][index] = "";
        formIsValid = false;
        errors["attendee_name"][index] = "This field is required";
      } else if (
        val.attendee_name !== "" &&
        val.attendee_email === "" &&
        val.attendee_phone === ""
      ) {
        formIsValid = false;
        errors["attendee_phone"][index] = "Phone # or email are required.";
      }

      if (val.attendee_phone && val.attendee_phone.length !== 10) {
        formIsValid = false;
        errors["attendee_phone"][index] = "This field is invalid";
      }
      if (val.attendee_email && val.attendee_email !== "") {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(val.attendee_email)) {
          formIsValid = false;
          errors["attendee_email"][index] = "This field is invalid";
        }
      }
    });
    this.setState({
      errors: errors,
    });
    this.forceUpdate();
    if (formIsValid) {
      let fields = this.state.fields;
      fields["attendee_name"] = fields["attendee_name"].filter((n) => n);
      fields["attendee_phone"] = fields["attendee_phone"].filter((n) => n);
      fields["attendee_email"] = fields["attendee_email"].filter((n) => n);
      this.setState({ fields: fields });
    }
    return formIsValid;
  }

  validTime() {
    const {appointmentTime} = this.state.fields;
   
    const currDT = momentTZ().tz('America/Chicago').format("YYYY-MM-DD HH:mm A");
    const appDT = momentTZ(appointmentTime).tz('America/Chicago').format("YYYY-MM-DD HH:mm A");
  
    const isValidTime = appDT > currDT;
    if(!isValidTime){
      let errors = this.state.errors;
      errors["appointmentTime"] = "This time has expired. Select another time.";
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors["appointmentTime"] = "";
      this.setState({ errors: errors });
    }
    return isValidTime;
  }

  async cancelAppointment() {
    if (
      this.state.fields["appointmentId"] !== undefined &&
      this.state.fields["appointmentId"] !== ""
    ) {
      let appointmentId = this.state.fields["appointmentId"];
      await this.props.cancelAppointment(appointmentId);
    }
  }

  addAttendee(e) {
    let fields = this.state.fields;

    if (fields["attendee"].length > 0) {
      fields["attendee"].push({
        attendee_name: "",
        attendee_phone: "",
        attendee_email: "",
      });
    } else {
      fields["attendee"] = [];
      fields["attendee"].push({
        attendee_name: "",
        attendee_phone: "",
        attendee_email: "",
      });
    }
    this.setState({ fields });
    this.forceUpdate();
  }

  render() {
    const { physician_availabilities } = this.state;
    const elem = document.querySelectorAll('input[name=appointmentDate]')[0];
    if(elem){
      elem.setAttribute('disabled', "true")
    }
    
    return (
      <div>
        <Modal
          className="modal-656"
          show={this.props.showPopup}
          onHide={this.props.closePopup}
          backdrop={"static"}
          keyboard={false}>
          <Modal.Header>
            <Modal.Title className="font-18 font-quicksand">Schedule Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              name="scheduleAppoitmentForm"
              onSubmit={this.submitscheduleApptForm.bind(this)}>
              <div className="schedule-appointment-detail">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <div className="flex-grow-1">
                      <span className="d-block text-dark-gray font-10">Patient</span>
                      {this.state.fields.patientName === ", " ? <span class="font-italic">PHI Removed</span> : this.state.fields.patientName}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="flex-grow-1">
                      <span className="d-block text-dark-gray font-10">DOB</span>
                      {this.state.fields.patientName === ", " ? null : <Moment className="" format="MM/DD/YYYY">{this.state.fields.patientBirthDate}</Moment> }
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="">
                      <span className="d-block text-dark-gray font-10">Physician</span>
                      {this.state.fields.physician}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                      <div className="form-group modal-col">
                        <TextField
                          type="text"
                          label="Procedure"
                          id="procedureDesc"
                          name="procedureDesc"
                          value={this.state.fields.procedureDesc}
                          onChange={this.handleChange.bind(this)}
                          className="w-100"
                        />
                      </div>

                      <div className="form-group position-relative modal-col">
                        {/*<label htmlFor="procedureDate">Procedure Date</label>
                                                 <DatePicker
                                                    className="form-control datepicker bg-transparent"
                                                    selected={this.state.fields.procedureDate}
                                                    name="procedureDate"
                                                    onChange={this.procedureDateChange}
                                                    dateFormat="MM/dd/yyyy"
                                                /> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk
                            // disableToolbar
                            variant="inline"
                            views={["year", "month", "date"]}
                            label="Procedure Date"
                            format="MM/dd/yyyy"
                            value={this.state.fields.procedureDate}
                            name="procedureDate"
                            onChange={this.procedureDateChange}
                            InputAdornmentProps={{ position: "end" }}
                            className="w-100"
                            error={this.state.errors.procedureDate ? true : false}
                            helperText={this.state.errors.procedureDate}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                    <div className="form-group modal-col">
                      {/* <label htmlFor="appointmentType">Appointment Type</label>
                                            <select className="custom-select form-control w-100" id="appointmentType" name="appointmentType" onChange={this.handleChange.bind(this)} value={this.state.fields.appointmentType}>
                                                <option value="">Select Appointment Type</option>
                                                <option value="New Patient">New Patient </option>
                                                <option value="Follow Up">Follow Up</option>
                                                <option value="1 Week Post-op">1 Week Post-op</option>
                                                <option value="3 Week Post-op">3 Week Post-op</option>
                                                <option value="5 Week Post-op">5 Week Post-op</option>
                                                <option value="8 Week Post-op">8 Week Post-op</option>
                                                <option value="10 Week Post-op">10 Week Post-op</option>
                                                <option value="12 Week Post-op">12 Week Post-op</option>
                                            </select> */}
                      <FormControl
                        className="w-100"
                        error={this.state.errors.appointmentType ? true : false}>
                        <InputLabel id="appointmentTypeLabel">Appointment Type *</InputLabel>
                        <Select
                          className="w-100"
                          labelId="appointmentTypeLabel"
                          id="appointmentType"
                          name="appointmentType"
                          value={this.state.fields.appointmentType}
                          onChange={this.handleChange.bind(this)}>
                          <MenuItem value="New Patient">New Patient</MenuItem>
                          <MenuItem value="Follow Up">Follow Up</MenuItem>
                          <MenuItem value="Post-Op">Post-Op</MenuItem>
                          <MenuItem value="Imaging Review">Imaging Review</MenuItem>
                          <MenuItem value="Urgent Care Tele">Urgent Care Tele</MenuItem>
                        </Select>
                        {this.state.errors.appointmentType && (
                          <FormHelperText>{this.state.errors.appointmentType}</FormHelperText>
                        )}
                      </FormControl>
                      {/* <div className="errorMsg">{this.state.errors.appointmentType}</div> */}
                    </div>

                    <div className="form-group modal-col">
                      <FormControl
                        className="w-100"
                        error={this.state.errors.resourceId ? true : false}>
                        <InputLabel id="providerLabel">Provider *</InputLabel>
                        <Select
                          className="w-100"
                          labelId="providerLabel"
                          id="resourceId"
                          name="resourceId"
                          value={this.state.fields.resourceId}
                          onChange={this.handleChange.bind(this)}>
                          {this.props.physicians.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.id}>
                                {val.lastName}, {val.firstName}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.state.errors.resourceId && (
                          <FormHelperText>{this.state.errors.resourceId}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="modal-flex-row d-flex align-items-center flex-wrap justify-content-between">
                    <div className="form-group position-relative modal-col">
                      {/*<label htmlFor="appointmentDate">Appointment Date</label>

                                             <DatePicker
                                                className="form-control datepicker bg-transparent"
                                                selected={this.state.fields.appointmentDate}
                                                name="appointmentDate"
                                                onChange={this.appointmentDateChange}
                                                dateFormat="MM/dd/yyyy"
                                            /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          disablePast
                          // disableToolbar
                          variant="inline"
                          views={["year", "month", "date"]}
                          label="Appointment Date *"
                          format="MM/dd/yyyy"
                          name="appointmentDate"
                          value={this.state.fields.appointmentDate}
                          onChange={this.appointmentDateChange}
                          InputAdornmentProps={{ position: "end" }}
                          error={this.state.errors.appointmentDate ? true : false}
                          helperText={this.state.errors.appointmentDate}
                          className="w-100"
                          maxDate={momentTZ().tz('America/Chicago').add(6, 'months').calendar()}
                        />
                      </MuiPickersUtilsProvider>

                      {/* <div className="errorMsg">{this.state.errors.appointmentDate}</div> */}
                    </div>

                    <div className="form-group position-relative modal-col">
                      {/* <DatePicker
                                                className="form-control datepicker bg-transparent"
                                                selected={this.state.fields.appointmentTime}
                                                onChange={this.appointmentTimeChange}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"

                                            />
                                            <span className="input-icon position-absolute label-icon">
                                                <CustomImg src="icon-clock.svg" alt="Calendar Icon" />
                                            </span> */}

                      {/*<label htmlFor="appointmentTime">Appointment Time</label>
                                             <select className="custom-select form-control w-100" id="appointmentTime" name="appointmentTime" onChange={this.handleChange.bind(this)} value={this.state.fields.appointmentTime}>
                                                <option value="">Select Time</option>
                                                {this.state.physician_availabilities !== undefined && this.state.physician_availabilities.length > 0 ? this.state.physician_availabilities.map((val, index) => {
                                                    return (
                                                        <option key={index} value={val.start}>{moment(val.start).format('h:mm A')} </option>
                                                    )
                                                }) : ''}
                                            </select> */}

                      <FormControl
                        className="w-100"
                        error={this.state.errors.appointmentTime ? true : false}>
                        <InputLabel id="appointmentTimeLable">Appointment Time *</InputLabel>
                        <Select
                          className="w-100"
                          labelId="appointmentTimeLable"
                          id="appointmentTime"
                          name="appointmentTime"
                          value={this.state.fields.appointmentTime}
                          disabled={
                            !physician_availabilities ||
                            this.state.fields.resourceId === "" ||
                            this.state.fields.appointmentDate === null
                          }
                          onChange={this.handleChange.bind(this)}
                          error={this.state.errors.appointmentTime}
                          >
                          {physician_availabilities &&
                            physician_availabilities.map((val, index) => {
                              return (
                                <MenuItem key={index} value={moment(val.start).format()}>
                                  {moment(val.start).format("h:mm A")}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {this.state.errors.appointmentTime && (
                          <FormHelperText>{this.state.errors.appointmentTime}</FormHelperText>
                        )}
                      </FormControl>
                      {/* <div className="errorMsg">{this.state.errors.appointmentTime}</div> */}
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <h3 className="font-18 quicksand-bold text-col-58">Additional Attendees</h3>
                </div>
                <div className="col-12 position-relative">
                  {this.state.fields.attendee && this.state.fields.attendee.length > 0 ? (
                    this.state.fields.attendee.map((val, index) => {
                      return (
                        <AddAttendee
                          index={index}
                          handelAttendee={(attendee, index) => this.handelAttendee(attendee, index)}
                          errors={this.state.errors}
                          attendee={val}></AddAttendee>
                      );
                    })
                  ) : (
                    <AddAttendee
                      index="0"
                      handelAttendee={(attendee, index = 0) => this.handelAttendee(attendee, index)}
                      errors={this.state.errors}></AddAttendee>
                  )}
                  {!this.state.fields["attendee"] || this.state.fields["attendee"].length < 2 ? (
                    <div className="form-group add-attendee-btn-formg-roup mb-0">
                      <a
                        href="javascript:void(0)"
                        onClick={this.addAttendee.bind(this)}
                        type="button"
                        className="add-attendee-btn-icon">
                        <span class="material-icons"> add_circle </span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-border-primary box-shadow-none text-uppercase mr-3" onClick={this.props.closePopup}>Cancel{this.state.isAllowCancel}</button>
                                {
                                    this.state.fields.isAllowCancel ?
                                        <button type="button" className="btn btn-border-primary box-shadow-none text-uppercase mr-3" onClick={this.props.closePopup}>Cancel APPT</button> : ''
                                }
                                <button type="submit" className="btn btn-primary text-uppercase px-4 ml-0">SAVE</button> */}

                <Button
                  variant="outlined"
                  type="button"
                  className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                  onClick={this.props.closePopup}>
                  Cancel{this.state.isAllowCancel}
                </Button>
                {this.state.fields.isAllowCancel ? (
                  <Button
                    variant="outlined"
                    type="button"
                    className="btn btn-border-primary box-shadow-none text-uppercase mr-3"
                    onClick={this.cancelAppointment.bind(this)}>
                    Cancel APPT
                  </Button>
                ) : (
                  ""
                )}
                <Button type="submit" className="btn btn-primary text-uppercase px-4 ml-0">
                  SAVE
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ScheduleApptModal;
