import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "../../Containers/PageContainer/Dashboard/Dashboard";
import Conference from '../../Containers/PageContainer/Conference/Conference';
import Endsession from '../PageContainer/Conference/Endsession';
import Linkexpired from '../PageContainer/Conference/Linkexpired';
import Overcapicity from '../PageContainer/Conference/Overcapicity';
import Sms from '../Global/Sms/Sms';
import Users from '../../Containers/PageContainer/User/User';
import Reportdashboards from '../../Containers/PageContainer/Report/Reportdashboard';
import Reportlists from '../../Containers/PageContainer/Report/Reportlist';

class Main extends React.Component {
    render() {
        if (this.props.authState === "signedIn") {
            return (
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/v/:shortcode?" component={Conference} />
                    <Route exact path="/a/:shortcode?" component={Conference} />
                    <Route exact path="/e" component={Endsession} />
                    <Route exact path="/i" component={Linkexpired} />
                    <Route exact path="/c" component={Overcapicity} />
                    <Route exact path="/sms" component={Sms} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/reportdashboard" component={Reportdashboards} />
                    <Route exact path="/reportdetail" component={Reportlists} />                    
                    <Route component={Dashboard} />
                </Switch>
            );
        } else {
            return (
                <Switch>
                    <Route exact path="/v/:shortcode?" component={Conference} />
                    <Route exact path="/a/:shortcode?" component={Conference} />
                    <Route exact path="/e" component={Endsession} />
                    <Route exact path="/i" component={Linkexpired} />
                    <Route exact path="/c" component={Overcapicity} />
                </Switch>
            );
        }
    }
}

export default Main;
