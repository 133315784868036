import "date-fns";
import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment-timezone";
import { isMobile } from "mobile-device-detect";

import Storage from "../../../Providers/Storage";
import Patientsearch from "./../../../Containers/PageContainer/Patient/Patient";
import Pastappointment from "./../../../Containers/PageContainer/Dashboard/Pastappointment";
import Appointmentlist from "../../../Containers/PageContainer/Dashboard/Appointmentlist";
import PatientSearchbox from "./../../../Containers/PageContainer/Layout/PatientSearchbox";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      currentDatetime: moment().format("MM/DD/YYYY hh:mm").valueOf(),
      isPatientSearch: true,
      showApptPopup: false,
      scheduleInfo: "",
      current_tab: "schedule",
      isClinicanUser: false,
      userId: "",
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    //this.getPastAppointments();
    this.props.getCurrentUserInfo();
    this.props.setSearchTxtPatient(undefined);
    //await this.props.getPhysicianList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userInfo && this.props.userInfo.username) {
      Storage.set("username", this.props.userInfo.username);
      if (this.props.userInfo.attributes["custom:Practice"])
        Storage.set("userpractice", this.props.userInfo.attributes["custom:Practice"]);
    }
    if (prevProps.userInfo !== this.props.userInfo) {
      if (this.props.userInfo && this.props.userInfo.attributes["custom:ResourceId"]) {
        this.setState({
          isClinicanUser: true,
          userId: this.props.userInfo.attributes["custom:ResourceId"],
        });
      } else {
        this.setState({ isClinicanUser: false, userId: "" });
      }
    }
  }

  handleTabSelect = (key) => {
    if (key === "past_appointment" && this.state.current_tab !== "past_appointment") {
      //this.getPastAppointments();
    } else if (key === "schedule" && this.state.current_tab !== "schedule") {
      //this.getAppointments(this.state.startDate);
    }
    this.setState({
      current_tab: key,
    });
  };
  startSession = (val, shortcode) => {
    val.shortcode = shortcode;
    this.props.history.push({
      pathname: "/v",
      //search: '?query=',
      state: { detail: val },
    });
  };
  render() {
    return (
      <main className="main-container">
        <div className="content-area scrollbar h-100">
          <div className="container-fluid container-padding">
            <div className="body-search-bar d-lg-none mb-3">
              {this.props.userInfo && this.props.userInfo.username && !isMobile ? (
                <PatientSearchbox></PatientSearchbox>
              ) : (
                ""
              )}
            </div>

            {this.props.serchtxtpatient === undefined || this.props.serchtxtpatient.length === 0 ? (
              <div>
                <h2 className="font-18 quicksand-regular text-charcoal pb-2 text-left">
                  My Dashboard
                </h2>
                <div
                  className={
                    isMobile
                      ? "responsive-content-table-panel content-tab-panel"
                      : "content-tab-panel"
                  }>
                  {/* <!--Tab Headings--> */}
                  <div className="tab-headings">
                    <Tabs
                    activeKey={this.state.current_tab || "Schedule"}
                      className="hide-tab-title"
                      id="controlled-tab-example"
                      onSelect={this.handleTabSelect}>
                      <Tab eventKey={"schedule"} title="Schedule">
                        {/* <!--First Tab Schedule Appointments--> */}
                        {this.state.current_tab === "schedule" ? (
                          <Appointmentlist
                            startSession={this.startSession}
                            isClinicanUser={this.state.isClinicanUser}
                            userId={this.state.userId}></Appointmentlist>
                        ) : (
                          "null"
                        )}
                      </Tab>
                      {!isMobile && (
                        <Tab eventKey={"past_appointment"} title="Past Appointments">
                          {/* <!--Second Tab Past Appointments--> */}
                          {this.state.current_tab === "past_appointment" ? (
                            <Pastappointment
                              isClinicanUser={this.state.isClinicanUser}></Pastappointment>
                          ) : (
                            "null"
                          )}
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-100">
                <Patientsearch searchTxt={this.props.serchtxtpatient}></Patientsearch>
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

export default Dashboard;
