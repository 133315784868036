
import Http from '../../Services/Http';
import {
  URL_PATIENTS, APINAME_PATIENTS
} from '../../Containers/Common/APIUtils';
const api = new Http({});


const getPatientList = async (search) => {
  let apiName = APINAME_PATIENTS;
  let path = URL_PATIENTS;
  let queryParams = {
    "search": search
  };

  // fetch patient from api
  let response = await api.get(apiName, path, queryParams);

  // fetch patient from static json file
  //let response = data.patients[0];
  return response;
};

// Add patient API call
const postPatient = async (patient) => {

  let apiName = APINAME_PATIENTS;
  let path = URL_PATIENTS;
  let response = "";
  if (patient !== '') {
    response = await api.post(apiName, path, patient);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
}

// update patient API call
const putPatient = async (patient) => {

  let apiName = APINAME_PATIENTS;
  let path = URL_PATIENTS + '/' + patient.id;
  let response = "";
  if (patient !== '') {
    response = await api.put(apiName, path, patient);
  }
  //response = data.postPatient[0].data;

  //dispatch({ type: GET_PATIENTS, data: response });

  return response;
}


export const PatientService = {
  getPatientList, postPatient, putPatient
};
