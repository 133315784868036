import * as actionTypes from './actionTypes';
import { alertService } from '../../data/services/alertService';
import { Appointment } from '../../Models/index';
import { AppointmetService } from '../../data/services/appointmentService';
import { ConferenceService } from '../../data/services/conferenceService';
//import {AlertHelper} from '../../common/AlertHelper';
//import {logger} from '../../common/logger';



export const appointmentList = (appointment_date, physicians, pageInfo) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_APPOINTMENTS });
  getAppointmentList(dispatchEvent, appointment_date, physicians, pageInfo);

};

function convertDbPatientToModel(val) {
  const obj = {};
  obj[Appointment.id] = val.id;
  obj[Appointment.patientId] = val.patientId;
  obj[Appointment.patientName] = `${val.patientLastName}, ${val.patientFirstName}`;
  obj[Appointment.startDate] = val.scheduleStart;
  obj[Appointment.endDate] = val.scheduleEnd;
  obj[Appointment.physican] = val.physician;
  obj[Appointment.patientPhone] = val.patientPhone;
  obj[Appointment.patientDOB] = val.patientDob;
  // obj[Appointment.completed] = val.completed; // TODO: What is this?
  obj[Appointment.appointmentType] = val.appointmentType;
  obj[Appointment.procedureDesc] = val.procedureDesc;
  obj[Appointment.procedureDate] = val.procedureDate;
  obj[Appointment.roomStatus] = val.roomStatus;
  //obj[Appointment.physicianId] = physicians.filter((data, key) => { return data.name === obj[Appointment.physican] }).map((data) => { return data.id });
  obj[Appointment.physicianId] = val.clinicianUserId;
  obj[Appointment.resource] = '';
  if (val.providerLastName !== '' && val.providerFirstName !== '') {
    obj[Appointment.resource] = val.providerLastName + ', ' + val.providerFirstName;
  }
  if (val.attendee && val.attendee.length) {
    obj[Appointment.attendee] = val.attendee;
  }
  return obj;
}

function getAppointmentList(dispatchEvent, appointment_date, physicians, pageInfo={}) {
  AppointmetService.getAppointmentList(appointment_date, pageInfo)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      //let response = data;
      let modifiedRes = [];
      if(response?.status === "error") {
        throw response;
      }
      response.data.forEach((val, index) => {
        if (val.roomStatus !== 'CANCELLED') {
          const obj = convertDbPatientToModel(val);
          modifiedRes.push(obj);
        }
      });

      // sort appointment by starttime
      modifiedRes.sort((a, b) => (a[Appointment.startDate] > b[Appointment.startDate]) ? 1 : -1)
      dispatchEvent({
        type: actionTypes.APPOINTMENT_LIST_SUCCESS,
        payload: {data: modifiedRes , meta:response.meta },
      });
    })
    .catch(error => {
      console.log(error);
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      dispatchEvent({
        type: actionTypes.APPOINTMENT_LIST_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const pastAppointmentList = (filter, pageInfo) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_PAST_APPOINTMENTS });
  getPastAppointmentList(dispatchEvent, filter, pageInfo);
};

function getPastAppointmentList(dispatchEvent, filter, pageInfo) {

  AppointmetService.getPastAppointmentList(filter, pageInfo)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });


      //let response = data;
      let modifiedRes = [];
      if(response?.status === "error") {
        throw response;
      }

      response.data.forEach((val, index) => {
        if (val.roomStatus !== 'CANCELLED') {
          const obj = convertDbPatientToModel(val);
          modifiedRes.push(obj);
        }
      });
      let pastappointment = {}
      pastappointment.data = modifiedRes;
      pastappointment.meta = response.meta;

      // sort appointment by starttime
      //modifiedRes.sort((a, b) => (a[Appointment.startDate] > b[Appointment.startDate]) ? 1 : -1);
      dispatchEvent({
        type: actionTypes.PASTAPPOINTMENT_LIST_SUCCESS,
        payload: pastappointment,
      });
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      //
      dispatchEvent({
        type: actionTypes.PASTAPPOINTMENT_LIST_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const submitAppointment = (appointmentInfo) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  postAppointment(dispatchEvent, appointmentInfo);
};

function postAppointment(dispatchEvent, appointmentInfo) {
  let obj = {};
  obj['resourceId'] = appointmentInfo.resourceId;
  obj['begin'] = appointmentInfo.begin;
  obj['end'] = appointmentInfo.end;
  obj['patientName'] = appointmentInfo.patientName;
  obj['patientPhone'] = appointmentInfo.patientPhone;
  obj['birthDate'] = appointmentInfo.patientBirthDate;
  obj['appointmentType'] = appointmentInfo.appointmentType;
  obj['procedureDesc'] = appointmentInfo.procedureDesc;
  obj['procedureDate'] = appointmentInfo.procedureDate;
  obj['physician'] = appointmentInfo.physician;
  obj['notes'] = 'NA';
  obj['pid'] = appointmentInfo.patientId;
  obj['appointmentId'] = appointmentInfo.appointmentId;
  let attendees = appointmentInfo.attendee;
  obj['attendee'] = [];
  attendees.forEach((val, index) => {
    obj['attendee'][index] = {};
    // obj['attendee'][index].attendee_name = appointmentInfo.attendee_name[index];
    // obj['attendee'][index].attendee_email = appointmentInfo.attendee_email[index];
    // obj['attendee'][index].attendee_phone = appointmentInfo.attendee_phone[index];
    obj['attendee'][index].attendee_name = val.attendee_name;
    obj['attendee'][index].attendee_email = val.attendee_email;
    obj['attendee'][index].attendee_phone = val.attendee_phone;
  });
  AppointmetService.postAppointment(obj)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      if (response?.status === 'success') {
        alertService.success('Your appointment has been successfully scheduled.', { autoClose: true });
        dispatchEvent({
          type: actionTypes.APPOINTMENT_SAVE_SUCCESS,
          payload: response,
        });
      }
      if( response?.status === 'error' && response?.statusCode == 400 ){
        alertService.error(response.message,{ autoClose:true });
        dispatchEvent({
          type: actionTypes.APPOINTMENT_SAVE_ERROR,
          payload: response,
        });
      } else if (response?.status === 'error') {
        throw response;
      }
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // 
      dispatchEvent({
        type: actionTypes.APPOINTMENT_SAVE_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}


export const cancelAppointment = (appointmentId) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  postCancelAppointment(dispatchEvent, appointmentId);
};

function postCancelAppointment(dispatchEvent, appointmentId) {


  AppointmetService.postCancelAppointment(appointmentId)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      if (response?.status === 'success') {
        alertService.success('Your appointment has been successfully canceled.', { autoClose: true });
        dispatchEvent({
          type: actionTypes.APPOINTMENT_CANCEL_SUCCESS,
          payload: response,
        });
      }
      else if (response?.status === 'error') {

        dispatchEvent({
          type: actionTypes.APPOINTMENT_CANCEL_ERROR,
          payload: response,
        });
        if (response?.statusCode === 422) {
          alertService.error(response.message, { autoClose: true });
        } else {
          throw response;
        }
      } 
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      //  
      dispatchEvent({
        type: actionTypes.APPOINTMENT_CANCEL_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

export const appointmentStatus = (appointmentId) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_APPOINTMENTS });
  getAppointmentStatus(dispatchEvent, appointmentId);

};

function getAppointmentStatus(dispatchEvent, appointmentId) {
  AppointmetService.getAppointmentStatus(appointmentId)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });

      //let response = data[0];
      let modifiedRes = response.data;
      if (response?.status === 'success') {
        dispatchEvent({
          type: actionTypes.APPOINTMENT_STATUS_SUCCESS,
          payload: modifiedRes,
        });
      } else if (response?.status === 'error') {

        // dispatchEvent({
        //   type: actionTypes.PATIENT_ADD_ERROR,
        //   payload: response,
        // });
        // if (response.statusCode === '404') {
        //   alertService.error('A Patient with this info already exists. Please modify your entry, or search for this patient.');
        // }

        throw response;
      } 


    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // 
      dispatchEvent({
        type: actionTypes.APPOINTMENT_STATUS_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

export const appointmentParticipants = (shortcode) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  dispatchEvent({ type: actionTypes.GET_APPOINTMENTS });
  getAppointmentParticipants(dispatchEvent, shortcode);

};

function getAppointmentParticipants(dispatchEvent, shortcode) {
  ConferenceService.getAppointmentInfo(shortcode)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
      console.log(response);
      //let response = data[0];
      let modifiedRes = response.data;
      if (response?.status === 'success') {
        dispatchEvent({
          type: actionTypes.APPOINTMENT_PARTICIPANTS_SUCCESS,
          payload: modifiedRes,
        });
      } else if (response?.status === 'error') {

        // dispatchEvent({
        //   type: actionTypes.PATIENT_ADD_ERROR,
        //   payload: response,
        // });
        // if (response.statusCode === '404') {
        //   alertService.error('A Patient with this info already exists. Please modify your entry, or search for this patient.');
        // }
        throw response;
      }
    })
    .catch(error => {
      console.log("getAppointmentParticipants ", error);
      //alertService.error('Something went wrong. Please try again.', { autoClose: true });
      // dispatchEvent({
      //   type: actionTypes.APPOINTMENT_PARTICIPANTS_ERROR,
      //   payload: error,
      // });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // 
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}
