import * as actionTypes from './actionTypes';
import { alertService } from '../../data/services/alertService';

import { ConferenceService } from '../../data/services/conferenceService';
//import {AlertHelper} from '../../common/AlertHelper';
//import {logger} from '../../common/logger';


export const submitSessionQuality = (appointmentId, sessionInfo) => dispatchEvent => {
  dispatchEvent({
    type: actionTypes.IS_LOADING,
    isLoading: true,
  });
  //dispatchEvent({ type: actionTypes.GET_PATIENTS });
  postSubmitSessionQuality(dispatchEvent, appointmentId, sessionInfo);
};
function postSubmitSessionQuality(dispatchEvent, appointmentId, sessionInfo) {
  let obj = {};
  obj['success'] = sessionInfo.completed;
  obj['feedbackMessage'] = "None";
  if (sessionInfo.sessionIssue && sessionInfo.sessionIssue !== '') {
    obj['feedbackMessage'] = sessionInfo.sessionIssue;
  }
  ConferenceService.postSubmitSessionQuality(appointmentId, obj)
    .then(response => {
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });

      if (response?.status === 'success') {
        dispatchEvent({
          type: actionTypes.FEEDBACK_APPOINTMENT_SUCCESS,
          payload: response,
        });
        alertService.success('Feedback saved successfully',{autoClose:true});
      } 
      else if(response?.status === "error"){
        throw response;
      } 
    })
    .catch(error => {
      console.log(error);
      // alertService.error('Something went wrong. Please try again.', { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      // dispatchEvent({
      //   type: actionTypes.FEEDBACK_APPOINTMENT_ERROR,
      //   payload: error,
      // });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}