/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { Auth } from "aws-amplify";
import "./SignIn.css";
import { Alert, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import CustomImg from "../Global/CustomImg/CustomImg";
import Branding from "../../Services/Branding";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Progress from "react-progress-2";
import { alertService } from '../../data/services/alertService';
import { history } from "../../Providers/History";

// set two factor authentication flow for sign in 
Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
})

const FORMS = {
  SIGN_IN: { title: "Sign in to your account" },
  RESET_PASSWORD: { title: "Reset your password" },
  CONFIRM_RESET_PASSWORD: { title: "Confirm Reset your password" },
  SET_NEW_PASSWORD: { title: "Change Password" },
  VERIFY_SIGN_IN_ACCOUNT: { title: "Verify Account" },
};

export class CustomSignIn extends Component {
  inputs = {};
  currentUser = null;

  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.signIn = this.signIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSignInFormSubmission = this.handleSignInFormSubmission.bind(
      this
    );
    this.handleResetPasswordFormSubmission = this.handleResetPasswordFormSubmission.bind(
      this
    );
    this.handleNewPasswordFormSubmission = this.handleNewPasswordFormSubmission.bind(
      this
    );
    this.handleConfirmResetPasswordFormSubmission = this.handleConfirmResetPasswordFormSubmission.bind(
      this
    );
    this.onBlurPassword = this.onBlurPassword.bind(this);
    this.state = {
      form: FORMS.SIGN_IN,
      logoImageUrl: null,
      logoImageAlt: null,
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecial: false,
      hasLength: false,
      focusoutConfirmPassword: false,
      userAttributes: null,
      loginerror: '',
      codeError: ''
    };
    this.handleVerifyAccountToSignInFormSubmission = this.handleVerifyAccountToSignInFormSubmission.bind(
      this
    );

    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.resendVerificationCode = this.resendVerificationCode.bind(this);
  }

  async setBranding() {
    const branding = await Branding.getBranding();
    if (this.state.logoImageUrl !== branding.logoImageUrl) {
      this.setState({
        logoImageUrl: branding.logoImageUrl,
        logoImageAlt: branding.logoImageAlt,
      });
    }
  }

  async componentDidMount() {
    // const path = window.location.pathname;
    // if (path !== "/"){
    //   history.push("/");
    // }
    await this.setBranding();
    // this.pageDisplayOnRefresh();
  }

  /* pageDisplayOnRefresh(){
    Object.entries(FORMS).forEach(([key, value]) => {
      if (localStorage.getItem('form') === Object.values(value)[0]) {
        // If the value in 'localStorage' matches a value in 'FORMS', update the component state
        this.setState({ form: FORMS[key] });
      }
    });
  } */

  handleSignInFormSubmission(evt) {
    this.setState({ loginerror: '' });
    evt.preventDefault();
    if (this.validateLoginfrm()) {
      this.signIn();
    }
  }

  validateLoginfrm() {
    let loginerror = '';
    let formIsValid = true;
    let email = this.inputs.username
    let password = this.inputs.password;
    if (!email || email == '') {
      loginerror = "The email or password is incorrect.";
      formIsValid = false;
    }

    if (!password || password == '') {
      loginerror = "The email or password is incorrect.";
      formIsValid = false;
    }
    this.setState({ loginerror: loginerror });
    return formIsValid;
  }

  async handleVerifyAccountToSignInFormSubmission(event) {
    this.setState({ error: '' });
    event.preventDefault();
    Progress.show();
      try {
          let verificationCode = this.inputs.verificationCode;
          // console.log("this.currentUser ===>>", this.currentUser);
          // console.log("this.inputs ==>>", this.inputs);
          if (!verificationCode || verificationCode === '') {
              Progress.hide();
              this.setState({ error: "Please enter verification code." });
          } else {
              if (this.currentUser.challengeName === 'CUSTOM_CHALLENGE') {
                this.currentUser = await Auth.sendCustomChallengeAnswer(this.currentUser, verificationCode)
                // console.log("this.currentUser send verification ==>>", this.currentUser);
                Progress.hide();
                if (this.currentUser.challengeName) {
                    console.log("Invalid verification code, try again");
                    this.setState({ error: "Invalid verification code, try again." });
                } else {
                    console.log("Authentication successful");
                    this.inputs={};
                    this.props.onStateChange("signedIn", {});
                    this.showForm(FORMS.SIGN_IN);
                }
              } else {
                  Progress.hide();
                  this.inputs={};
                  this.props.onStateChange("signedIn", {});
                  this.showForm(FORMS.SIGN_IN);
              }
          }
      } catch (err) {
        Progress.hide();
        console.log("err ==>>", err)
        if(err.code === 'NotAuthorizedException' && err.message === 'Invalid session for the user.') {
            this.setState({ error: "Verification code is expired. Please resend verification code and try again." });
        } else if(err.code === 'NotAuthorizedException') {
            this.setState({ error: "You have exceeded the maximum number of failed attempts. Go back to Login page and try again." });
        } else {
          this.handleAuthError(err);
        }
      }

  }

  handleResetPasswordFormSubmission(evt) {
    this.setState({ error: "" });
    evt.preventDefault();
    if (this.validateForgotPWD()) {
      this.beginResetPassword();
    }
  }
  validateForgotPWD() {
    let email = this.inputs.username_forgotPwd
    if (email === '' || email === null || email === undefined) {
      this.setState({
        error: "The email field is required.",
      });
      return false;
    }
    else if (email !== "") {
      var pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!pattern.test(email)) {
        this.setState({
          // error: "We can't find a user with that email.",
          error: "Please enter a valid email address.",
        });
        return false;
      }
    }
    this.setState({
      error: "",
      loginerror: ""
    });
    return true;
  }

  handleNewPasswordFormSubmission(evt) {
    evt.preventDefault();
    if (!this.state.passwordIsValid) {
      this.setState({ error: "Password is not valid." });
      return;
    }
    if (this.inputs.newPassword !== this.inputs.confirmPassword) {
      this.setState({ error: "Both passwords should be the same." });
      return;
    }
    this.setState({ error: "" });
    this.setNewPassword();
  }

  handleConfirmResetPasswordFormSubmission(evt) {
    this.setState({ error: "", codeError: "" });
    evt.preventDefault();
    this.confirmResetPassword();
  }

  handleInputChange(evt) {
    this.setState({ error: null, codeError: null });
    const { name, value } = evt.target;
    const newState = { error: "" };

    if (["newPassword"].indexOf(name) > -1) {
      newState.hasUppercase = !!value.match(/[A-Z]/);
      newState.hasLowercase = !!value.match(/[a-z]/);
      newState.hasNumber = !!value.match(/[0-9]/);
      newState.hasSpecial = !!value.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
      newState.hasLength = value.length >= 8;

      newState.passwordIsValid =
        newState.hasUppercase &&
        newState.hasLowercase &&
        newState.hasSpecial &&
        newState.hasNumber &&
        newState.hasLength;
    }

    /* if (name === "username") {
      this.inputs[name] = value.toLowerCase();
    } else {
      this.inputs[name] = value;
    } */
    this.inputs[name] = value;

    this.setState(newState);
  }

  _onNewPasswordChange = (e) => {
    this.setState({ error: null });
    const { name, value } = e.target;
    const newState = { error: "" };

    newState.hasUppercase = !!value.match(/[A-Z]/);
    newState.hasLowercase = !!value.match(/[a-z]/);
    newState.hasNumber = !!value.match(/[0-9]/);
    newState.hasSpecial = !!value.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    newState.hasLength = value.length >= 8;

    newState.passwordIsValid =
      newState.hasUppercase &&
      newState.hasLowercase &&
      newState.hasSpecial &&
      newState.hasNumber &&
      newState.hasLength;

    this.inputs[name] = value;
    this.setState(newState);
  };

  onBlurPassword(evt) {
    this.setState({ focusoutConfirmPassword: true });
  }

  render() {
    if (this.props.authState === "signedIn") {
      return null;
    } else {
      return (
        <div className="scrollbar h-100 px-3">
          <div className="login-card-container">
            <div className="backdrop">
              <div className="top"></div>
            </div>
            <Card className="login-card">
              <Card.Body>
                {/*<Card.Title>{this.state.form.title}</Card.Title>*/}
                <Card.Text>
                  {this.state.form === FORMS.SIGN_IN ? this.loginForm() : null}
                  {this.state.form === FORMS.VERIFY_SIGN_IN_ACCOUNT ? this.verifyToSignInAccount() : null}
                  {this.state.form === FORMS.RESET_PASSWORD ? this.resetPasswordForm() : null}
                  {this.state.form === FORMS.CONFIRM_RESET_PASSWORD ? this.confirmResetPasswordForm() : null}
                  {this.state.form === FORMS.SET_NEW_PASSWORD ? this.setNewPasswordForm() : null}
                </Card.Text>
                <Card.Footer>
                  <div className="footer nunito-regular">
                    A <span className="theos nunito-bold">Theos</span>
                    <span className="health nunito-bold">Health</span> Partner
                  </div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </div>
        </div>
      );
    }
  }

  showForm(form) {
    this.setState({ form, error: "" });
    localStorage.setItem('form',form.title);
  }

  handleAuthError(err, isGeneric = false,forgotPassword=false) {
    let errorMessage = "An error has occurred.";
    switch (err.code) {
      case "LimitExceededException":
        errorMessage = "Limit exceeded.  Try again in a few minutes.";
        break;
      case "NotAuthorizedException": // The error happens when the incorrect password is provided
        if (forgotPassword && err.message === 'User password cannot be reset in the current state.') {
          errorMessage = 'Please login with the temporary password sent in your email or contact the administrator to reset your account again.';
        } else if (err.message === "Invalid session for the user, session is expired.") {
          errorMessage = 'Your session is expired. Reload the page and try again.';
        } else {
          errorMessage = 'The email or password is incorrect.';
        }
        break;
      case "UserNotFoundException": // The error happens when the supplied username/email does not exist in the Cognito user pool
        errorMessage = 'The email or password is incorrect.';
        break;
      case "CodeMismatchException": // The error happens when the incorrect code entered
        errorMessage = "Code entered doesn't match our records. Please re-enter.";
        break;
      case "InvalidParameterException":
        errorMessage = (isGeneric) ? "The email or password is incorrect." : "The email field is required.";
        break;
      default:
        errorMessage = "An error has occurred.";
        console.error(err);
    }
    this.setState({
      error: errorMessage,
    });
  }

  async signIn(isResend = false) {
    const username = this.inputs.username;
    const password = this.inputs.password;
    try {
      Progress.show();
      this.currentUser = await Auth.signIn(username, password);
      Progress.hide();
      if (this.currentUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        console.log("this.currentUser NEW_PASSWORD_REQUIRED ==>> ", this.currentUser);
        this.setState({ userAttributes: this.currentUser ?.challengeParam ?.userAttributes || null });
        this.showForm(FORMS.SET_NEW_PASSWORD);
      } else if (this.currentUser.challengeName === 'CUSTOM_CHALLENGE') {
        console.log("this.currentUser CUSTOM_CHALLENGE ==>> ", this.currentUser);
        this.showForm(FORMS.VERIFY_SIGN_IN_ACCOUNT);
        if(isResend) {
          alertService.success(`Verification code sent to ${username}.`, { autoClose: true });
        }
      } else {
        this.props.onStateChange("signedIn", {});
      }
    } catch (err) {
      Progress.hide();
      console.log("err ==>>", err)
      this.handleAuthError(err, true);
    }
  }

  async beginResetPassword() {
    Progress.show();
    const username = this.inputs.username_forgotPwd;
    try {
      await Auth.forgotPassword(username);
      Progress.hide();
      this.showForm(FORMS.CONFIRM_RESET_PASSWORD);
    } catch (err) {
      Progress.hide();
      this.handleAuthError(err,false,true);
    }
  }

  async confirmResetPassword() {
    let forgotPasswordSetError = false;
    if (!this.state.passwordIsValid) {
      this.setState({ error: "Password is not valid." });
      forgotPasswordSetError = true;
    }

    if (!this.inputs.code || !this.inputs.code.trim()) {
      this.setState({ codeError: "Invalid code." });
      forgotPasswordSetError = true;
    }
    if(forgotPasswordSetError) {
      return;
    }
    const code = this.inputs.code;
    const username = this.inputs.username_forgotPwd;
    const newPassword = this.inputs.newPassword;
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      this.inputs = { username };
      this.showForm(FORMS.SIGN_IN);
      alertService.success('Password updated successfully.', {autoClose: true});
    } catch (err) {
      this.handleAuthError(err);
    }
  }

  async setNewPassword() {
    const newPassword = this.inputs.newPassword;
    try {
      await Auth.completeNewPassword(this.currentUser, newPassword, {});
      alertService.success('Password updated successfully.', {autoClose: true})
      this.showForm(FORMS.SIGN_IN);
      this.props.onStateChange("signedIn", {});
    } catch (err) {
      this.handleAuthError(err);
    }
  }

  loginForm() {
    return (
      this._validAuthStates.includes(this.props.authState) && (
        <Form onSubmit={this.handleSignInFormSubmission}>
          <div className="text-center logo">
            <CustomImg
              src={this.state.logoImageUrl}
              alt={this.state.logoImageAlt}
              longdesc={this.state.logoImageAlt}
              title={this.state.logoImageAlt}
              resizemode="stretch"
            />
          </div>

          <div className="login-body">
            <div className="mb-4 pb-1">
              <TextField
                name="username"
                type="text"
                label="Email *"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="mb-3">
              <TextField
                name="password"
                type="password"
                label="Password *"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="input-alert-error mr-1">
              <Alert hidden={!this.state.loginerror} key="error" variant="danger">
                {this.state.loginerror}
              </Alert>
            </div>

            <div className="input-alert-error mr-1">
              <Alert hidden={!this.state.error} key="error" variant="danger">
                {this.state.error}
              </Alert>
            </div>

            <div className="text-right">
              <a
                href="javascript:void(0);"
                onClick={() => this.showForm(FORMS.RESET_PASSWORD)}
              >
                Forgot password?
              </a>
              {/*<br /><a href="javascript:void(0);" onClick={() => this.showForm(FORMS.SET_NEW_PASSWORD)}>SET_NEW_PASSWORD</a>*/}
              {/*<br /><a href="javascript:void(0);" onClick={() => this.showForm(FORMS.CONFIRM_RESET_PASSWORD)}>CONFIRM_RESET_PASSWORD</a>*/}
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
            >
              Login
            </button>
          </div>
        </Form>
      )
    );
  }

  resetPasswordForm() {
    return (
      this._validAuthStates.includes(this.props.authState) && (
        <Form onSubmit={this.handleResetPasswordFormSubmission}>
          <div className="text-center logo">
            <CustomImg
              src={this.state.logoImageUrl}
              alt="SportsMed Connect"
              longdesc="SportsMed Connect"
              title="SportsMed Connect"
              resizemode="stretch"
            />
          </div>

          <div className="login-body">
            <div className="mb-3">
              <TextField
                name="username_forgotPwd"
                type="text"
                label="Enter your username (email address) *"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="input-alert-error mr-1">
              <Alert hidden={!this.state.error} key="error" variant="danger">
                {this.state.error}
              </Alert>
            </div>
            <div className="text-right">
              <a
                href="javascript:void(0);"
                onClick={() => this.showForm(FORMS.SIGN_IN)}
              >
                Back to Login
              </a>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
            >
              Submit
            </button>
          </div>
        </Form>
      )
    );
  }

  confirmResetPasswordForm() {
    return (
      this._validAuthStates.includes(this.props.authState) && (
        <Form onSubmit={this.handleConfirmResetPasswordFormSubmission}>
          <div className="text-center logo">
            <CustomImg
              src={this.state.logoImageUrl}
              alt="SportsMed Connect"
              longdesc="SportsMed Connect"
              title="SportsMed Connect"
              resizemode="stretch"
            />
          </div>

          <div className="login-body">
            <div className="mb-4 pb-1">
              <TextField
                name="code"
                type="text"
                label="Enter the code sent to your email address *"
                onChange={this.handleInputChange}
              />
              <div className="input-alert-error cnf-reset-error-msg">
                <Alert
                  hidden={!this.state.codeError}
                  key="error"
                  variant="danger"
                  className="mb-0 mb-n3"
                >
                  {this.state.codeError}
                </Alert>
              </div>
            </div>
            <div className="mb-4">
              <TextField
                name="newPassword"
                type="password"
                label="Enter new password *"
                onBlur={this.onBlurPassword}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
              <div className="input-alert-error cnf-reset-error-msg">
                <Alert
                  hidden={!this.state.error}
                  key="error"
                  variant="danger"
                  className="mb-0 mb-n3"
                >
                  {this.state.error}
                </Alert>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasUppercase ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasUppercase ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Uppercase
                </div>
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasLowercase ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasLowercase ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Lowercase
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasNumber ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasNumber ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Number
                </div>
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasSpecial ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasSpecial ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Special character
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasLength ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasLength ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  8 characters
                </div>
              </div>
            </div>
            <br />
            <div className="text-right">
              <a
                href="javascript:void(0);"
                onClick={() => this.showForm(FORMS.SIGN_IN)}
              >
                Back to Login
              </a>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
            >
              Submit
            </button>
          </div>
        </Form>
      )
    );
  }

  setNewPasswordForm() {
    const { userAttributes } = this.state;
    return (
      this._validAuthStates.includes(this.props.authState) && (
        <Form onSubmit={this.handleNewPasswordFormSubmission}>
          <div className="text-center logo">
            <CustomImg
              src={this.state.logoImageUrl}
              alt="SportsMed Connect"
              longdesc="SportsMed Connect"
              title="SportsMed Connect"
              resizemode="stretch"
            />
          </div>
          <div className="login-body">
            <div className="set-password-instruction mb-4 pb-3">
              <p className="mb-0">
                To register, please verify that the following information is
                correct.
              </p>
              <p className="mb-0">
                If it is correct, choose and confirm a password.
              </p>
              <p className="mb-0">
                If it is not, then please contact your administrator.
              </p>
            </div>
            <div className="set-password-details d-flex">
              <div className="d-inline-flex flex-column mx-auto">
                {/* <div className="set-password-details-items d-flex">
                  <span>First Name:</span>
                  <span>{userAttributes ?.firstName}</span>
                </div> */}
                {/* <div className="set-password-details-items d-flex">
                  <span>Last Name:</span>
                  <span>{userAttributes ?.lastName}</span>
                </div> */}
                <div className="set-password-details-items d-flex">
                  <span style={{display: "flex", justifyContent: "flex-end"}}>Email Address:</span>
                  <span>{userAttributes ?.email}</span>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <TextField
                name="newPassword"
                type="password"
                label="Password *"
                onChange={this._onNewPasswordChange}
                autoComplete="off"
              />
            </div>

            <div className="mb-3">
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm Password *"
                onChange={this._onNewPasswordChange}
                autoComplete="off"
              />
            </div>

            <div className="input-alert-error mr-1">
              <Alert hidden={!this.state.error} key="error" variant="danger">
                {this.state.error}
              </Alert>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasUppercase ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasUppercase ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Uppercase
                </div>
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasLowercase ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasLowercase ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Lowercase
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasNumber ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasNumber ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Number
                </div>
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasSpecial ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasSpecial ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  Special character
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {this.state.focusoutConfirmPassword &&
                    !this.state.hasLength ? (
                      <HighlightOffIcon className="icon-red-color"></HighlightOffIcon>
                    ) : (
                      <span
                        className={this.state.hasLength ? "satisfied" : "tbd"}
                      >
                        <CheckIcon></CheckIcon>
                      </span>
                    )}
                  8 characters
                </div>
              </div>
            </div>
            <br />
            <div className="text-right" style={{ display: "none" }}>
              <a
                href="javascript:void(0);"
                onClick={() => this.showForm(FORMS.SIGN_IN)}
              >
                Back to Login
              </a>
            </div>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
            >
              Submit
            </button>
          </div>
        </Form>
      )
    );
  }

  redirectToLogin(){
    this.inputs={};
    this.showForm(FORMS.SIGN_IN);
  }
  
  // resend verification Code
  async resendVerificationCode(){
    await this.signIn(true);
  }

  verifyToSignInAccount() {
    return (
      this._validAuthStates.includes(this.props.authState) && (
        <Form onSubmit={this.handleVerifyAccountToSignInFormSubmission}>
          <div className="text-center logo">
            <CustomImg
              src={this.state.logoImageUrl}
              alt={this.state.logoImageAlt}
              longdesc={this.state.logoImageAlt}
              title={this.state.logoImageAlt}
              resizemode="stretch"
            />
          </div>
          <div className="login-body p-3 m-4">
            <div className="set-password-instruction mb-4 pb-3">
              <p className="mb-1">
                We just sent your verification code via email to {this.inputs ?.username}. 
              </p>
              <p className="mb-0">
                To complete sign in, please verify that the following information is correct. 
              </p>
              <p className="mb-0">
                If it is correct, enter verification code to sign into your account.
              </p>
              <p className="mb-0">
                If it is not, then please contact your administrator.
              </p>
            </div>
            <div className="set-password-details d-flex">
              <div className="d-inline-flex flex-column mx-auto">
                <div className="set-password-details-items d-flex">
                  <span>Email Address: {this.inputs ?.username}</span>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <TextField
                name="verificationCode"
                type="text"
                label="Verification Code *"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="input-alert-error mr-1">
              <Alert hidden={!this.state.error} key="error" variant="danger">
                {this.state.error}
              </Alert>
            </div>
            <div className="mb-4 mt-1">
              <div className="d-flex align-items-start align-items-md-center">
                  <h3 className="mb-0 mr-1"><strong>Note: </strong></h3>
                  <p className="mb-0">You can retry the verification code up to 3 times</p>
              </div> 
            </div>
            <div className="d-flex">
              <a
                href="javascript:void(0);"
                onClick={this.resendVerificationCode}
                className="text-left"
              >
                Resend Code
              </a>
              <a
                href="javascript:void(0);"
                onClick={this.redirectToLogin} 
                className="text-right ml-auto"
              >
                Back to Login
              </a>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-small btn-shadow text-uppercase text-white"
            >
              Submit
            </button>
          </div>
        </Form>
      )
    );
  }
}
