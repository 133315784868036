import * as actionTypes from "./actionTypes";
import {alertService} from "../../data/services/alertService";
import {Team, User} from "../../Models/index";
import {UserService} from "../../data/services/index";
import { currentUserSignout } from "./common";
import { history } from "../../Providers/History";

export const setSearchTxtPatient = (serchTxt) => (dispatchEvent) => {
    try {
        let response = serchTxt;
        dispatchEvent({type: actionTypes.SET_SEARCHTXT_PATIENT, payload: response});
        return response;
    } catch (e) {
    }
};

export const userList = (filterBy, sortOn, sortBy, practice) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    getUserList(dispatchEvent, filterBy, sortOn, sortBy, practice);
};

function getUserList(dispatchEvent, filterBy, sortOn, sortBy, practice) {
    UserService.getUserList(filterBy, sortOn, sortBy, practice)
        .then((response) => {
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
            let modifiedRes = [];
            if(response?.status === "error"){
                throw response;
            }

            response.data.forEach((val, index) => {
                let obj = {};
                obj[User.id] = val.id;
                obj[User.systemEdxProviderId] = val.systemEdxProviderId;
                obj[User.firstName] = val.firstName;
                obj[User.lastName] = val.lastName;
                obj[User.name] = val.name;
                obj[User.email] = val.email;
                obj[User.prefix] = val.prefix;
                obj[User.suffix] = val.suffix;
                obj[User.teams] = val.teams;
                obj[User.role] = val.role;
                obj[User.isAdmin] = val.isAdmin;
                obj[User.isDeveloper] = val.isDeveloper;
                obj[User.resourceId] = val.resourceId;
                obj[User.practice] = val.practice;
                obj[User.enabled] = val.enabled;
                obj[User.patientCount] = val.patientCount;

                modifiedRes.push(obj);
            });

            dispatchEvent({
                type: actionTypes.USER_LIST_SUCCESS,
                payload: modifiedRes,
            });
        })
        .catch((error) => {
            console.log(error);
            // alertService.error("Something went wrong. Please try again.", { autoClose: true });
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            // 
            dispatchEvent({
                type: actionTypes.USER_LIST_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}

export const addUser = (userInfo) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    postUser(dispatchEvent, userInfo);
};

function postUser(dispatchEvent, userInfo) {
    UserService.postUser(userInfo)
        .then((response) => {
            dispatchEvent({type: actionTypes.IS_LOADING, isLoading: false});

            if (response?.status === "success") {
                alertService.success(`${response.firstName} ${response.lastName} Account Created Successfully.`, {autoClose: true});
                let obj = {};
                obj[User.id] = response.id;
                obj[User.systemEdxProviderId] = response.systemEdxProviderId;
                obj[User.firstName] = response.firstName;
                obj[User.lastName] = response.lastName;
                obj[User.email] = response.email;
                obj[User.prefix] = response.prefix;
                obj[User.suffix] = response.suffix;
                obj[User.role] = response.role;
                obj[User.isAdmin] = response.isAdmin;
                obj[User.teams] = response.teams;
                obj[User.practice] = response.practice;

                dispatchEvent({
                    type: actionTypes.USER_ADD_SUCCESS,
                    payload: {data: obj, status: response.status},
                });
            } else if (response?.status === "error") {
                dispatchEvent({type: actionTypes.USER_ADD_ERROR, payload: response});
                if (response?.statusCode === 400) {
                    alertService.error("An account with given email already exist.", { autoClose: true });
                } else {
                    throw response;
                }
            } 
        })
        .catch((error) => {
            console.log(error);
            //alertService.error('Something went wrong. Please try again.');
            //alertService.error(error.message);
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            // 
            dispatchEvent({type: actionTypes.USER_ADD_ERROR, payload: error});
            dispatchEvent({type: actionTypes.IS_LOADING, isLoading: false});
        });
}

export const updateUser = (userInfo, removeSelfAsAdmin) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    putUser(dispatchEvent, userInfo, removeSelfAsAdmin);
};

function putUser(dispatchEvent, userInfo, removeSelfAsAdmin) {
    UserService.putUser(userInfo)
        .then((response) => {
            removeSelfAsAdmin && history.push("/");
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });

            if (response?.status === "success") {
                !removeSelfAsAdmin && alertService.success("User info updated successfully.", {autoClose: true});
                let obj = {};
                obj[User.id] = response.id;
                obj[User.systemEdxProviderId] = response.systemEdxProviderId;
                obj[User.firstName] = response.firstName;
                obj[User.lastName] = response.lastName;
                obj[User.email] = response.email;
                obj[User.prefix] = response.prefix;
                obj[User.suffix] = response.suffix;
                obj[User.role] = response.role;
                obj[User.isAdmin] = response.isAdmin;
                obj[User.teams] = response.teams;
                obj[User.practice] = response.practice;

                dispatchEvent({
                    type: actionTypes.USER_UPDATE_SUCCESS,
                    payload: {data: obj, status: response.status},
                });
                removeSelfAsAdmin && window.location.reload();
            } else if (response?.status === "error") {
                dispatchEvent({
                    type: actionTypes.USER_UPDATE_ERROR,
                    payload: response,
                });
                if (response?.statusCode === 400) {
                    alertService.error(
                        "A User with this info already exists. Please modify your entry, or search for this user.", { autoClose: true }
                    );
                } else {
                    throw response;
                }
            } 
        })
        .catch((error) => {
            console.log(error);
            //alertService.error('Something went wrong. Please try again.');
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            //alertService.error(error.message);
            dispatchEvent({
                type: actionTypes.USER_ADD_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}

export const teamList = () => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_TEAMS});
    getTeamList(dispatchEvent);
};

function getTeamList(dispatchEvent) {
    UserService.getTeamList()
        .then((response) => {
            if(response?.status === "error") {
                throw response;
            }
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
            let modifiedRes = [];
            response.data.forEach((val, index) => {
                let obj = {};
                obj[Team.id] = val.id;
                obj[Team.name] = val.name;
                modifiedRes.push(obj);
            });

            dispatchEvent({
                type: actionTypes.TEAM_LIST_SUCCESS,
                payload: modifiedRes,
            });
        })
        .catch((error) => {
            console.log(error);
            // alertService.error("Something went wrong. Please try again.", { autoClose: true });
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            // 
            dispatchEvent({
                type: actionTypes.TEAM_LIST_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}

export const addTeam = (teamInfo) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    postTeam(dispatchEvent, teamInfo);
};

function postTeam(dispatchEvent, teamInfo) {
    UserService.postTeam(teamInfo)
        .then((response) => {
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
            if (response?.status === "success") {
                let obj = {};
                obj[Team.id] = response.id;
                obj[Team.name] = response.name;
                obj[Team.clinicId] = response.clinicId;

                dispatchEvent({
                    type: actionTypes.TEAM_ADD_SUCCESS,
                    payload: {data: obj, status: response.status},
                });
            } else if (response?.status === "error") {
                dispatchEvent({
                    type: actionTypes.TEAM_ADD_ERROR,
                    payload: response,
                });
                if (response?.statusCode === "400") {
                    alertService.error(
                        "A Team with this info already exists. Please modify your entry, or search for this team.", { autoClose: true }
                    );
                } else {
                    throw response;
                }
            } 
        })
        .catch((error) => {
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            dispatchEvent({
                type: actionTypes.USER_ADD_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}

export const deactivateAccount = (userid, isLoggedInUser) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    putDeactiveAccount(dispatchEvent, userid, isLoggedInUser);
};

export const activateAccount = (userid) => (dispatchEvent) => {
    dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: true,
    });
    dispatchEvent({type: actionTypes.GET_USERS});
    putActiveAccount(dispatchEvent, userid);
};

function putDeactiveAccount(dispatchEvent, userid, isLoggedInUser) {  
    UserService.putDeactiveAccount(userid)
        .then((response) => {
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });

            if (response?.status === "success") {
                alertService.success("User deactivated successfully.", {autoClose: true});
                let obj = {};

                dispatchEvent({
                    type: actionTypes.USER_DEACTIVE_SUCCESS,
                    payload: {data: obj, status: response.status},
                });
                isLoggedInUser && dispatchEvent(currentUserSignout());
            } else if (response?.status === "error") {
                dispatchEvent({
                    type: actionTypes.USER_DEACTIVE_ERROR,
                    payload: response,
                });
                throw response;
            } 
        })
        .catch((error) => {
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            dispatchEvent({
                type: actionTypes.USER_DEACTIVE_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}

function putActiveAccount(dispatchEvent, userid) {
    UserService.putActiveAccount(userid)
        .then((response) => {
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });

            if (response?.status === "success") {
                alertService.success("User activated successfully.", {autoClose: true});
                let obj = {};

                dispatchEvent({
                    type: actionTypes.USER_ACTIVE_SUCCESS,
                    payload: {data: obj, status: response?.status},
                });
            } else if (response?.status === "error") {
                dispatchEvent({
                    type: actionTypes.USER_ACTIVE_ERROR,
                    payload: response,
                });
                throw response;
            } 
        })
        .catch((error) => {
            const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
            alertService.error(errorMessage, { autoClose: true });
            dispatchEvent({
                type: actionTypes.USER_ACTIVE_ERROR,
                payload: error,
            });
            dispatchEvent({
                type: actionTypes.IS_LOADING,
                isLoading: false,
            });
        });
}
