export const Appointment = {
    id: 'id',
    patientFirstName: 'patientFirstName',
    patientLastName: 'patientLastName',
    patientName: 'patientName',
    patientDOB: 'patientDOB',
    patientPhone: 'patientPhone',
    startDate: 'startDate',
    endDate: 'endDate',
    appointmentType: 'appointmentType',
    procedureDesc: 'procedureDesc',
    procedureDate: 'procedureDate',
    physican: 'physician',
    physicianId: 'physicianId',
    completed: 'completed',
    resourceId: 'resourceId',
    resource: 'resource',
    appointmentStart: 'begin',
    appointmentEnd: 'end',
    note: 'note',
    patientId: 'pid',
    attendee: 'attendee',
    roomStatus: 'roomStatus'
};
