import * as actionTypes from "./actionTypes";
import { alertService } from "../../data/services/alertService";
import { ReportService } from "../../data/services/index";

export const reportDashboardCount = (searchFilter) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.IS_LOADING, isLoading: true });
  //dispatchEvent({ type: actionTypes.GET_USERS });
  reportSummary(dispatchEvent, searchFilter);
};

function reportSummary(dispatchEvent, searchFilter) {
  ReportService.getReportSummary(searchFilter)
    .then((response) => {
      dispatchEvent({ type: actionTypes.IS_LOADING, isLoading: false });
      if (response?.status === "success") {
        dispatchEvent({
          type: actionTypes.REPORTSUMMARY_GET_SUCCESS,
          payload: response,
        });
      } else if (response?.status === "error") {
        dispatchEvent({
          type: actionTypes.REPORTSUMMARY_GET_ERROR,
          payload: response,
        });
        throw response;
      }
    })
    .catch((error) => {
      console.log(error);
      // alertService.error("Something went wrong. Please try again.", { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      //alertService.error(error.message);
      dispatchEvent({
        type: actionTypes.REPORTSUMMARY_GET_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}

export const getReportList = (searchFilter, pageInfo) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.IS_LOADING, isLoading: true });
  //dispatchEvent({ type: actionTypes.GET_USERS });
  reportList(dispatchEvent, searchFilter, pageInfo);
};
function reportList(dispatchEvent, searchFilter, pageInfo) {
  ReportService.getReportList(searchFilter, pageInfo)
    .then((response) => {
      dispatchEvent({ type: actionTypes.IS_LOADING, isLoading: false });
      if (response?.status === "success") {
        console.log(response);
        dispatchEvent({
          type: actionTypes.REPORTLIST_GET_SUCCESS,
          payload: response,
        });
      } else if (response?.status === "error") {
        dispatchEvent({
          type: actionTypes.REPORTLIST_GET_ERROR,
          payload: response,
        });
        throw response;
      }
    })
    .catch((error) => {
      console.log(error);
      // alertService.error("Something went wrong. Please try again.", { autoClose: true });
      const errorMessage = error && error.message ? error.message : (typeof error === 'string' ? error : 'Something went wrong. Please try again.');
      alertService.error(errorMessage, { autoClose: true });
      //alertService.error(error.message);
      dispatchEvent({
        type: actionTypes.REPORTLIST_GET_ERROR,
        payload: error,
      });
      dispatchEvent({
        type: actionTypes.IS_LOADING,
        isLoading: false,
      });
    });
}
