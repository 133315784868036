import './Sms.css';
import React from 'react';

class Sms extends React.Component {
    render() {

        const theosLogoPath = require("../../../Asset/images/theos-logo.png");

        return (
            <div className="body">
                <div className="logoContainer">
                    <img alt="Theos Logo" className="logo" src={theosLogoPath}></img>
                </div>

                <div className="title">Theos Health SMS Terms of Service</div>
                <ol className="bullets">
                    <li>When you opt-in to the service, we will send you an SMS message to confirm your signup.</li>
                    <li>This service is only used to send you notifications regarding your telemedicine appointments.</li>
                    <li>You can cancel this service at any time by replying with the message STOP. After you send the message STOP to us, we will send you a reply message to confirm that you have been unsubscribed. After this, you will
                        no
                        longer receive messages from us.
                    </li>
                    <li>If at any time you forget what keywords are supported, just reply with the message HELP. After you send the message HELP to us, we will respond with instructions on how to use our service as well as how to
                        unsubscribe.
                    </li>
                    <li>We can deliver messages to the following major mobile phone carriers: AT&T, Verizon Wireless, Sprint, T-Mobile.</li>
                    <li>As always, Message and Data Rates May Apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider. For
                        all
                        questions about the services provided by this short code, you can send an email to support@theoshealth.com.
                    </li>
                </ol>
            </div>
        );
    }
}

export default Sms;
