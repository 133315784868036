import React, {Component} from 'react';
import CustomImg from '../../Global/CustomImg/CustomImg';
import Branding from "../../../Services/Branding";

class Endsession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoImageUrl: null
    };
  }

    async setBranding() {
        const branding = await Branding.getBranding(this.props.conference);
        if (this.state.logoImageUrl !== branding.logoImageUrl) {
            this.setState({logoImageUrl: branding.logoImageUrl});
        }
    }

    async componentWillMount() {
        this.setBranding();
    }

    render() {
        return (
            <div className="thankyou-page-wrapper scrollbar">
                <div className="scroll-min-height d-flex flex-column align-items-center justify-content-center">
                    <div className="mb-4"><CustomImg src={this.state.logoImageUrl} className="img-fluid" alt="Logo"/></div>
                    <div className="thankyou-page-icon bg-primary mb-3">
                        <CustomImg src="icon-video-session.svg" alt="icon session"/>
                    </div>

                    <div className="text-center thankyou-page-text">
                        <h2>Thank You!</h2>
                        <p className="mb-0">Your session has been completed.</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default Endsession;
