import queryString from "query-string";
import urlParser from "url";
import Storage from "../Providers/Storage";
import Progress from "react-progress-2";
import { API, Auth } from "aws-amplify";
import { history } from "../Providers/History";

export default class Http {
  constructor({ options = {} }) {
    // console.log("http Host ",this.host);
    this.options = options;
  }

  // Override this in implementations to set headers at request-time
  getHeaders() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return headers;
  }

  resolveUrl(url) {
    if (!this.host) return url;

    return urlParser.resolve(this.host, urlParser.parse(url).path);
  }

  parseJsonResponse = (json) => {
    // console.log(" HTTP parseJsonResponse ");
    if (typeof json.data === "undefined" && !json.token) {
      //throw new Error("No data member specified on API response.");
    }
    return json;
  };

  parseJsonError = (json, status) => {
    if (status === 401) {
      /*if (!store.getState().Auth.isAuthenticated && window.location.pathname !== '/login') {
                history.push('/login');
            } else { */
      // Mav Note: assume based on the conditions
      // the error was a result of an invalid session.
      // If at the login page, throw errors
      // This is hackey but prevents a butt load of error toasts
      // we're currently seeing on the dashboard when timing out
      throw { ...json.errors, status: status };
      //}
    } else {
      // All other status codes, simply throw
      throw { ...json.errors, status: status };
    }
  };

  async handleRequest(apiName, url, options = {}, loader_show = true, check_token = true) {
    //alert(url+'::::'+loader_show);
    if (loader_show === true) {
      Progress.show();
    }
    const requestOptions = { ...this.options, ...options };
    requestOptions.headers = this.getHeaders();

    //const token = Storage.get('username');
    try {
      let token = "";
      if (check_token) {
        token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      } else {
        token = "";
      }
      // console.log(" Get access Token ",token);
      if (token) {
        requestOptions.headers = { ...requestOptions.headers, Authorization: `${token}` };
      }
      //const response = await fetch(this.resolveUrl(url), requestOptions);
      let response = "";
      if (options.method === "post") {
        response = await API.post(apiName, url, requestOptions);
      } else if (options.method === "put") {
        response = await API.put(apiName, url, requestOptions);
      } else {
        response = await API.get(apiName, url, requestOptions);
      }
      //const json = await response.json();
      if (loader_show === true) {
        Progress.hide();
      }
      // Throw responses that returned HTTP error codes
      if (response?.status > 299) throw this.parseJsonError(response, response?.status);
      let res = { ...response, status: "success" };
      return this.parseJsonResponse(res);
    } catch (e) {
      console.log(e);
      if (loader_show === true) {
        Progress.hide();
      }

      if (e?.response?.status === 401 || (typeof e === "string" && e.trim().includes("No current user"))) {
        Auth.signOut();
        history.push({ pathname: "/" });
        
        let error_res = {
          status: "error",
          statusCode: e?.response?.status,
          message: "Session timed out, try logging in again",
        };
        console.log("error_res ==>>", error_res);
        return this.parseJsonResponse(error_res);
      }
      if (e?.response?.status === 418) {
        // Server returns this on an invalid join request.  Go back and refresh.
        history.push({ pathname: "/" });
        return;
      }
      if (e?.response?.status === 400 || e?.response?.status === 422 || e?.response?.status === 404) {
        let error_res = {
          status: "error",
          statusCode: e?.response?.status,
          message: e?.response?.data?.message,
        };
        console.log(error_res);
        return this.parseJsonResponse(error_res);
      }

      if(typeof e === "object") {
        const {message} = JSON.parse(JSON.stringify(e));
        if(message && message.trim().includes("Network Error")) {
          let error_res = {
            status: "error",
            statusCode: e?.response?.status,
            message: "Network error occurred. Please check your internet connection",
          }; 
          console.log("error_res ==>>", error_res);
          return this.parseJsonResponse(error_res);
        } else if (message) {
          let error_res = {
            status: "error",
            statusCode: e?.response?.status,
            message: e?.response?.data?.message
          }; 
          console.log("error_res ==>>", error_res);
          return this.parseJsonResponse(error_res);
        }
      }

    }
  }

  handleResponse = (response) => {
    if (response?.status >= 200 && response?.status < 300) {
      return response.json();
    }
  };

  get(apiName, url, queryObject = false, loader_show = true, check_token = true) {
    const query = queryObject ? `?${queryString.stringify(queryObject)}` : "";
    return this.handleRequest(apiName, url + query, "", loader_show,check_token);
  }

  post(apiName, url, body, loader_show = true, check_token = true) {
    return this.handleRequest(
      apiName,
      url,
      {
        method: "post",
        //body: JSON.stringify(body),
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      loader_show,
      check_token
    );
  }

  put(apiName, url, body, loader_show = true) {
    return this.handleRequest(
      apiName,
      url,
      {
        method: "put",
        //body: JSON.stringify(body)
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      loader_show
    );
  }

  delete(url, loader_show = true) {
    return this.handleRequest(url, { method: "delete" }, loader_show);
  }

  upload(url, data) {
    url = this.resolveUrl(url);
    return new Promise((resolve, reject) => {
      const formdata = new FormData();
      for (const key in data) {
        formdata.append(key, data[key]);
      }
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url);

      const token = Storage.get("access_token");
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        const json = JSON.parse(xhr.response);
        if (xhr.status > 299) {
          reject({ ...json.errors, status: json.status });
        } else {
          resolve(json);
        }
      };

      xhr.onabort = () => reject(JSON.parse(xhr.response));
      xhr.onerror = () => reject(JSON.parse(xhr.response));

      xhr.send(formdata);
    });
  }

  getBlob(url, params) {
    url = this.resolveUrl(url);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url + params);

      const token = Storage.get("access_token");
      xhr.setRequestHeader("Accept", "application/pdf");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.responseType = "blob";

      xhr.onload = () => {
        const json = xhr.response;
        if (xhr.status > 299) {
          reject({ ...json.errors, status: json.status });
        } else {
          resolve(json);
        }
      };

      xhr.onabort = () => reject(JSON.parse(xhr.response));
      xhr.onerror = () => reject(JSON.parse(xhr.response));

      xhr.send();
    });
  }

  getCSV(url, params) {
    url = this.resolveUrl(url);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const query = params ? `?${queryString.stringify(params)}` : "";
      xhr.open("GET", url + query);

      const token = Storage.get("access_token");
      xhr.setRequestHeader("Accept", "application/csv");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.responseType = "blob";

      xhr.onload = () => {
        const response = xhr.response;
        if (xhr.status > 299) {
          reject({ ...response?.errors, status: response?.status });
        } else {
          resolve(response);
        }
      };

      xhr.onabort = () => reject(xhr.response);
      xhr.onerror = () => reject(xhr.response);

      xhr.send();
    });
  }
}
